export const colors = {
         primary: "#4961b1",
         secondary: "#2DBECF",
         primary_rgba: "rgb(59, 140, 247)",
         white: "#fff",
         primary_light: "#e5e5e5",
         orange: "#ef9916",
         red: "#f02222",
         background_light_gray: "#F9F9F9",
         gold: "#ffca59",
         deep_gold: "#FDC445",
         sunset_orange: "#F0C987",
         green: "#61d352",
         gray: "#969696",
         lightgray: "#a5a5a5",
         black: "#000000",
         transparent_overlay_white: "rgba(255,255,255, 0.35)",
         transparent_overlay_black: "rgba(0,0,0, 0.6)",
         transparent_overlay_gray: "rgba(0,0,0, 0.2)",
         clear: "rgba(0, 0, 0, 0)",
         transparent_overlay_primary: "rgba(59, 140, 247, 0.8)"
       };
