import { UPDATE_USER_LAYOUT, LOGGING_IN, LOGIN, LOGIN_FAIL, LOGOUT, USER_DATA, REGISTER, REGISTERING, REGISTER_FAIL, RESETTING_PASSWORD, RESETTING_PASSWORD_FAILURE, RESETTING_PASSWORD_SUCCESS, SENDING_RESET_LINK, SENT_RESET_LINK } from './../Utils/ActionTypes';
import { toast } from 'react-toastify';

const initialState = {
  isLoggedIn: false,
  errorMessage: null,
  email: '',
  token: '',
  hasError: false,
  isFetching: false,
  data: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGGING_IN:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: true,
        data: {}
      });

    case LOGIN:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: true,
        email: action.email,
        token: action.payload,
        hasError: "",
        isFetching: false,
        data: action.data
      });

    case USER_DATA:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: state.isLoggedIn,
        email: state.email,
        token: state.token,
        hasError: "",
        isFetching: false,
        data: action.payload
      });

    case UPDATE_USER_LAYOUT:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: true,
        email: state.email,
        token: state.token,
        hasError: "",
        isFetching: false,
        data: action.payload
      });

    case LOGOUT:
      let keysToRemove = ["userToken", "userLoggedIn", "userEmail", "userName", "userStripeId"];
      keysToRemove.forEach(key => localStorage.removeItem(key));
      toast("You have been signed out. See you next time!", {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        position: "bottom-center"
      });

      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: false,
        data: {}
      });

    case REGISTERING:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: true,
        data: {}
      });

    case REGISTER:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: false,
        data: {}
      });

    case LOGIN_FAIL:
      return Object.assign({}, state, {
        errorMessage: action.payload,
        isLoggedIn: false,
        email: null,
        token: null,
        hasError: true,
        isFetching: false,
        data: {}
      });

    case REGISTER_FAIL:
      return Object.assign({}, state, {
        errorMessage: action.payload,
        isLoggedIn: false,
        email: null,
        token: null,
        hasError: true,
        isFetching: false,
        data: {}
      });

    case RESETTING_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
        isLoggedIn: false,
        email: null,
        token: null,
        hasError: true,
        isFetching: false,
        data: {}
      });

    case RESETTING_PASSWORD:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: true,
        data: {}
      });

    case RESETTING_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: false,
        isFetching: false,
        data: {}
      });

    case SENDING_RESET_LINK:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: "",
        isFetching: true,
        data: {}
      });

    case SENT_RESET_LINK:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoggedIn: false,
        email: "",
        token: "",
        hasError: false,
        isFetching: false,
        data: {}
      });

    default:
      return state;
  }
}