export const LOGGING_IN = "LOGGING_IN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTERING = "REGISTERING";
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const RESETTING_PASSWORD_SUCCESS = "RESETTING_PASSWORD_SUCCESS";
export const RESETTING_PASSWORD_FAILURE = "RESETTING_PASSWORD_FAILURE";
export const SENDING_RESET_LINK = "SENDING_RESET_LINK";
export const SENT_RESET_LINK = "SENT_RESET_LINK";
export const USER_DATA = "USER_DATA";
export const LINKING_STRIPE_TOKEN_TO_CUSTOMER = "LINKING_STRIPE_TOKEN_TO_CUSTOMER";
export const LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS = "LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS";
export const LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE = "LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE";

export const CANCEL_CUSTOMER_PLAN = "CANCEL_CUSTOMER_PLAN";
export const CANCEL_CUSTOMER_PLAN_SUCCESS = "CANCEL_CUSTOMER_PLAN_SUCCESS";
export const CANCEL_CUSTOMER_PLAN_FAILURE = "CANCEL_CUSTOMER_PLAN_FAILURE";

export const OPEN_SIGN_IN_MODAL = "OPEN_SIGN_IN_MODAL"
export const OPEN_RESERVATION_MODAL = "OPEN_RESERVATION_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"
export const SET_RESERVATION_DETAILS = "SET_RESERVATION_DETAILS";


export const UPDATE_USER_LAYOUT = "UPDATE_USER_LAYOUT";

export const GETTING_COUPON = "GETTING_COUPON";
export const GETTING_COUPON_SUCCESS = "GETTING_COUPON_SUCCESS";
export const GETTING_COUPON_FAILURE = "GETTING_COUPON_FAILURE";
export const CLEAR_COUPON = "CLEAR_COUPON";