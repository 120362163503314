import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import { validateCoupon } from './../actions/Auth';

class CouponModal extends Component {

	constructor(props) {
    super(props);
    this.state = {
			isLoading: false,
			code: '',
			isValid: false
    };
	}

	_validateCoupon = async () => {
		await this.props.validateCoupon(this.state.code).then(() => {
			if (this.props.coupons.isValid) {
				this.props.closeModal()
			}
		})
	}

  render() {
    return (
			<Modal open={this.props.open} onClose={this.props.closeModal} center>
				<div style={{ display: "flex", flexDirection: "column", padding: 10, justifyContent: 'center', alignItems: 'center' }}>
					<h2 style={{ marginBottom: 10 }}>Apply Code</h2>
					{this.props.coupons.isValid === false &&
						<p style={{ color: 'red' }}>Sorry, we couldn't find a valid coupon for you with that code.</p>
					}
					<label>Your Code:</label>
					<input
						style={{ marginBottom: 9 }}
						value={this.state.code}
						onChange={e => this.setState({code: e.target.value})}
						maxLength="85"
					/>
					<Button
						style={{ marginTop: 29, backgroundColor: "#2DBECF" }}
						onClick={this._validateCoupon}
						color="primary"
						disabled={this.props.coupons.isFetching}
					>
						Submit
					</Button>
					<Button
						style={{ marginTop: 29 }}
						onClick={() => this.props.closeModal()}
						color="gray"
					>
						Go Back
					</Button>
				</div>
			</Modal>
		)
  }
}


const mapStateToProps = state => ({
	auth: state.auth,
	coupons: state.coupons
});

export default withRouter(
  connect(
    mapStateToProps,
    { validateCoupon }
  )(CouponModal)
);
