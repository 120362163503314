import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import "./../styles/home/index.scss";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  renderPlaceholderStuff = () => {
    const words = ["sweet", "crazy", "dope", "tubular", "amazing"]
    let array = []
    for (let index = 0; index < 5; index++) {
      array.push(<div style={{paddingTop: 15}}>Here's something {words[index]} about this!</div>)
    }
    return array;



  }
  render () {
    return (
      <div>
        <div>Pricing</div>
        <div>{this.renderPlaceholderStuff()}</div>
      </div>

    )
  }
}

const mapStateToProps = state => ({
})

export default withRouter(connect(mapStateToProps, { })(Pricing))
