import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import { sendResetPasswordEmail } from '../actions/Auth';

class ForgotPasswordModal extends Component {
	
	constructor(props) {
    super(props);
    this.state = {
			email: "",
			submitted: false
    };
	}
	_validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      return (true)
    }
    alert("You entered an invalid email address!")
    return (false)
}

	_sendResetPasswordEmail = async () => {
		if(this._validateEmail(this.state.email) === true) {
			await this.props.sendResetPasswordEmail(this.state.email).then(() => {			
				this.setState({submitted: true})				
			})
		}		
	}
	
  render() {
    return (
			<Modal open={this.props.open} onClose={this.props.closeModal} center>
				
					{this.state.submitted &&
						<p style={{padding: 40}}>We have sent an email to {this.state.email}. Click the link in the email to reset your password.</p>
					}
					{!this.state.submitted &&
						<div style={{ display: "flex", flexDirection: "column", padding: 10, textAlign: 'center' }}>
							<h2 style={{ marginBottom: 10 }}>Forgot your password?</h2>					
						<p>Enter the email address linked to your account and we'll send a link to reset your password.</p>									
					<input
						style={{ width: 220, margin: '0 auto', marginTop: 20 }}
						autoFocus={true}
						value={this.state.email}
						onChange={e => this.setState({email: e.target.value})}
						maxLength="85"
					/>
					<Button
						style={{ margin: '0 auto', marginTop: 29, backgroundColor: "#2DBECF",  width: 220 }}
						onClick={this._sendResetPasswordEmail}
						color="primary"						
					>
						Submit
					</Button>
					<Button
						style={{ margin: '0 auto',marginTop: 29,  width: 220 }}
						onClick={() => this.props.closeModal()}
						color="gray"
					>
						Go Back
					</Button>
							</div>
					}
									
			</Modal>
		)
  }
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default withRouter(
  connect(
    mapStateToProps,
    { sendResetPasswordEmail }
  )(ForgotPasswordModal)
);
