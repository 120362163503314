// export const apiBaseURL = "https://linkstackr.com/api";
export const apiBaseURL = process.env.NODE_ENV === "development" ? "http://localhost:3001/api" : "https://linkstackr.com/api";
export const googleFontsKey = "AIzaSyAFFQVlF-dj8y05crm8YDsActt2Pr3-KHA";
export const stripePublishableKey =
         process.env.NODE_ENV === "development"
           ? "pk_test_TI7RmWSKIoIGc7eKHU16PE3I00xnYqYXWc"
           : "pk_live_6swKn9FnIROSMaa67ukODidi00GWEXn36K";
// export const stripePublishableKey = 'pk_test_L0IJWOGIhUhEU810lqapRsoy' //TESTING
export const inDev = () => {
    if (process.env.NODE_ENV === 'development') {
        return true;
    } else {
        return false;
    }
}
export const GAKey = "UA-109829871-2";
export const reservedUrls = [
    'me', 'you', 'trevor', 'trev', 'linkstackr', 'lnkstckr', 'lnkstkr', 'us', 'bree', 'breanne', 'beck', 'beckham', 'apple'
]