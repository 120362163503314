import React from 'react';
import './../styles/base.scss';

class Work extends React.Component {
  render() {


  	return (
		<section className="work" id="work">
		   <div className="container">
		      <div className="row">
		         <div className="col-md-8 offset-md-2 text-center">
		            <div className="section-title">
		               <h2>What's it good for?</h2>
		               {/* <img src={require("../styles/images/title-line.png")} alt="white-line" className="img-fluid"/> */}
		               <p>linkstackr is versatile and simple, all at the same time! The purpose of linkstackr is to make it easy to share a collection of links with whomever you want, as well as track how often your links are viewed and followed.</p>
		            </div>
		         </div>
		      </div>

		      <div className="row">
					<div className="col-md-8 offset-md-2 text-center">
						<h3 style={{marginBottom: 25}}>Some ideas...</h3>
					</div>
		         <div className="col-lg-3 text-center" style={{marginTop: 30}}>
		            <div className="process-box">
		               <img src={require("../styles/images/icon/1.png")} alt=""/>
		               <h3 style={{height: 80, textAlign: 'center'}}>App Download Links</h3>
		               <p style={{height: 120, textAlign: 'center'}}>Advertising your mobile app on multiple platforms? Use linkstackr to share the links to the App Store & Google Play Store!</p>
		               <img src={require("../styles/images/icon/1.png")} alt="" className="bg-icon"/>
		            </div>
		         </div>
		         <div className="col-lg-3 text-center" style={{marginTop: 30}}>
		            <div className="process-box">
		               <img src={require("../styles/images/icon/2.png")} alt=""/>
		               <h3 style={{height: 80, textAlign: 'center'}}>Blog Posts</h3>
		               <p style={{height: 120, textAlign: 'center'}}>Share your recent blog posts with all of your followers! Handy for Instagram, where you can't link to your blog posts directly!</p>
		               <img src={require("../styles/images/icon/2.png")} alt="" className="bg-icon"/>
		            </div>
		         </div>
		         <div className="col-lg-3 text-center" style={{marginTop: 30}}>
		            <div className="process-box">
		               <img src={require("../styles/images/icon/3.png")} alt=""/>
		               <h3 style={{height: 80, textAlign: 'center'}}>Affiliate Marketing</h3>
		               <p style={{height: 120, textAlign: 'center'}}>Share your affiliate links with your followers with ease! Not a bad way to make some additional cash!</p>
		               <img src={require("../styles/images/icon/3.png")} alt="" className="bg-icon"/>
		            </div>
		         </div>
		         <div className="col-lg-3 text-center" style={{marginTop: 30}}>
		            <div className="process-box">
		               <img src={require("../styles/images/icon/3.png")} alt=""/>
		               <h3 style={{height: 80, textAlign: 'center'}}>Portfolio</h3>
		               <p style={{height: 120, textAlign: 'center'}}>Show off links to your work across the web! Articles, photographs, websites & design... put it in one place!</p>
		               <img src={require("../styles/images/icon/3.png")} alt="" className="bg-icon"/>
		            </div>
		         </div>
		      </div>
		   </div>
		</section>
  	);
  }
}


export default Work;