import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLinkable = (props) => {
    let platform = props.linkableData ? props.linkableData.platform.toLowerCase() : ""
    if (props.edit) {
        return (
            <div
                // className="linkable"
                style={{
                    display: "flex",
                    width: 45,
                    position: "relative",
                    background: props.background,
                    height: 45,
                    padding: 5,
                    alignItems: "center",
                    borderWidth: 2,
                    borderRadius: props.rounded ? 23 : 0,
                    borderColor: props.borderColor,
                    margin: "0 auto",
                    justifyContent: "center"
                }}
                onClick={() => props.onEdit(props.linkableData)}
            >
                {platform === "facebook" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "facebook-square"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "instagram" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "instagram"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "linkedin" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "linkedin"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "pinterest" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "pinterest-square"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "facebook messenger" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "facebook-messenger"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "youtube" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "youtube-square"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "twitter" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "twitter-square"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "twitch" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "twitch"]}
                        style={{color: props.textColor}}
                    />
                }
                {platform === "dribbble" &&
                    <FontAwesomeIcon
                        size="2x"
                        icon={["fab", "dribbble"]}
                        style={{color: props.textColor}}
                    />
                }

            </div>
        );
    }

    return (
      <div
        style={{
          display: "flex",
          width: 45,
          position: "relative",
          background: props.background,
          height: 45,
          alignItems: "center",
          borderWidth: 2,
          borderRadius: props.rounded ? 23 : 0,
          borderColor: props.borderColor,
          margin: "0 auto",
          justifyContent: "center"
        }}
      >
        {platform === "facebook" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "facebook-square"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "instagram" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "instagram"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "linkedin" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "linkedin"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "twitter" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "twitter-square"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "twitch" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "twitch"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "dribbble" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "dribbble"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "pinterest" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "pinterest-square"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "facebook messenger" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "facebook-messenger"]}
            style={{ color: props.textColor }}
          />
        )}
        {platform === "youtube" && (
          <FontAwesomeIcon
            size="2x"
            icon={["fab", "youtube-square"]}
            style={{ color: props.textColor }}
          />
        )}
      </div>
    );
};

export default SocialLinkable;
