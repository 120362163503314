import React from 'react';


class Footer extends React.Component {
  render() {
  	return (
        <div>
            <footer className="theme-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" style={{paddingBottom: 10}}>
                            <p style={{fontSize: 13}} className="copyright white">{new Date().getFullYear()} Copyright by linkstackr</p>
                            <div style={{textAlign: 'center', marginTop: 15, color: "gray", fontSize: 13}}><a  rel="noopener noreferrer" href="https://app.termly.io/document/privacy-policy/10e4123f-e015-453c-8811-416a1032886f" style={{fontSize: 13, color: "white"}} target="_blank">Privacy Policy</a></div>
                            <div style={{textAlign: 'center', marginTop: 5, color: "gray", fontSize: 13}}><a  rel="noopener noreferrer" href="https://app.termly.io/document/terms-of-use-for-saas/f1e624cb-cd71-4ed5-b1ff-b86e87e90b71" style={{fontSize: 13, color: "white"}} target="_blank">Terms and Conditions</a></div>
                            
                            <div style={{textAlign: 'center', marginTop: 25, color: "lightgray", fontSize: 9}}>Icons made by <a  rel="noopener noreferrer"href="https://www.freepik.com/" title="Freepik" style={{color: "lightgray", fontSize: 9}}>Freepik</a> from <a  rel="noopener noreferrer" style={{color: "lightgray", fontSize: 9}} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a style={{color: "lightgray", fontSize: 9}}  rel="noopener noreferrer" href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
  	);
  }
}


export default Footer;