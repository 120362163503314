import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WelcomeModal from './WelcomeModal';
import { refreshData } from "./../actions/Auth";
import { colors } from "../Utils/BaseStyles";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { toast } from "react-toastify";
let theToast = null;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeModalOpen: false,
      slug: this.props.auth.data.slug,
      plan: this.props.auth.data.plan,
    };
    this.props.refreshData();
  }
  componentDidMount() {
    if (this.props.auth && (this.props.auth.data.plan === "canceled" || this.props.auth.data.plan === '')) {
      this.props.history.replace({
        pathname: '/account',
        state: {
          plan_active: "no",
          page: 'Dashboard'
        }
      });
    }
  }
  componentWillReceiveProps = (nextProps, props) => {
    if (props !== nextProps) {
      if(nextProps.auth.data){
        this.setState({
          name: nextProps.auth.data.name,
          slug: nextProps.auth.data.slug,
          plan: nextProps.auth.data.plan,
          email: nextProps.auth.data.email
        });
      }
    }
  };
  render() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("inner-page");
    return (
      <div>
        <section className="review-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-xs-12">
                <Link to={`/edit`}>
                  <div className="review-box">
                    <div className="rating-star">
                      <FontAwesomeIcon
                        icon={"magic"}
                        color="#a2a2a2"
                        className="fa-2x"
                      />
                    </div>
                    <h5>Live Editor</h5>

                    <div className="review-message">
                      <p>
                        Preview and edit your page with our Live Editor. A
                        quick, simple way to customize your links and look.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-xs-12">
                <Link to={`/account`}>
                  <div className="review-box">
                    <div className="rating-star">
                      <FontAwesomeIcon
                        icon={"user"}
                        color="#a2a2a2"
                        className="fa-2x"
                      />
                    </div>
                    <h5>My Account</h5>
                    <div className="review-message">
                      <p>
                        Make changes to your account, subscription, and
                        other preferences here.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-xs-12">
                <Link to={`/analytics`}>
                  <div className="review-box">
                    <div className="rating-star">
                      <FontAwesomeIcon
                        icon={"chart-line"}
                        color="#a2a2a2"
                        className="fa-2x"
                      />
                    </div>
                    <h5>Analytics</h5>

                    <div className="review-message">
                      <p>
                        View tracking information for your links and page.
                        Understand where your visitors are going.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div
              style={{ paddingTop: 50, paddingBottom: 20 }}
              className="col-lg-12 col-md-12 col-xs-12"
            >
              <h3 style={{ textAlign: "center" }}>Your linkstackr URL: </h3>
              <a href={"https://lnks.by/" + this.state.slug}>
                <h2 style={{ textAlign: "center" }}>
                  lnks.by/{this.state.slug}
                </h2>
              </a>

            </div>

            <h1 style={{ textAlign: "center", color: colors.primary, marginBottom: 50 }}>What's next?</h1>
            <div className="container-fluid" style={{ marginBottom: 30 }}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="review-box">

                    <CardBody>
                      <h1 style={{ textAlign: 'center', color: colors.primary }}>1</h1>
                      <CardTitle>Add All Your Links</CardTitle>
                      <CardText>Make sure you have all your important links in your Linkstackr and that it looks good!</CardText>
                      <Button color="primary" style={{marginTop: 15}} href="/edit">Do It Now</Button>
                    </CardBody>

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="review-box">

                    <CardBody>
                      <h1 style={{ textAlign: 'center', color: colors.primary }}>2</h1>
                      <CardTitle>Share Your Short URL</CardTitle>
                      <CardText>Put it in your "link in bio" on Instagram, then share it everywhere and watch your traffic with our Analytics!</CardText>
                      <CopyToClipboard text={"lnks.by/" + this.props.auth.data.slug}
                        onCopy={() => {
                          alert("Copied!");
                          this.setState({copied: true})

                          }}
                          >
                        <Button color="primary" style={{marginTop: 15}}>Copy to Paste Elsewhere</Button>
                      </CopyToClipboard>
                    </CardBody>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className="review-box">
                    <CardBody>
                      <h1 style={{ textAlign: 'center', color: colors.primary }}>3</h1>
                      <CardTitle>Spread the Word. Make Money!</CardTitle>
                      <CardText>Earn commissions for every user you refer that signs up for a subscription!</CardText>
                      <Button color="primary" style={{ marginTop: 15 }} href="https://linkstackr.getrewardful.com/signup">Get Referral Link Here</Button>
                    </CardBody>
                  </div>
                </div> */}
              </div>
            </div>


            {/* <div className="label-input-pair-center" style={{ padding: 40, textAlign: 'center' }}>
              <label>Want to earn some recurring commissions?</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p style={{ textAlign: "center" }}>
                  Get a personal link to share with your audience. Anyone
                  that clicks on your link will have 30 days to complete
                  signup. After that, you will earn a percentage of their
                  subscription rate EVERY MONTH THEY ARE ACTIVE. Put the
                  link in your own linkstackr!
                </p>
                <a
                  style={{ fontSize: 20, textDecoration: 'underline', color: colors.primary, textAlign: "center" }}
                  href="https://linkstackr.getrewardful.com/signup"
                >
                  Join our affiliate program here!
                </a>
              </div>
            </div> */}
          </div>
          <div className="animation-circle absolute">
            <i />
            <i />
            <i />
          </div>
          <div className="animation-circle-inverse">
            <i />
            <i />
            <i />
          </div>
        </section>
        <WelcomeModal
          open={this.state.welcomeModalOpen}
          closeModal={() =>
            this.setState({
              welcomeModalOpen: !this.state.welcomeModalOpen
            })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(
    mapStateToProps,
    { refreshData }
  )(Dashboard)
);
