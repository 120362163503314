import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Price from './../../UIcomponents/price';
import FAQ from './../../UIcomponents/faq';
import FeatureBlock from './../../UIcomponents/FeatureBlock';
import { Link } from 'react-router-dom';
import Subscribe from './../../UIcomponents/subscribe';
import { Animated } from 'react-animated-css';
import scrollToElement from 'scroll-to-element';
import "./../../styles/home/index.scss";
import "./../../styles/fonts.scss";
import "./../../styles/css/style.css";
import "./../../styles/base.scss";

import photographyData from "./../../data/photography.json";
import influencerData from "./../../data/influencers.json";
import emailData from "./../../data/email.json";
import fashionData from "./../../data/fashion.json";
import realEstateData from "./../../data/realestate.json";
import sellerData from "./../../data/seller.json";
import travelerData from "./../../data/traveler.json";
import youtuberData from "./../../data/youtuber.json";
import foodData from "./../../data/food.json";
import podcastData from "./../../data/podcaster.json";
import recruitData from "./../../data/recruiter.json";

class UseCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Math.random()
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      let data = {};
      switch (location.pathname) {
        case "/for/photographers":
          data = photographyData;
          break;
        case "/for/influencer":
          data = influencerData;
          break;
        case "/for/emails":
          data = emailData;
          break;
        case "/for/fashion":
          data = fashionData;
          break;
        case "/for/realestate":
          data = realEstateData;
          break;
        case "/for/seller":
          data = sellerData;
          break;
        case "/for/traveler":
          data = travelerData;
          break;
        case "/for/youtuber":
          data = youtuberData;
          break;
        case "/for/food":
          data = foodData;
          break;
        case "/for/podcast":
          data = podcastData;
          break;
        case "/for/recruit":
          data = recruitData;
          break;
        default:
          data = influencerData;
          break;
      }
      this.setState(data);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  _scrollTo = elementId => {
    scrollToElement(`${elementId}`, {
      offset: 0,
      ease: "in-out-quint",
      duration: 1500
    });
  };

  componentDidMount = () => {

    

    if (!this.props.match.params.who) {
      this.props.history.push("/");
    }
    let data = {};
    switch (this.props.match.params.who) {
      case "photographers":
        data = photographyData;
        break;
      case "influencer":
        data = influencerData;
        break;
      case "emails":
        data = emailData;
        break;
      case "fashion":
        data = fashionData;
        break;
      case "realestate":
        data = realEstateData;
        break;
      case "seller":
        data = sellerData;
        break;
      case "traveler":
        data = travelerData;
        break;
      case "youtuber":
        data = youtuberData;
        break;
      case "food":
        data = foodData;
        break;
      case "podcast":
        data = podcastData;
        break;
      case "recruit":
        data = recruitData;
        break;
      default:
        data = influencerData;
        break;
    }

    this.setState(data);
  };

  render() {
    document.body.classList.remove("inner-page");
    return (
      <div>
        <section className="slide-bg theme-bg shortHeight">
          <div className="animation-circle gray" id="home-circles-hero">
            <i />
            <i />
            <i />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex-1">
                  <div
                    style={{
                      marginTop: 25,
                      marginBottom: 25,
                      textAlign: "center"
                    }}
                  >
                    <Animated
                      animationIn="fadeInLeftBig"
                      animationOut="fadeOut"
                      isVisible={true}
                    >
                      <h1 className="main-title">{this.state.mainTitle}</h1>
                    </Animated>
                    <h4 className="main-subtitle">{this.state.subTitle}</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column"
                      }}
                    >
                      <Animated
                        animationIn="flipInX"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDelay={1500}
                      >
                        <div className="slid-btn">
                          <a
                            onClick={() => this._scrollTo("#first")}
                            className="btn-theme-primary mt-2 mr-3"
                          >
                            Why is that?
                          </a>
                        </div>
                      </Animated>
                      <Animated
                        animationIn="flipInX"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDelay={1500}
                      >
                        <div className="slid-btn">
                          <Link
                            to="/trial"
                            className="btn-theme-primary mt-2 mr-3"
                          >
                            Try it now!
                          </Link>
                        </div>
                      </Animated>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.point1 && (
          <div id="first">
            <FeatureBlock
              headerText={this.state.point1Header}
              mainText={this.state.point1MainText}
              subText={this.state.point1SubText}
              photoUrl={this.state.point1Image}
              side={this.state.point1side}
            />
          </div>
        )}

        {this.state.point2 && (
          <FeatureBlock
            gradient_bg={true}
            headerText={this.state.point2Header}
            mainText={this.state.point2MainText}
            subText={this.state.point2SubText}
            photoUrl={this.state.point2Image}
            side={this.state.point2side}
          />
        )}
        {this.state.point3 && (
          <FeatureBlock
            headerText={this.state.point3Header}
            mainText={this.state.point3MainText}
            subText={this.state.point3SubText}
            photoUrl={this.state.point3Image}
            side={this.state.point3side}
          />
        )}

        {this.state.point4 && (
          <FeatureBlock
            gradient_bg={true}
            headerText={this.state.point4Header}
            mainText={this.state.point4MainText}
            subText={this.state.point4SubText}
            photoUrl={this.state.point4Image}
            side={this.state.point4side}
          />
        )}

        {this.state.point5 && (
          <FeatureBlock
            headerText={this.state.point5Header}
            mainText={this.state.point5MainText}
            subText={this.state.point5SubText}
            photoUrl={this.state.point5Image}
            side={this.state.point5side}
          />
        )}

        {this.state.point6 && (
          <FeatureBlock
            gradient_bg={true}
            headerText={this.state.point6Header}
            mainText={this.state.point6MainText}
            subText={this.state.point6SubText}
            photoUrl={this.state.point6Image}
            side={this.state.point6side}
          />
        )}

        {this.state.point7 && (
          <FeatureBlock
            headerText={this.state.point7Header}
            mainText={this.state.point7MainText}
            subText={this.state.point7SubText}
            photoUrl={this.state.point7Image}
            side={this.state.point7side}
          />
        )}

        {/* Price Component */}
        {!this.props.auth.isLoggedIn && (
          <div>
            {/* <div>Enough said, I'm in! (To Be Completed)</div> */}
            <Price />
            <FAQ color="white" />
          </div>
        )}

        <div className="tap-top">
          <div>
            <i className="fa fa-angle-up" aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(
  connect(
    mapStateToProps,
		{}
  )(UseCase)
);