import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../actions/Auth";
import Headroom from "react-headroom";
import scrollToElement from "scroll-to-element";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false,
      mobileForMeMenu: false,
      mobileAccountMenu: false
    };
  }

  toggleMenu = () => {
    if (this.state.mobileNavOpen) {
      this.setState({ mobileForMeMenu: false, mobileAccountMenu: false });
    }
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
  };

  toggleForMe = () => {
    this.setState({ mobileForMeMenu: !this.state.mobileForMeMenu });
  };

  toggleAccountMenu = () => {
    this.setState({ mobileAccountMenu: !this.state.mobileAccountMenu });
  };
  handleLogOut = () => {
    this.props.logout();
    this.props.history.push({
      pathname: "/"
    });
  };

  _scrollTo = elementId => {
    scrollToElement(`${elementId}`, {
      offset: 0,
      ease: "in-out-quint",
      duration: 1500
    });
  };

  handleNavigation = destination => {
    const location = this.props.location.pathname;
    this.toggleMenu();
    if(destination === "login"){
      this.props.history.push("/login")
      return;
    }
    if (location === "/") {
      this._scrollTo("#" + destination);
    } else {
      this.props.history.push("/#" + destination);
    }
  };

  _ideaLink = () => {
    return (
      <div>
        <div className="mobile-only">
          <NavItem
            className="complex-link white"
            onClick={() => this.toggleForMe()}
            style={{ fontSize: this.state.mobileForMeMenu ? 24 : 18 }}
          >
            {this.props.auth.isLoggedIn && this.props.auth.data
              ? "Ideas"
              : "Is It For Me?"}
          </NavItem>
          <p
            style={{
              display: this.state.mobileForMeMenu ? "block" : "none"
            }}
          >
            Which of the following best describes you?
          </p>
          <Collapse isOpen={this.state.mobileForMeMenu} navbar>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/influencers">Influencers</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/photographers">Photographer</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/emails">Email Marketing</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/fashion">Fashion</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/realestate">Real Estate Agents</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/seller">Online Sellers</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/traveler">Traveler</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/youtuber">YouTuber</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/podcast">Podcasts</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/food">Food Blogger</Link>
            </DropdownItem>
            <DropdownItem>
              <Link                      onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/for/recruit">Recruiter</Link>
            </DropdownItem>

            <p
              onClick={() => this.toggleForMe()}
              style={{
                display: this.state.mobileForMeMenu ? "block" : "none",
                marginTop: 10
              }}
            >
              BACK TO MENU
            </p>
          </Collapse>
        </div>
        <div className="web-only">
          <NavItem className="complex-link">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                {this.props.auth.isLoggedIn && this.props.auth.data
                  ? "Ideas"
                  : "Is It For Me?"}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/for/influencers">Influencers</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/photographers">Photographer</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/emails">Email Marketing</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/fashion">Fashion</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/realestate">Real Estate Agents</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/seller">Online Sellers</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/traveler">Traveler</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/youtuber">YouTuber</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/podcast">Podcasts</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/food">Food Blogger</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/for/recruit">Recruiter</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavItem>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Headroom style={{ zIndex: 1000 }} disabled>
        <Navbar className="darkHeader" expand="md">
          <NavbarBrand href={this.props.auth.isLoggedIn ? "/start" : "/"}>
            <img
              src="/logo_words_white.png"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleMenu} className="mr-2">
            {" "}
            <FontAwesomeIcon icon={"bars"} color="#fff" className="fa" />
          </NavbarToggler>
          <Collapse isOpen={this.state.mobileNavOpen} navbar>
            <Nav className="ml-auto" navbar>
              {!this.props.auth.isLoggedIn && (
                <div className="menu-item-container">
                  <NavItem
                    style={{
                      display: this.state.mobileForMeMenu ? "none" : "block"
                    }}
                  >
                    <NavLink
                      className="link"
                      onClick={() => this.handleNavigation("about")}
                    >
                      About
                    </NavLink>
                  </NavItem>
                  <NavItem
                    style={{
                      display: this.state.mobileForMeMenu ? "none" : "block"
                    }}
                  >
                    <NavLink
                      className="link"
                      onClick={() => this.handleNavigation("features")}
                    >
                      Features
                    </NavLink>
                  </NavItem>
                  <NavItem
                    style={{
                      display: this.state.mobileForMeMenu ? "none" : "block"
                    }}
                  >
                    <NavLink
                      className="link"
                      onClick={() => this.handleNavigation("pricing")}
                    >
                      Pricing
                    </NavLink>
                  </NavItem>

                  {this._ideaLink()}

                  <NavItem
                    style={{
                      display: this.state.mobileForMeMenu ? "none" : "block"
                    }}
                  >
                    <div
                      className="btn-inverse"
                      style={{ borderRadius: 50 }}
                      onClick={() => this.props.history.push("/trial")}
                    >
                      Start for Free
                    </div>
                  </NavItem>
                  <NavItem
                    style={{
                      display: this.state.mobileForMeMenu ? "none" : "block"
                    }}
                  >
                    <NavLink
                      className="link"
                      onClick={() => this.handleNavigation("login")}
                    >
                      Login
                    </NavLink>
                  </NavItem>
                </div>
              )}
              {this.props.auth.isLoggedIn && this.props.auth.data && (
                <div className="menu-item-container">
                  <div className="web-only">
                    {this._ideaLink()}
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>My Account</DropdownToggle>
                      <DropdownMenu
                        rightstyle={{
                          display: this.state.mobileForMeMenu
                            ? "none"
                            : "block"
                        }}
                      >
                        <DropdownItem
                          style={{
                            display:
                              this.props.auth.isLoggedIn &&
                              (this.props.auth.data.plan !== "" ||
                                this.props.auth.data.plan !== "canceled")
                                ? "block"
                                : "none"
                          }}
                        >
                          <Link
                            to="/start"
                            onClick={() =>
                              this.setState({ mobileNavOpen: false })
                            }
                          >
                            Dashboard
                          </Link>
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            display:
                              this.props.auth.isLoggedIn &&
                              (this.props.auth.data.plan !== "" ||
                                this.props.auth.data.plan !== "canceled")
                                ? "block"
                                : "none"
                          }}
                        >
                          <Link to="/edit">Edit Links</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/account">My Settings</Link>
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem onClick={() => this.handleLogOut()}>
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <div className="mobile-only">
                    <DropdownItem
                      style={{
                        display:
                          this.state.mobileForMeMenu &&
                          this.props.auth.isLoggedIn &&
                          (this.props.auth.data.plan !== "" ||
                            this.props.auth.data.plan !== "canceled")
                            ? "none"
                            : "block"
                      }}
                    >
                      <Link
                        to="/start"
                        onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                      >
                        Dashboard
                      </Link>
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display:
                          this.state.mobileForMeMenu &&
                          this.props.auth.isLoggedIn &&
                          (this.props.auth.data.plan !== "" ||
                            this.props.auth.data.plan !== "canceled")
                            ? "none"
                            : "block"
                      }}
                    >
                      <Link
                        onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/edit"
                      >
                        Edit Links
                      </Link>
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: this.state.mobileForMeMenu
                          ? "none"
                          : "block"
                      }}
                    >
                      <Link
                        onClick={() =>
                          this.setState({ mobileNavOpen: false })
                        }
                        to="/account"
                      >
                        My Settings
                      </Link>
                    </DropdownItem>

                    {this._ideaLink()}
                    <DropdownItem divider />
                    <DropdownItem
                      style={{
                        display: this.state.mobileForMeMenu
                          ? "none"
                          : "block",
                        color: "white",
                        fontSize: 16
                      }}
                      onClick={() => this.handleLogOut()}
                    >
                      Log out
                    </DropdownItem>
                  </div>
                </div>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </Headroom>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default withRouter(
  connect(
    mapStateToProps,
    { logout }
  )(Navigation)
);
