import React from "react";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
let timeout = 0;
class SocialModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      linkable_data: this.props.linkable,
      action: Object.entries(this.props.linkable).length !== 0 ? "Edit" : "New",
      platform: this.props.platform ? this.props.platform : ""
    };
  }

  componentWillReceiveProps(props, newProps) {
    if (props !== newProps) {
      this.setState({
        action: props.linkable
          ? Object.entries(props.linkable).length !== 0
            ? "Edit"
            : "New"
          : "New",
        platform: props.platform ? props.platform : "",
        linkable_data: props.linkable
      });
    }
  }

  typeTimeout = (parent, val) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      switch (parent) {
        case "url":
          this.props.updateUrl(val);
          break;
        case "platform":
          this.setState({ platform: val });
          this.props.updatePlatform(val);
          break;
        default:
          break;
      }
    }, 700);
  };

  render() {
    let linkable_data = this.props.linkable;
    // console.log(linkable_data);
    // console.log(this.props);
    // console.log(Object.entries(this.props.linkable).length);
    return (
      <Modal open={this.props.open} onClose={this.props.closeModal} center>
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          <h2 style={{ marginBottom: 10 }}>
            {this.state.action} Social Media Link
          </h2>
          <label>Select Platform:</label>
          <select
            value={this.state.platform}
            onChange={e => this.typeTimeout("platform", e.target.value)}
          >
            <option value="" disabled>
              Select a Platform
            </option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Twitter">Twitter</option>
            <option value="Youtube">Youtube</option>
            <option value="Pinterest">Pinterest</option>
            <option value="Dribbble">Dribbble</option>
            <option value="Twitch">Twitch</option>
            <option value="Facebook Messenger">Facebook Messenger</option>
          </select>

          <label style={{ marginTop: 9 }}>Platform URL</label>
          {this.state.platform === "Facebook Messenger" && (
            <p>
              Find your Messenger link{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/help/messenger-app/android/1047811435279151"
              >
                {" "}
                here.
              </a>
            </p>
          )}
          <input
            style={{ margin: "initial", marginBottom: 9 }}
            defaultValue={
              linkable_data.destination
                ? linkable_data.destination
                : this.props.newUrl
            }
            onChange={e => this.typeTimeout("url", e.target.value)}
            maxLength="85"
          />
          <Button
            style={{ marginTop: 29, backgroundColor: "#2DBECF" }}
            onClick={this.props.newLinkable}
            color="primary"
          >
            Submit
          </Button>
          {this.state.action === "Edit" && (
            <Button
              style={{ marginTop: 29 }}
              onClick={() => this.props.deleteLink(linkable_data.id)}
              color="red"
            >
              Remove Link
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

export default SocialModal;
