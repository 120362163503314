import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import { register, enrollCustomer, clearCoupon } from './../actions/Auth';
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { inDev, stripePublishableKey } from './../Utils/Constants';
import { CardElement, injectStripe } from "react-stripe-elements";
import CouponModal from './CouponModal';
import "./../styles/home/index.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';
import ChatWithUs from './ChatWithUs';
let theToast = null;


class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      couponModalOpen: false,
      couponCodeDetails: {},
      referrer: null
    };
    // window.addEventListener("Rewardful.tracked", () => {
    //   if (
    //     window.Rewardful.referral !== null ||
    //     window.Rewardful.referral !== ""
    //   ) {
    //     this.setState({ referrer: window.Rewardful.referral });
    //   }
    // });

  }
  componentDidMount = () => {
    window.addEventListener("beforeunload", this.onUnload)

  }

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.onUnload)

  }

  onUnload = () => { // the method that will be used for both add and remove event
    this.props.clearCoupon()
  }

  handleSubmit = async e => {
    e.preventDefault();
    let validEmail = this._validateEmail(this.state.email)
    if(validEmail === false){
      return;
    }
    let referrer = null;


    theToast = toast.success("Logging In...", { autoClose: false });
    if (this.state.email !== "" && this.state.name !== "" && this.state.password !== "") {
        ReactGA.event({
          category: "Sales",
          action: "Signed Up",
          label:
            referrer !== null
              ? "From Affiliate"
              : "Without affiliate"
        });
      let { token } = await this.props.stripe.createToken({ name: this.state.name });
      if(typeof token === 'undefined') {
        toast.update(theToast, {
          autoClose: 3000,
          type: toast.TYPE.ERROR,
          render:
            "Sign up failed. Check your card info!"
        });
        return;
      }
      this.props.register(this.state.email, this.state.password, this.state.name, null).then(async res => {
        if (this.props.auth.isLoggedIn === true) {
          let coupon_code = this.props.coupons.details !== null ? this.props.coupons.details.id : null
          this.props
            .enrollCustomer(token, "pro", referrer, coupon_code)
            .then(async res => {
              const userLoggedIn = await localStorage.getItem(
                "userLoggedIn"
              );
              if (userLoggedIn) {
                toast.update(theToast, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 4000,
                  render: `Welcome ${this.props.auth.data.name.replace(
                    / .*/,
                    ""
                  )}! Let's get started!`
                });
                this.props.history.push({
                  pathname: "/start/"
                });
              } else {
                toast.update(theToast, {
                  autoClose: 3000,
                  type: toast.TYPE.ERROR,
                  render:
                    "Sign up failed. Check your email and password!!"
                });
              }
            }); // premium plan ID from Stripe

        }
        else {
          toast.update(theToast, {
            autoClose: 3000,
            type: toast.TYPE.ERROR,
            render: "Sign up failed. Check your email and password!!"
          });
        }
      });
    }
    else {
      toast.update(theToast, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
        render: "Sign up failed. Check your email and password!!"
      });
    }
  };

  _validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      return (true)
    }
    alert("You entered an invalid email address!")
    return (false)
}

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  render() {
      ReactGA.event({
        category: 'Sales',
        action: 'View Sign Up Page'
      });

    document.body.classList.remove("inner-page");
    return (
      <section className="auth-page">
        <div className="animation-circle-inverse">
          <i />
          <i />
          <i />
        </div>
        <div className="animation-circle">
          <i />
          <i />
          <i />
        </div>
        <div className="auth-card row">
          <div
            className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            style={{ marginBottom: 45 }}
          >
            <div className="text-left">
              <p style={{ color: "#2DBECF" }}>linkstackr Premium</p>
              <p style={{ marginTop: 15, fontSize: 20 }}>
                Try linkstackr for 15 days free.
              </p>
              <p style={{ marginTop: 50, fontSize: 12 }}>
                After 15 days, you will be charged
              </p>

              <h1
                className="text-left"
                style={{ color: "black", margin: 0}}
              >
                $4<span style={{ fontSize: 13 }}>/month</span>
              </h1>
              {/* <p style={{ marginTop: 5 }}>
                Your card will not be charged until your 15-day trial period
                is complete.
              </p> */}
              <a href="https://www.stripe.com">
                <img
                  src={require("./../assets/powered_by_stripe.png")}
                  height={35}
                />
              </a>
              <p style={{ fontSize: 12, color: "gray" }}>
                All transactions are securely processed with Stripe.
                </p>
              {/* <ChatWithUs prompt="Have questions about how this works?"/> */}
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* <div className="text-center">
              <p style={{ marginTop: 25 }}>
                Welcome To linkstackr, sign up to get started!
              </p>
            </div> */}
            <div className="main">
              <form className="auth-form" data-rewardful="" id="signupform">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name</label>

                  <FontAwesomeIcon
                    icon={"user"}
                    color="#a2a2a2"
                    className="fa"
                  />
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email</label>

                  <FontAwesomeIcon
                    icon={"envelope-open"}
                    color="#a2a2a2"
                    className="fa"
                  />
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <FontAwesomeIcon
                    icon={"lock"}
                    color="#a2a2a2"
                    className="fa"
                  />
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    className="form-control"
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                    padding: 10,
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    marginBottom: 20
                  }}
                >
                  <CardElement
                    style={{
                      base: { fontSize: 24, padding: 20, lineHeight: 1.5 }
                    }}
                    on={() => console.log("Completed")}
                  />
                </div>

                {/* <a href={`${process.env.PUBLIC_URL}/forget-password`} className="forgot-pass">forgot?</a> */}
                {this.props.coupons.isValid !== true &&
                    <p style={{
                      fontSize: 14,
                      cursor: "pointer",
                      width: 100,
                      textAlign: "center"
                    }} onClick={() => this.setState({couponModalOpen: true})}>Have a code?</p>
                }
                {this.props.coupons.details &&
                  this.props.coupons.details.percent_off &&
                    <p style={{color: 'green'}}>{this.props.coupons.details.percent_off}% OFF ACTIVATED</p>
                  }
                {this.props.coupons.details &&
                  this.props.coupons.details.amount_off &&
                    <p style={{color: 'green'}}>${this.props.coupons.details.amount_off} OFF ACTIVATED</p>
                  }
                {this.props.coupons.details &&
                  this.props.coupons.details.duration === "forever" &&
                    <p style={{color: 'green'}}>This will apply to every payment for the lifetime of your account.</p>
                  }
                <div className="form-group">
                  <Button
                    onClick={this.handleSubmit}
                    color="primary"
                    size={"lg"}
                    className="submit"
                    style={{ marginTop: 15 }}
                  >
                    Sign Up
                  </Button>
                  <p style={{fontSize: 12}}>By signing up, you agree to our
                    <a style={{fontSize: 12, paddingTop: 10}} href="https://app.termly.io/document/terms-of-use-for-saas/f1e624cb-cd71-4ed5-b1ff-b86e87e90b71" target="_blank">
                     {" "}terms and conditions
                  </a> and understand our <a href="https://app.termly.io/document/privacy-policy/10e4123f-e015-453c-8811-416a1032886f" style={{fontSize: 12}} target="_blank">privacy policy</a>.</p>
                </div>

                <div className="or-saparator">
                  <span>Already have an account?</span>
                </div>
                <div
                  className="form-button text-center social-btns"
                  style={{ marginTop: 15 }}
                >
                  <Link to="/login">
                    <Button type="submit" className="btn submit">
                      Login Here
                    </Button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <CouponModal
          open={this.state.couponModalOpen}
          closeModal={() => this.setState({couponModalOpen: !this.state.couponModalOpen})}
          validCode={(details) => this.setState({couponCodeDetails: details})}
        />
      </section>

    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  coupons: state.coupons
})

export default withRouter(
  connect(
    mapStateToProps,
    { register, enrollCustomer, clearCoupon }
  )(injectStripe(SignUp))
);
