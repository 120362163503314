import { apiBaseURL } from "./../Utils/Constants";
import axios from "axios";


export const getView = (slug) => {
  return axios({
    method: "get",
    url: `${apiBaseURL}/visitor/${slug}`
  }).catch((err) => {
    console.log("err: ", err)
  }).then(async res => {    
    return res.data;
  })
}