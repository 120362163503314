import React from 'react';
import About from './../UIcomponents/about';
import Feature from './../UIcomponents/feature';
import Work from './../UIcomponents/work';
import Price from './../UIcomponents/price';
import Cases from './../UIcomponents/cases';
import FAQ from './../UIcomponents/faq';
import FeatureBlock from './../UIcomponents/FeatureBlock';
import { Link } from 'react-router-dom';
import Subscribe from './../UIcomponents/subscribe';
import { Animated } from 'react-animated-css';
import scrollToElement from 'scroll-to-element';
import "./../styles/home/index.scss";
import "./../styles/fonts.scss";
import "./../styles/css/style.css";
import "./../styles/base.scss";
import FeatureOverview from './FeatureOverview';
import Drift from 'react-driftjs'
import { toast } from "react-toastify";
import quick_demo from "./../assets/video/quick_demo.mov";
import UrlReserve from './UrlReserve';
let theToast = null;
class Home extends React.Component {

  _scrollTo = (elementId) => {
    scrollToElement(`${elementId}`, {
      offset: 0,
      ease: 'in-out-quint',
      duration: 1500
    });
  }

  componentDidMount() {
    if (this.props.location.hash) {
      this._scrollTo(this.props.location.hash);
    }
    window.drift.on('ready', function (api) {
      if (window.innerWidth >= 280) {
        api.widget.show()
      }
    });
    if (
      typeof this.props.location.state !== "undefined" &&
      this.props.location.state.blocked === "yes"
    ) {
      theToast = toast.error(
        `Sorry, the linkstackr you tried to access is blocked. If you are the owner, please contact us to unblock it.`,
        {
          autoClose: 4500
        }
      );
    }
  }

  render() {
    document.body.classList.remove('inner-page');
    return (
      <div>
        <section className="slide-bg lightgray" id="home-hero">
          <div className="animation-circle gray" id="home-circles-hero">
            <i />
            <i />
            <i />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex-1" style={{ marginTop: 30 }}>
                  <div className="slide-text">
                    <Animated
                      animationIn="fadeInLeftBig"
                      animationOut="fadeOut"
                      isVisible={true}
                    >
                      <h1 className="main-title">
                        All Your Links in One Place
                      </h1>
                    </Animated>
                    {/* <h4>
                        Build yours today, free!
                      </h4> */}
                    {/* <Animated
                        animationIn="flipInX"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDelay={1500}
                      >
                        <div className="slid-btn">
                          <Link
                            to="/trial"
                            className="btn-inverse mt-2 mr-3"
                          >
                            TEST IT NOW
                          </Link>
                          <a
                            onClick={() => this._scrollTo("#features")}
                            className="btn-theme-primary mt-2 mr-3"
                          >
                            Features
                          </a>
                        </div>
                      </Animated> */}
                    {/* <h6>
                        Use on Instagram for your "link in bio" and never
                        change your bio link again!
                      </h6> */}
                    <div className="row">
                      <UrlReserve hideHeader={true} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ textAlign: "center" }}>
                <Animated
                  animationIn="fadeInRightBig"
                  animationOut="fadeOut"
                  isVisible={true}
                >
                  <img
                    className="mobile-mockup margin-top margin-bottom"
                    style={{ maxHeight: 500, width: "auto" }}
                    src="https://i.ibb.co/vs03bFc/mockuphand.png"
                  />
                </Animated>
              </div>
            </div>
          </div>
        </section>
        {/* Home One Section End */}
        {/* About Component*/}
        <About />
        <FeatureBlock
          headerText="Easy, Short URLs"
          mainText="Example: lnks.by/trev"
          subText="Customize your URL to be short and consistent with your brand. Studies show that branded URLs are much more trusted and clicked on than non-branded URLs!"
          side="left"
          photoUrl="https://i.ibb.co/qMYCt1K/loupe-1.png"
        />
        <section>
          <div className="about-chat">
            <div className="container">
              <div className="row">
                <h1 className="primary">Reserve your short URL now!</h1>
                <UrlReserve hideHeader={true} />
              </div>
            </div>
          </div>
        </section>

        {/* <Feature /> */}

        {/* Customize Component Feature */}
        <FeatureBlock
          gradient_bg={true}
          headerText="Link In Bio"
          mainText="On Instagram, never worry about your link in bio again"
          subText="Instead of choosing between all of your links to share, why not share your linkstackr page instead? Perfect for influencers, bloggers, photographers, and well... everyone!"
          photoUrl="https://i.ibb.co/x8xnLVh/Screen-Shot-2019-04-29-at-8-36-32-AM.png"
          side="right"
        />
        <FeatureBlock
          headerText="Completely Custom Pages"
          mainText="Colors, fonts, backgrounds, layouts"
          subText="Keep your page consistent with your brand, or customize your links however you want. You have complete control!"
          photoUrl="https://i.ibb.co/F7MJwCV/mockup-min-1.png"
          side="left"
        />

        <FeatureBlock
          headerText="Get Analytics"
          mainText="How many visitors do you get? Where are they going?"
          subText="Get data that helps you see what links work best - conversion rates, visits, total clicks - actionable data to help you make good decisions."
          photoUrl="https://i.ibb.co/5jnvf3c/mockup-min-2.png"
          side="right"
          gradient_bg={true}
        />

        <FeatureBlock
          headerText="100% Satisfaction Guaranteed"
          mainText="Try linkstackr Risk-Free with a 30 day money back guarantee"
          subText="We're confident you'll love linkstackr, but if for some reason you decide it's not for you, simply ask for your money back!"
          side="right"
          photoUrl="https://i.ibb.co/WHS76PR/satisfaction-guarantee-2109235-640-1.png"
        />
        {/* <FeatureBlock
          gradient_bg={true}
          headerText="Earn Recurring Commissions"
          mainText="Share linkstackr with your audience. Get paid monthly."
          subText="We like to reward linkstackrs for spreading the word! Enroll in our affiliate program and get a special link to share! You can even put it in your list of links!"
          side="left"
          photoUrl="https://i.ibb.co/pn17qgy/notes-1.png"
        /> */}
        <Price />
        <div
          className="container"
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <div className="row">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h2>Still not sure if it's right for you?</h2>
              <p>See how linkstackr can change your workflow below.</p>
            </div>
          </div>
        </div>
        {/* Cases Component*/}
        <Cases />
        {/*Feature Component*/}
        <FeatureOverview />

        <FAQ color="white" />
        <div className="tap-top">
          <div>
            <i className="fa fa-angle-up" aria-hidden="true" />
          </div>
        </div>
        <Drift appId="kxeneavd7hra" />
      </div>
    );
  }
}

export default Home;