import React from "react";
import { colors } from './../Utils/BaseStyles';
const style = {
  padding: "10px 0",
  borderBottom: "4px solid transparent",
  display: "inline-block",
  cursor: "pointer",
  backgroundColor: colors.white,
  transition: "all .2s ease",
  width: "100%",
  color: colors.primary,
  textAlign: "center",
  fontFamily: "Lato"
};

const activeStyle = {
  ...style,
  color: "white",
  borderBottom: `4px solid ${colors.primary}`,
  backgroundColor: colors.secondary
};

const CustomTabVip = ({ children, isActive }) => (
  <span style={isActive ? activeStyle : style}>{children}</span>
);

export default CustomTabVip;
