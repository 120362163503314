import { apiBaseURL } from "./../Utils/Constants";
import { UPDATE_USER_LAYOUT, USER_DATA } from "./../Utils/ActionTypes";

import axios from "axios";

export const updateUserData = data => {
  let linkables = [];
  data.linkables.map(linkable => {
    linkables.push(linkable);
  });
  console.log("DATA: ", data.checkmarkAvatar)

  return dispatch => {
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update_data`,
      data: JSON.stringify({
        user: {
          page_title: data.pageTitle,
          page_subtitle: data.pageSubTitle,
        },
        layout: {
          title_font: data.pageTitleFont,
          background_color: data.backgroundColor,
          linkable_background_color: data.linkableBackgroundColor,
          linkable_text_color: data.linkableTextColor,
          linkable_border_color: data.linkableBorderColor,
          linkable_rounded: data.linkableRounded,
          linkable_font: data.linkableTextFont,
          text_color: data.pageTextColor,
          other_text_color: data.otherTextColor,
          text_font: data.otherTextFont,
          background_is_image: data.useImageBackground,
          background_image_url: data.imageBackgroundUrl,
          background_type: data.backgroundType,
          unsplash_user: data.unsplashUser,
          unsplash_user_link: data.unsplashUserLink,
          gradient_one_color: data.gradientOne,
          gradient_two_color: data.gradientTwo,
          design: data.layout,
          header_center_aligned: data.header_center_aligned,
          shining_avatar: data.shiningAvatar,
          checkmark_avatar: data.checkmarkAvatar,
          avatar_pulse_ring: data.avatarPulseRing
        },
        linkables
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {
        dispatch({
          type: UPDATE_USER_LAYOUT,
          payload: res.data
        });
      })
      .catch(err => {});
  };
};

export const updateLinkable = (destination, title, description = null, file = null, id = null, active = true, highlight, platform = null, visible = true, start_date = null, end_date = null) => {
  let formData = new FormData()
  formData.set('linkable[destination]', destination)
  formData.set('linkable[title]', title)
  formData.set('linkable[active]', active)
  formData.set('linkable[highlight]', highlight)
  formData.set('linkable[visible]', visible)
  formData.set('linkable[platform]', platform)
  formData.set('linkable[start_date]', start_date)
  formData.set('linkable[end_date]', end_date)
  if (description) {
    formData.set('linkable[description]', description)
  }
  if (file) {
    formData.append('linkable[thumbnail]', file)
  }
  if (id) {
    formData.set('linkable[id]', id)
  }

  return dispatch => {
    return axios({
      method: "put",
      url: `${apiBaseURL}/user/linkable`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": 'multipart/form-data',
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {
        dispatch({
          type: UPDATE_USER_LAYOUT,
          payload: res.data
        });
      })
      .catch(err => {

      })
  };
};

export const updateUser = (id, avatar = null) => {
  let formData = new FormData()
  if (avatar) {
    formData.append("user[avatar]", avatar, "User Avatar");
  }

  return dispatch => {
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update/${id}`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": 'multipart/form-data',
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {
        dispatch({
          type: UPDATE_USER_LAYOUT,
          payload: res.data
        });
      })
      .catch(err => {

      })
  };
};

export const updateUserInfo = (id, data = {}) => {
  return dispatch => {
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update/${id}`,
      data: JSON.stringify({
        user: {
          name: data.name,
          slug: data.slug,

        }
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {
        dispatch({
          type: USER_DATA,
          payload: res.data
        });
      })
      .catch(err => {
        if(err.toString().indexOf("422") !== -1){
          window.alert(`Sorry, that URL is in use by someone else. Try another.`);
        }

      });
  };
};


export const updateUserUrl = (id, url) => {  
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update_url/${id}`,
      data: JSON.stringify({
        user: {         
          slug: url,

        }
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {        
      })
      .catch(err => {
        if(err.toString().indexOf("422") !== -1){
          window.alert(`Sorry, that URL is in use by someone else. Try another.`);
        }

      
  })
};



export const updateLayout = (data) => {
  let formData = new FormData()
  if (data["newBackgroundImage"]) {
    formData.set("layout[background_image]", data["newBackgroundImage"]);
  }
  return dispatch => {
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update_background`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": 'multipart/form-data',
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(async res => {
        dispatch({
          type: UPDATE_USER_LAYOUT,
          payload: res.data
        });
      })
      .catch(err => {

      })
  };
};

