import { library } from "@fortawesome/fontawesome-svg-core";
import { faDribbble, faFacebookSquare, faInstagram, faLinkedin, faTwitch, faTwitterSquare, faYoutubeSquare, faPinterestSquare, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faAddressCard, faArrowCircleLeft, faArrowCircleRight, faCheckCircle, faUserCheck, faBars, faBookOpen, faCameraRetro, faCaretDown, faCaretUp, faChartLine, faCheck, faMedal, faCubes, faEdit, faEnvelopeOpen, faEye, faEyeSlash, faLock, faMagic, faMinusCircle, faMoneyBillWave, faPencilAlt, faQrcode, faSearch, faTimes, faTrash, faUser, faWifi } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from '../UIcomponents/footer';
import Navbar from "../UIcomponents/navbar";
import PageNotFound from './404';
import Account from "./Account";
import Analytics from './Analytics';
import UseCase from "./cases/UseCase";
import Dashboard from './Dashboard';
import Edit from "./Edit";
import Home from "./Home";
import Login from "./Login";
import Pricing from "./Pricing";
import ProtectedRoute from './ProtectedRoute';
import PublicView from "./PublicView";
import SignUp from "./SignUp";
import TermsAndConditions from './TermsAndConditions';
import ResetPassword from "./ResetPassword";
import TrialPrompt from "./TrialPrompt";
import Publish from "./Publish";
import VipSignUp from "./VipSignUp";
import Cookies from 'universal-cookie';
import queryString from 'query-string';
library.add(
  faSearch,
  faEdit,
  faCheck,
  faCheckCircle,
  faEyeSlash,
  faEye,
  faCaretDown,
  faCaretUp,
  faMinusCircle,
  faTrash,
  faCameraRetro,
  faEnvelopeOpen,
  faLock,
  faUser,
  faMagic,
  faChartLine,
  faPencilAlt,
  faTimes,
  faArrowCircleLeft,
  faArrowCircleRight,
  faBars,
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faTwitch,
  faLinkedin,
  faDribbble,
  faAddressCard,
  faWifi,
  faBookOpen,
  faCubes,
  faQrcode,
  faMoneyBillWave,
  faYoutubeSquare,
  faPinterestSquare,
  faFacebookMessenger,
  faMedal,
  faUserCheck
);




class Main extends Component {
  render() {
    const cookies = new Cookies();
    var parsed = queryString.parse(this.props.location.search);
    if(typeof parsed.via !== 'undefined'){
      console.log("PARSED VIA: ", parsed.via)
      if(typeof(cookies.get('referred_by')) === "undefined"){
        cookies.set('referred_by', parsed.via, { path: '/' });
        console.log("Referred by: ", cookies.get('referred_by'));
      } else {
        console.log("Current referral ", parsed.via, " cannot override the first affiliate: ", cookies.get('referred_by'));
      }
    }

    let onPublic = this.props.location.pathname.indexOf("/v") !== -1;
    let onPreview = this.props.location.pathname.indexOf("/preview") !== -1;
    let onEdit = this.props.location.pathname.indexOf("/edit") !== -1;
    let onHome = this.props.location.pathname === "/";

    return (
      <div>
        <div style={{ minHeight: "90vh" }}>
          {!onPublic && !onPreview && <Navbar dark={onHome ? false : true} />}
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/trial" component={TrialPrompt}/>
              <Route exact path="/vip" component={VipSignUp}/>
              <Route exact path="/register" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/terms" component={TermsAndConditions} />
              <Route exact path="/publish" component={Publish} />
              <Route exact path="/reset_password/:user_token" component={ResetPassword} />
              <Route path="/for/:who" component={UseCase} />
              <ProtectedRoute isLoggedIn={this.props.isLoggedIn} path="/start/" component={Dashboard} />
              <ProtectedRoute isLoggedIn={this.props.isLoggedIn} path="/account/" component={Account} />
              <ProtectedRoute isLoggedIn={this.props.isLoggedIn} path="/analytics/" component={Analytics} />
              <ProtectedRoute isLoggedIn={this.props.isLoggedIn} path="/edit/" component={() => <Edit isTrial={false}/>} />
              <Route path="/v/:slug" component={() => <PublicView preview={false} />} />
              <ProtectedRoute isLoggedIn={this.props.isLoggedIn} path="/preview" component={() => <PublicView preview={true} />} />
              <Route path="/404" component={PageNotFound} />
              <Route component={PageNotFound} />
            </Switch>
          </main>
          <ToastContainer
            className="toast-container"
            toastClassName="toast-content"
            position="bottom-center"
            progressStyle={{
              background:
                "linear-gradient(to right, #5a3bf7 54%,#2989d8 79%)"
            }}
            pauseOnHover={false}
          />
        </div>

        {!onPublic && !onPreview && !onEdit && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn
});
export default withRouter(
  connect(
    mapStateToProps,
    { }
  )(Main)
);
