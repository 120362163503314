import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import badwords from './../Utils/InappropriateWords.json';
import { Button } from "reactstrap";
import { updateUserUrl } from '../actions/UpdateData';
import axios from 'axios';
import { apiBaseURL, reservedUrls } from './../Utils/Constants';

class UrlReserve extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			code: '',
			isValid: null,
			showAdvance: false,
			url: this.props.url || 'custom'
		};
	}

	_validateUrl = async (code) => {
		if(code.indexOf(" ") !== -1){
			this.setState({notFormatted: true, isLoading: false})
			return;
		}
		code = code.replace(" ", "");
		this.setState({ isLoading: true, url: code })
		if(reservedUrls.includes(code) || badwords.includes(code)){
			this.setState({ isLoading: false, url: code, isValid: false })
			return;
		}
		return axios({
			method: "get",
			url: `${apiBaseURL}/validate_url/${code}`,
			headers: {
				"Content-Type": "application/json; charset=UTF-8"
			}
		})
			.then(async res => {				
				if (res.data.status === 200) {
					this.setState({ isLoading: false, isValid: true, showAdvance: true })
				}
				else {
					this.setState({ isLoading: false, isValid: false, showAdvance: false })
				}
			})
			.catch(err => {
				this.setState({ isLoading: false, isValid: false, showAdvance: false })				
				return err;
				
			})
	}
	_moveToPublish = async () => {
		if(this.props.hideHeader && this.props.hideHeader === true){
			this.props.history.push({
				pathname: '/trial',
				state: { slug: this.state.url}
			  })
			return;
		}
		updateUserUrl(this.props.auth.data.id,this.state.code)
		this.props.history.push("/publish");
	}

	_handleCodeInput = (e) => {

		this.setState({ code: e.target.value, showAdvance: false, notFormatted: false })
	}

	render() {
		return (
			
				<div style={{ display: "flex", flexDirection: "column", margin: "0 auto", padding: 10, maxWidth: 500, textAlign: 'center' }}>
					<h1 className="primary">Try it free!</h1>
					<h2 style={{ marginBottom: 10, display: this.props.hideHeader === true ? 'none' : 'block' }}>Get your URL!</h2>
					<p>Get your custom URL before it's gone!</p>
					{this.state.isValid === false &&
						<p style={{ color: 'red' }}>Sorry, that URL is unavailable.</p>
					}		
					{this.state.notFormatted === true &&
						<p style={{ color: 'red' }}>Limit your URL to alphanumeric characters only, with no spaces.</p>
					}		
					<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 15}}>
						<p>lnks.by/</p>
						<input
							style={{ marginTop: 9, marginBottom: 9, margin: "initial", width: "initial" }}
							value={this.state.code}
							onChange={e => this._handleCodeInput(e)}
							maxLength="85"
						/>
					</div>

					<Button
						style={{margin: "0 auto", marginTop: 29, width: 200,  backgroundColor: "#2DBECF",  display: this.state.showAdvance ? "block" : "none" }}
						onClick={() => this._moveToPublish()}
						color="primary"
						disabled={this.state.isLoading}
					>
						🎉 Claim URL! 🎉
				</Button>
					
					<Button
						style={{ margin: "0 auto", marginTop: 29, width: 200 }}
						onClick={ () => this._validateUrl(this.state.code)}
						color="primary"
						disabled={this.state.isLoading}
					>
						{this.state.showAdvance ? "Check Another URL" : "Check Availability"}
				</Button>


				</div>			
		)
	}
}


const mapStateToProps = state => ({
	auth: state.auth,
	coupons: state.coupons
});

export default withRouter(
	connect(
		mapStateToProps,
		{  }
	)(UrlReserve)
);
