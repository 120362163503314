import React from "react";
export default class ChatWithUs extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		let prompt = this.props.prompt || "Have questions?"
		return (
			<div className="hidden row" style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
				<p style={{ textAlign: 'center' }}>{prompt}</p>
				<a className="btn-theme-primary mt-2 drift-open-chat hidden">Chat with us</a>
			</div>
		)
	}
}
