import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { colors } from "../Utils/BaseStyles";
import QRCode from "qrcode.react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import AccountEditModal from "./AccountEditModal";
import { refreshData } from "./../actions/Auth";
import { toast } from "react-toastify";
import axios from "axios";
import { apiBaseURL } from "../Utils/Constants";
let theToast = null;
class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accountEditModalOpen: false,
      type: "name",
      name: this.props.auth.data.name,
      slug: this.props.auth.data.slug,
      plan: this.props.auth.data.plan,
      uid: this.props.auth.data.id,
      email: this.props.auth.data.email
    };
  }

  componentDidMount() {
    this.props.refreshData();
    if(window.location.href.includes("#billing")){
      this.requestBillingPortal();
    }
    if (
      typeof this.props.location.state !== "undefined" &&
      this.props.location.state.plan_active === "no"
    ) {
      let ref_page = this.props.location.state.page
        ? this.props.location.state.page
        : "page you were trying to access";
      theToast = toast.error(
        `Sorry, the ${ref_page} is restricted to users that have an active subscription. Update your plan details in the "Account" page under "plan".`,
        {
          autoClose: 6700
        }
      );
    }
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };
  toggleModal = type => {
    this.setState({
      accountEditModalOpen: !this.state.accountEditModalOpen,
      type: type
    });
  };

  componentWillReceiveProps = (nextProps, props) => {
    if (props !== nextProps) {
      if(nextProps.auth.data){
        this.setState({
          name: nextProps.auth.data.name,
          slug: nextProps.auth.data.slug,
          plan: nextProps.auth.data.plan,
          email: nextProps.auth.data.email
        });
      }
    }
  };
  download = () => {
    const canvas = document.querySelector("canvas");
    this.downloadRef.href = canvas.toDataURL();
    this.downloadRef.download = "linkstackrQRcode.png";
  };

  requestBillingPortal = async () => {
    return axios.post(
      `${apiBaseURL}/user/billing_portal`,
        {user: this.state.uid}
      )
    .then(async (res) => {
      console.log("RES: ", res);
      if(res.data && res.data.redirect){
        window.location.href = res.data.redirect;
      } else {
        console.log("Hmmm. Something did not work right.");
      }

    }).catch(err => {
      console.log("ERR: ", err);
    })
  }

  render() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("inner-page");
    return (
      <div>
        {/*breadcrumb start*/}
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 d-align-center">
                <h2 className="title">
                  <Link to="/start/">
                    <span
                      style={{
                        fontSize: 22,
                        textTransform: "lowercase",
                        color: "#c7c7c7",
                        marginRight: 5
                      }}
                    >
                      Dashboard
                    </span>
                  </Link>
                  <span> Account </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/*breadcrumb end*/}

        {/*Review Section Start*/}

        <div className="container">
          <div className="row margin-bottom">
            <div className="col-sm-12">
              <div style={{ textAlign: "center", paddingBottom: 45 }}>
                <div className="label-input-pair-left">
                  <label>Display Name</label>
                  <p>
                    {this.state.name}
                    {"   "}
                  </p>
                  <span
                    style={{
                      color: colors.primary,
                      cursor: "pointer",
                      textDecorationLine: "underline"
                    }}
                    onClick={() => this.toggleModal("name")}
                  >
                    {"  "}update
                  </span>
                </div>
                <div className="label-input-pair-left">
                  <label>Personal URL</label>
                  <a href={`https://www.linkstackr.com/v/${this.state.slug}`}>
                  <p
                      style={{
                        color: colors.primary
                      }}
                    >
                      https://lnks.by/
                      {this.state.slug}{" "}
                    </p>
                  </a>

                  <span
                    style={{
                      color: colors.primary,
                      fontSize: 16,
                      cursor: "pointer",
                      textDecorationLine: "underline"
                    }}
                    onClick={() => this.toggleModal("url")}
                  >
                    change
                  </span>
                </div>

                <div className="label-input-pair-left">
                  <label>linkstackr QR Code</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <QRCode
                      value={`https://www.linkstackr.com/v/${this.state.slug}`}
                      size={85}
                      level={"H"}
                    />
                    <a
                      style={{ color: colors.primary }}
                      ref={ref => (this.downloadRef = ref)}
                      onClick={() => this.download()}
                    >
                      Download
                    </a>
                  </div>
                </div>
                <div className="label-input-pair-left">
                  <a className="btn-theme-primary" onClick={() => this.requestBillingPortal()}>
                    Manage Billing
                  </a>
                </div>
              </div>
              {/* <div className="label-input-pair-center">
                <label>Get commissions for sharing linkstackr!</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <a
                    className="btn-theme-primary"
                    href="https://linkstackr.getrewardful.com/signup"
                  >
                    Join our affiliate program here!
                  </a>
                </div>
              </div> */}
            </div>

            <AccountEditModal
              open={this.state.accountEditModalOpen}
              closeModal={() =>
                this.setState({
                  accountEditModalOpen: false
                })
              }
              type={this.state.type}
              url={this.state.slug}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(
    mapStateToProps,
    { refreshData }
  )(Account)
);
