import React from "react";
import Typist from 'react-typist';
import ScrollMenu from "react-horizontal-scrolling-menu";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// HORIZONTAL MENU ITEMS START //
// list of items
const caseList = [
  { name: "Fashion Blogger", position: 1, url: "fashion" },
  { name: "Photographer", position: 2, url: "photographers" },
  { name: "Social Media Influencer", position: 3, url: "influencer" },
  { name: "Traveler", position: 4, url: "traveler" },
  { name: "YouTube Sensation", position: 5, url: "youtuber" },
  { name: "Online Seller", position: 6, url: "seller" },
  { name: "Food Blogger", position: 7, url: "food" },
  { name: "Podcaster", position: 8, url: "podcast" },
  { name: "Recruiter", position: 9, url: "recruit" },
  { name: "Real Estate Agent", position: 10, url: "realestate" },
  { name: "I Send Lots Of Emails", position: 11, url: "emails" }
];

const MenuItem = ({ text, url, selected }) => {
  return <a href={`/for/` + url} ><div className={`menu-item ${!selected ? "active" : ""}`}>{text}</div></a>;
};

export const Menu = (list, selected) =>
  list.map(el => {
    const {name, url} = el;

    return <MenuItem text={name} key={name} url={url} selected={selected} />;
  });

const ArrowLeft = (
  <FontAwesomeIcon
    icon={"arrow-circle-left"}
    className="fa-2x"
    color="#fff"
    style={{marginRight: 20}}
  />
);
const ArrowRight = (
  <FontAwesomeIcon
    icon={"arrow-circle-right"}
    className="fa-2x"
    color="#fff"
    style={{ marginLeft: 20 }}
  />
);

const selected = 'Fashion Blogger';

// HORIZONTAL MENU ITEMS END //

class Cases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typing: true,
      selected: "Fashion Blogger"
    };
    this.menuItems = Menu(caseList, selected);
  }

  done = () => {
    setTimeout(() => {
      this.setState({ typing: false }, () => {
        this.setState({ typing: true });
      });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = 0;
    }
  };
  onSelect = key => {
    this.setState({ selected: key });
  };

  endTyping = (line = "⏰") => {
    if (line.indexOf("⏰") !== -1 || line.indexOf("🔙") !== -1) return;
    // if (this.refs.scroller) {
    //   this.refs.scroller.scrollTo(line);
    // }
  };

  render() {
    const { selected } = this.state;
    const menu = this.menuItems;

    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      autoplay: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    let data = [
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo: "https://i.ibb.co/jyr0Nf0/travelx.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/pLwFw3N/Screen-Shot-2019-04-06-at-8-46-29-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo: "https://i.ibb.co/jyr0Nf0/travelx.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo: "https://i.ibb.co/jyr0Nf0/travelx.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo: "https://i.ibb.co/jyr0Nf0/travelx.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      },
      {
        photo:
          "https://i.ibb.co/Xp6kLdP/Screen-Shot-2019-04-05-at-7-46-13-AM-iphonexspacegrey-portrait.png",
        description:
          "lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem ipsum dolor sit amet."
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div key={i}>
          <img className="mobile-mockup" src={val.photo} alt="top1" />
        </div>
      );
    });

    return (
      <section className="theme-bg" id="package">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-6">
              <Slider ref="slider" {...settings}>
                {DataList}
              </Slider>
            </div> */}
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center", textAlign: 'center' }}
            >
              <div className="section-title center">
                <h2 className="text-white">I'm a </h2>
                <div style={{ height: 130 }}>
                  {this.state.typing && (
                    <Typist
                      onLineTyped={line => this.endTyping(line)}
                      cursor={{ show: false }}
                      onTypingDone={this.done}
                    >
                      <h1 className="text-white">Fashion Blogger</h1>
                      <Typist.Backspace
                        count={15}
                        delay={2200}
                        Delay
                        ms={10000}
                      />

                      <h1 className="text-white">Photographer</h1>
                      <Typist.Backspace
                        count={12}
                        delay={1200}
                        onLineTyped={this.endTyping()}
                      />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">
                        Social Media Influencer
                      </h1>
                      <Typist.Backspace
                        count={23}
                        delay={1200}
                        onLineTyped={this.endTyping}
                      />
                      <h1 className="text-white">Traveler</h1>
                      <Typist.Backspace count={8} delay={1200} />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">YouTube Sensation</h1>
                      <Typist.Backspace count={17} delay={1200} />
                      <h1 className="text-white">Online Seller</h1>
                      <Typist.Backspace count={13} delay={1200} />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">Food Blogger</h1>
                      <Typist.Backspace count={12} delay={1200} />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">Podcaster</h1>
                      <Typist.Backspace count={9} delay={1200} />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">Recruiter</h1>
                      <Typist.Backspace count={9} delay={1200} />
                      <Typist.Delay ms={200} />
                      <h1 className="text-white">Real Estate Agent</h1>
                      <Typist.Backspace count={12} delay={1200} />
                      <Typist.Delay ms={1000} />
                      <h1 className="text-white">Big Deal.</h1>
                    </Typist>
                  )}
                </div>
                {/* <img
                  src={require("../styles/images/white-line.png")}
                  alt="title-line"
                  className="img-fluid"
                /> */}
                <h4 className="text-white">
                  linkstackr is (especially) for you.
                </h4>
              </div>
            </div>
            <div className="container" style={{ marginTop: 30}}>
              <div className="row">
                <div className="col-md-12">
                  <div style={{ marginBottom: 30, textAlign: 'center' }}>
                    <h3 className="white">
                      Which of these roles best describe you?
                    </h3>
                    <p className="white">
                      See how linkstackr can help you specifically by
                      clicking on these options:
                    </p>
                  </div>
                  <ScrollMenu
                    ref="scroller"
                    data={menu}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selected}
                    hideSingleArrow={true}
                    onSelect={this.onSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cases;
