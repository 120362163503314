import React from 'react';
import { Link } from 'react-router-dom';
class About extends React.Component {
  render() {

  	return (
      <div>
      <section id="about" className="pb-0 about">
        <div className="about-chat">
          <div className="container ">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-title">
                  <h1 className="primary" style={{ textAlign: "center" }}>
                    Unlimited Links in Your Bio
                  </h1>
                  {/* <img
                    src={require("../styles/images/title-line.png")}
                    alt="title-line"
                    className="img-fluid"
                  /> */}
                </div>
              </div>
              <div style={{ margin: "0 auto" }}>
                <h3
                  className="black"
                  style={{ textAlign: "center", paddingTop: 50 }}
                >
                  You can create links to anywhere and share as many as you want from a single URL!
                </h3>
                {/* <h3 className="black" style={{ textAlign: "center" }}>
                  With your custom landing page, you can generate more leads
                  and traffic than ever before to the links of your choice!
                </h3> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-center">
          <div className="row-of-images">
            <img
              id="far-left"
              src="https://i.ibb.co/jyr0Nf0/travelx.png"
              alt="top1"
            />
            <img
              id="left"
              src="https://i.ibb.co/T8V5J6q/Screen-Shot-2019-04-14-at-12-16-25-AM-iphonexspacegrey-portrait.png"
              alt="top1"
            />
            <img
              id="center"
              src="https://i.ibb.co/cTkmX4m/Screen-Shot-2019-04-14-at-12-05-45-AM-iphonexspacegrey-portrait.png"
              alt="top1"
            />
            <img
              id="right"
              src="https://i.ibb.co/3p1TV7Q/Screen-Shot-2019-04-13-at-11-51-52-PM-iphonexspacegrey-portrait.png"
              alt="top1"
            />
            <img
              id="far-right"
              src="https://i.ibb.co/8bcFRcJ/Screen-Shot-2019-04-14-at-12-25-58-AM-iphonexspacegrey-portrait.png"
              alt="top1"
            />
          </div>
        </div>
      </section>
      <section className="theme-bg">
            <div className="container">
              <div className="col-md-12 text-center">
                <div className="section-title">
                  <h1 className="text-white">What's linkstackr?</h1>
                  {/* <img
                    src={require("../styles/images/white-line.png")}
                    alt="white-line"
                    className="img-fluid"
                  /> */}
                </div>
                <p className="white">A platform that allows you to customize a page entirely. On the page, you can add as many links to different places across the web as you want. But the beauty of it is that you get a simple, short, personal URL that you can share with your audience to bring your audience to your links.</p>
                <br></br>
                <p style={{paddingBottom: 30}} className="white">It's so easy to do, you can have it set up in minutes. Want to try it out? No credit card required!</p>
                <Link
                            to="/trial"
                            className="btn-theme-primary mt-2 mr-3"
                          >
                            Test it out
                          </Link>
              </div>
              </div>
              </section>
              </div>
    );
  }
}


export default About;