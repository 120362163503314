import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { register, facebook_login } from '../actions/Auth';
import FacebookLogin from 'react-facebook-login';
import ReactGA from 'react-ga';

let theToast = null;
class VipSignUp extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			email: '',
			name: '',
			password: '',
			unlockPasscode: 'VIP2020',
			unlockString: '',
			unlocked: false

		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.auth.isLoggedIn !== this.props.auth.isLoggedIn ||
			nextProps.auth.isFetching !== this.props.auth.isFetching) {
			return false;
		}
		return true;
	}

	_register = async e => {
		e.preventDefault();
		let validEmail = this._validateEmail(this.state.email)
		if (validEmail === false) {
			return;
		}


		theToast = toast.success("Logging In...", { autoClose: false });
		if (this.state.email !== "" && this.state.name !== "" && this.state.password !== "") {
			ReactGA.event({
				category: "Sales",
				action: "Trial - Sandbox"
			});
			var slug = typeof(this.props.location.state) === 'undefined' ? null : this.props.location.state.slug;
			this.props.register(this.state.email, this.state.password, this.state.name, slug, true).then(async res => {
				if (this.props.auth.isLoggedIn) {
					toast.update(theToast, {
						type: toast.TYPE.SUCCESS,
						autoClose: 4000,
						render: `Welcome ${this.props.auth.data.name.replace(
							/ .*/,
							""
						)}! Let's get started!`
					});
					this.props.history.push({
						pathname: '/edit',
						state: { custom_slug: slug }
					  })

				} else {
					toast.update(theToast, {
						autoClose: 3000,
						type: toast.TYPE.ERROR,
						render:
							"Sign up failed. Check your email and password!!"
					})

				}
			})
		}
		else {
			toast.update(theToast, {
				autoClose: 3000,
				type: toast.TYPE.ERROR,
				render: "Sign up failed. Check your email and password!!"
			});
		}
	};

	_validateEmail(email) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return (true)
		}
		alert("You entered an invalid email address!")
		return (false)
	}

	handleEmailChange = e => {
		this.setState({ email: e.target.value });
	};

	handlePasswordChange = e => {
		this.setState({ password: e.target.value });
	};
	handleNameChange = e => {
		this.setState({ name: e.target.value });
	};
	handleUnlockString = e => {
		this.setState({ unlockString: e.target.value });
	};
	checkUnlockPasscode = () => {
		if(this.state.unlockPasscode === this.state.unlockString){
			this.setState({unlocked: true})
			theToast = toast.success("Thanks! Let's get you started!", { autoClose: 5000, position: "bottom-center" });
		} else {
			theToast = toast.error("Incorrect password. Try again please!", { autoClose: 3000, position: "bottom-center" });
		}
	}
	responseFacebook = (response) => {
		let referrer = null;
		// if (typeof window.Rewardful !== "undefined") {
		// 	if (
		// 		window.Rewardful.referral !== null ||
		// 		window.Rewardful.referral !== ""
		// 	) {
		// 		referrer = window.Rewardful.referral;
		// 	}
		// }
		theToast = toast.success("Logging In... Please wait...", { autoClose: false, position: "bottom-center" });
		this.props.facebook_login(response["accessToken"]).then(async res => {
			if (this.props.auth.isLoggedIn === true) {
				const userLoggedIn = await localStorage.getItem(
					"userLoggedIn"
				);
				if (userLoggedIn) {
					ReactGA.event({
						category: "Sales",
						action: "Signed Up",
						label:
							referrer !== null
								? "From Affiliate"
								: "Without affiliate"
					});
					toast.update(theToast, {
						type: toast.TYPE.SUCCESS,
						autoClose: 4000,
						render: `Success! Let's get started!`,
						position: "bottom-center"
					});
					this.props.history.replace("/edit")
				} else {
					toast.update(theToast, {
						autoClose: 3000,
						type: toast.TYPE.ERROR,
						render: "Sign in failed. Check your email and password!!",
						position: "bottom-center"
					});
				}
			} else {
				toast.update(theToast, {
					autoClose: 3000,
					type: toast.TYPE.ERROR,
					render: "Sign in failed. Check your email and password!!",
					position: "bottom-center"
				});
			}
		});
	}

	render() {
		if(this.state.unlocked){
			return (
				<div style={{ display: "flex", flexDirection: "column", padding: 30, textAlign: 'center' }}>
					<h2 style={{ marginBottom: 20 }}>Welcome to Linkstackr VIP! 👋</h2>
					<p style={{ paddingBottom: 15 }}>In order to save your progress, we need your email address and a password!</p>
					<label>Your Display Name:</label>
					<input
						className="reg-input"
						style={{ marginBottom: 5 }}
						value={this.state.name}
						onChange={this.handleNameChange}
						maxLength="85"
					/>

					<label>Your Email:</label>
					<input
						className="reg-input"
						style={{ marginBottom: 5 }}
						value={this.state.email}
						onChange={this.handleEmailChange}
						maxLength="85"
					/>

					<label>Your Password:</label>
					<input
						className="reg-input"
						style={{ marginBottom: 25 }}
						value={this.state.password}
						onChange={this.handlePasswordChange}
						maxLength="85"
						type="password"
					/>
					<Button
						style={{ marginTop: 10, marginBottom: 10, backgroundColor: "#2DBECF", width: 300, margin: "0 auto" }}
						onClick={this._register}
						color="primary"
						disabled={this.props.auth.isFetching}
					>
						Get Started
						</Button>
					{/* <div className="or-saparator" style={{ marginTop: 10 }}><span>Or</span></div>
					<div style={{ padding: 20 }}>
						<FacebookLogin
							appId="2246737035541687"
							autoLoad={false}
							fields="name,email,picture"
							callback={(response) => this.responseFacebook(response)} />
					</div> */}
				</div>
			)
		}
		else {
			return(
				<div style={{ display: "flex", flexDirection: "column", padding: 30, textAlign: 'center' }}>
					<h2 style={{ marginBottom: 20 }}>Welcome to Linkstackr VIP!</h2>
					<p style={{ paddingBottom: 15 }}>We're glad you're here! Please enter the VIP password you were given in order to get started!</p>
					<input
						type="password"
						placeholder="Enter password here..."
						className="reg-light-input"
						style={{ marginBottom: 25, textAlign: 'center' }}
						value={this.state.unlockString}
						onChange={this.handleUnlockString}
						maxLength="85"
					/>

					<Button
						style={{ marginTop: 10, marginBottom: 10, backgroundColor: "#2DBECF", width: 300, margin: "0 auto" }}
						onClick={this.checkUnlockPasscode}
						color="primary"
						// disabled={this.props.auth.isFetching}
					>
						Enter
					</Button>
				</div>
			)
		}

	}
}


const mapStateToProps = state => ({
	auth: state.auth,
	coupons: state.coupons
});

export default withRouter(
	connect(
		mapStateToProps,
		{ register, facebook_login }
	)(VipSignUp)
);
