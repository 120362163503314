import React from 'react';
import Modal from 'react-responsive-modal';
import { Button } from "reactstrap";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

let timeout = 0;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 320,
  },
}));

const LinkableModal = (props) => {
  let linkable_data = props.linkable
  const classes = useStyles();
  let action = linkable_data.id ? "Edit" : "New";
  const typeTimeout = (parent, val) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      switch (parent) {
        case "title":
          props.updateTitle(val, linkable_data.id);
          break;

        case "description":
          props.updateDescription(val);
          break;
        case "url":
          props.updateUrl(val);
          break;

        case "url":
          props.updateUrl(val);
          break;

        default:
          break;
      }
    }, 50);

  }

  return (
    <Modal open={props.open} onClose={props.closeModal} center>
      <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
        <h2 style={{ marginBottom: 10 }}>{`${action} Link`}</h2>
        <strong>Title (Label of your link seen by visitors)</strong>
        <input
          style={{ margin: 'initial', marginBottom: 9 }}
          defaultValue={
            linkable_data.title ? linkable_data.title : props.newTitle
          }
          onChange={e => typeTimeout("title", e.target.value)}
          maxLength="55"
        />
        {/* <label>Description</label>
        <input
          style={{ marginBottom: 9 }}
          defaultValue={
            linkable_data.description
              ? linkable_data.description
              : props.newDescription
          }
          onChange={e => typeTimeout("description", e.target.value)}
        /> */}
        <strong>
          URL (Where should visitors go when they click on the link?)
        </strong>
        <input
          style={{ margin: 'initial', marginBottom: 9 }}
          defaultValue={
            linkable_data.destination
              ? linkable_data.destination
              : props.newUrl
          }
          onChange={e => typeTimeout("url", e.target.value)}
        />
        <div className="edit-side-by-side">
          <label>Visible to your audience? (Checked = Yes)</label>
          <input
            type="checkbox"
            defaultChecked={linkable_data.visible}
            onChange={() => props.updateVisible(linkable_data.id)}
          />
        </div>
        <div className="edit-side-by-side">
        <label>Highlight with Animation (List View only)</label>
        <input
          type="checkbox"
          defaultChecked={linkable_data.highlight}
          onChange={() => props.updateHighlight(linkable_data.id)}
        />
        </div>
        <strong>
          Scheduling: Start Displaying on Date
        </strong>
        <TextField
          id="datetime-local"
          type="datetime-local"
          defaultValue={
            linkable_data.start_date ?
              moment.utc(linkable_data.start_date).local().format('YYYY-MM-DDTHH:mm:ss') :
              props.newStartDate
          }
          className={classes.textField}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={e => {
            props.setStartDate(linkable_data.id, e.target.value)
          }}
        />
        <strong>
          Scheduling: Stop Displaying On Date
        </strong>
        <TextField
          id="datetime-local"
          type="datetime-local"
          defaultValue={linkable_data.end_date ?
            moment.utc(linkable_data.end_date).local().format('YYYY-MM-DDTHH:mm:ss') :
            null
          }
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={e => props.setEndDate(linkable_data.id, e.target.value)}
        />
        <strong>
          Upload Link Image (optional - Only used in the Image Grid Layout)
        </strong>
        <input
          type="file"
          name=""
          id=""
          style={{ margin: 'initial', marginBottom: 9 }}
          onChange={e => props.handleselectedFile(e)}
        />
        <Button
          style={{ backgroundColor: "#2DBECF" }}
          onClick={props.newLinkable}
          color="primary"
        >
          Submit
        </Button>
        {action === "Edit" && (
          <Button
            style={{ marginTop: 20 }}
            onClick={() => props.deleteLink(linkable_data.id)}
            color="danger"
          >
            Remove Link
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default LinkableModal;