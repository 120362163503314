import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const GridLinkable = props => {
  if (props.edit) {
    return (
      <div>
        <p style={{ color: props.textColor, fontFamily: props.font }}>
        {!props.linkableData.visible &&
          <FontAwesomeIcon
          icon={"eye-slash"}
          color={props.textColor}
          style={{ height: 15, marginRight: 20 }}
          />
        }
          {props.linkableData.title}
        </p>

        {/* <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            className="linkable-edit-action-button"
            style={{
              borderRadius: props.linkableRounded ? 40 : 0,
              background: props.linkableBackgroundColor
            }}
            onClick={() => props.onDelete(props.linkableData.id)}
          >
            <FontAwesomeIcon icon={"trash"} color="#ff6060" />
          </div>
        </div> */}
      </div>
    );
  }

  return (
    <div>
      <p style={{ color: props.textColor, fontFamily: props.font }}>
        {props.linkableData.title}
      </p>
    </div>
  );
};

export default GridLinkable;
