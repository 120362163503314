import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FitText from "@kennethormandy/react-fittext";
const Linkable = (props) => {
  if (props.edit) {
    return (
      <div
        className="linkable"
        style={{
          display: "flex",
          width: "80%",
          position: "relative",
          background: props.background,
          height: 60,
          alignItems: "center",
          borderWidth: 2,
          borderRadius: props.rounded ? 60 : 0,
          borderColor: props.borderColor,
          margin: "0 auto",
          marginBottom: 20
        }}
        onClick={() => props.onEdit(props.linkableData)}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FitText
            compressor={0.5}
            maxFontSize={30}
            style={{
              color: props.textColor,
              fontFamily: props.font,
              padding: 10
            }}
          >
            <p style={{ color: props.textColor, fontFamily: props.font }}>
              {!props.linkableData.visible && (
                <FontAwesomeIcon
                  icon={"eye-slash"}
                  color={props.textColor}
                  style={{ height: 15, marginRight: 20 }}
                />
              )}

              {props.linkableData.title}
            </p>
          </FitText>
          {/* <div
          className="linkable-settings"
        >
          <div className="linkable-edit-action-button" style={{ borderRadius: props.linkableRounded ? 40 : 0, background: props.linkableBackgroundColor }} onClick={() => props.onEdit(props.linkableData)}>
            <FontAwesomeIcon icon={"pencil-alt"} color={props.linkableTextColor} />
          </div>
          <div
            className="linkable-edit-action-button" style={{ borderRadius: props.linkableRounded ? 40 : 0, background: props.linkableBackgroundColor }}
            onClick={() => props.onDelete(props.linkableData.id)}
          >
            <FontAwesomeIcon
              icon={"trash"}
              color="#ff6060"
            />
          </div>
        </div> */}
          {/* <p style={{ color: props.textColor, fontFamily: props.font, fontSize: 12 }}>
          {props.linkableData.destination}
        </p> */}
        </div>
      </div>
    );
  }

  return (
    <div
      className="linkable"
      id="linkable-parent"
      style={{
        display: "flex",
        width: "90%",
        background: props.background,
        borderRadius: props.rounded ? 30 : 0
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 0,
          padding: 10
        }}
      >
        <FitText
          compressor={0.8}
          maxFontSize={36}
          style={{
            color: props.textColor,
            fontFamily: props.font,
            padding: 10
          }}
        >
          <p style={{color: props.textColor}}>{props.linkableData.title}</p>
        </FitText>
      </div>
    </div>
  );
};

export default Linkable;
