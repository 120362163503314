import React from 'react';


class Feature extends React.Component {
  render() {

  	return (
        <section className="theme-bg feature" id="features">
           <div className="container">
              <div className="animation-circle-inverse">
                 <i></i>
                 <i></i>
                 <i></i>
              </div>
              <div className="row">
                 <div className="col-md-12 text-center">
                    <div className="section-title">
                       <h1 className="text-white">FEATURES</h1>
                       {/* <img src={require("../styles/images/white-line.png")} alt="white-line" className="img-fluid"/> */}
                    </div>
                 </div>
                 <div className="col-lg-6 col-sm-6">
                    <div className="future-box">
                       <div className="future-timeline">
                          <ul >
                             {/* <li className="timeline">
                                <h4 className="sub-title">One Page</h4>
                                <p className="">for all of your links!</p>
                             </li> */}
                             <li className="timeline">
                                  <h4 className="sub-title">Completely Customizable 🎨</h4>
                                <p className="">Your links and backgrounds can be mixed up however you want!</p>
                             </li>
                             <li className="timeline">
                                <h4 className="sub-title">Branding</h4>
                                <p className="">Keep your branding consistent</p>
                             </li>
                             <li className="timeline">
                                  <h4 className="sub-title">Prime for Instagram 📸</h4>
                                <p className="">Perfect for your "link in bio"!</p>
                             </li>
                             <li className="timeline">
                                <h4 className="sub-title">Custom Branding URL</h4>
                                <p className="">Short, easy to read URLs. Example: lnks.by/jamie</p>
                             </li>
                             <li className="timeline">
                                  <h4 className="sub-title">Unique QR Code 🤳🏼</h4>
                                <p className="">Download a QR code that takes people to your linkstackr. Perfect for printing & marketing!</p>
                             </li>
                          </ul>
                       </div>
                    </div>
                 </div>
                 {/* <div className="col-md-4">
                    <img src="https://i.ibb.co/8bcFRcJ/Screen-Shot-2019-04-14-at-12-25-58-AM-iphonexspacegrey-portrait.png" alt="feature-mob" className="img-fluid"/>
                 </div> */}
                 <div className="col-lg-6 col-sm-6">
                    <div className="future-box">
                       <div className="future-timeline-right">
                          <ul className="text-left">
                             <li className="timeline-right">
                                  <h4>📊 Analytics</h4>
                                <p className="">See how many visitors come to your linkstackr</p>
                             </li>
                             <li className="timeline-right">
                                  <h4>📈 Link Tracking</h4>
                                <p className="">Track clicks on your links</p>
                             </li>
                             <li className="timeline-right">
                                  <h4>💼 Perfect for Portfolios </h4>
                                <p className="">Share your page with clients or future employers!</p>
                             </li>
                             <li className="timeline-right">
                                  <h4>🎛 Switch your Layout </h4>
                                <p className="">Have a "tiled" grid layout, or a list of links!</p>
                             </li>
                             <li className="timeline-right">
                                  <h4>🚀 Getting Sponsored? </h4>
                                  <p className="">Share your affiliate links and watch the clicks soar!</p>
                             </li>
                          </ul>
                       </div>
                    </div>
                 </div>
                   <h4 className="text-center">... and more!</h4>
              </div>
           </div>
        </section>
  	);
  }
}


export default Feature;