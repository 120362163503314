import React from "react";

class FeatureBlock extends React.Component {
  _textBlock = () => {
    return (
      <div className={this.props.side === "center" ? "col-md-12 text-center" : "col-md-6 text-center"}>
        <div className="section-title center">
          <h1
            className={this.props.gradient_bg ? "white" : "primary"}
            style={{ textAlign: "center" }}
          >
            {this.props.headerText}
          </h1>
        </div>
        <h3
          className={this.props.gradient_bg ? "white" : "black"}
          style={{ textAlign: "center" }}
        >
          {this.props.mainText === "Example: lnks.by/trev" ?
          <p>Example: <a href="https://lnks.by/trev">lnks.by/trev</a></p>
          : this.props.mainText}
        </h3>
        <p
          className={this.props.gradient_bg ? "white" : "black"}
          style={{
            textAlign: "center",
            paddingTop: 20,
            maxWidth: 450,
            margin: "0 auto"
          }}
        >
          {this.props.subText}
        </p>
      </div>
    );
  };

  _imageBlock = () => {
    return (
      <div className="col-md-6">
        {this.props.photoUrl === "realestate" ?
        <div className="feature-image-container">
        <img
          src={require('./../assets/images/realestate.png')}
          alt="feature_image"
          className="img-fluid"
        />
      </div> :
        this.props.photoUrl !== "" && this.props.photoUrl && (
          <div className="feature-image-container">
            <img
              src={this.props.photoUrl}
              alt="feature_image"
              className="img-fluid"
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <section className={this.props.gradient_bg ? "theme-bg" : ""}>
        <div className="about-chat">
          <div className="container ">
            <div className="row">
              {this.props.side === "left"
                ? this._textBlock()
                : this.props.side === "center"
                ? ""
                : this._imageBlock()}
              {this.props.side === "left"
                ? this._imageBlock()
                : this.props.side === "center"
                ? ""
                : this._textBlock()}
              {this.props.side === "center" && this._textBlock()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default FeatureBlock;
