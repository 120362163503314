import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import { updateUserInfo } from "./../actions/UpdateData";
import Filter from 'bad-words';
import {
  cancelPlan,
  enrollCustomer,
  refreshData,
  updateCustomerCard
} from "./../actions/Auth";

import { _forceURLprefix, _forceShortURLprefix } from "./../Utils/Helpers";
import { CardElement, injectStripe } from "react-stripe-elements";
import { inDev, reservedUrls } from "./../Utils/Constants";
import ReactGA from "react-ga";

class AccountEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: this.props.auth ? this.props.auth.data.name : "",
      shortCode: this.props.url ? this.props.url.replace("https://www.linkstackr.com/v/", "") : "",
      url: "https://www.linkstackr.com/v/".concat(this.props.url ? this.props.url : ""),
      shortUrl: "https://lnks.by/".concat(this.props.url ? this.props.url : "")
    };
  }

  _onURLChange = e => {
    this.setState({
      shortCode: e.target.value,
      url: "https://www.linkstackr.com/v/".concat(e.target.value),
      shortUrl: "https://lnks.by/".concat(e.target.value)
    });
  };

  _onNameChange = e => {
    this.setState({ value: e.target.value });
  };

  _onSubmit = async () => {
    if (this.props.type === "url") {
      this.setState({ isLoading: true });
      const filter = new Filter();
      let isProfane = filter.isProfane(this.state.url);
      let count = this.state.url.length;
      let valid = /^[a-z0-9]+$/i.test(this.state.url.split("/v/")[1]);
      let reserved = reservedUrls.includes(
        this.state.url.split("/v/")[1]
      );

      if(isProfane || count < 32 || !valid || reserved){
        window.alert("Sorry, that URL is not available. Try another!");
        return;
      }
      this.props
        .updateUserInfo(this.props.auth.data.id, {
          slug: this.state.url.replace("https://www.linkstackr.com/v/", "")
        })
        .then(res => {
          this.setState({ isLoading: false });
          this.props.closeModal();
        })
    } else if (this.props.type === "cancel plan") {
      this.setState({ isLoading: true });
      this.props.cancelPlan().then(() => {
        this.props.refreshData();
          ReactGA.event({
            category: "Sales",
            action: "Canceled Account"
          });
        this.setState({ isLoading: false });
        this.props.closeModal();
      });
    } else if (this.props.type === "reactivate") {
             this.setState({ isLoading: true });

             let { token } = await this.props.stripe.createToken({
               name: this.props.auth.data.name
             });

             if (typeof token === "undefined") {
               window.alert(
                 "Something was wrong with your card info. Try again."
               );
               return;
             }
             this.props
               .enrollCustomer(
                 token,
                 "pro",
                 null
               )
               .then(async res => {
                 this.props.refreshData();
                 this.setState({ isLoading: false });
                 this.props.closeModal();
               });
               ReactGA.event({
                 category: "Sales",
                 action: "Reactivated Account"
               });
           } else if (this.props.type === "card update") {
             this.setState({ isLoading: true });

             let { token } = await this.props.stripe.createToken({
               name: this.props.auth.data.name
             });

             if (typeof token === "undefined") {
               window.alert(
                 "Something was wrong with your card info. Try again."
               );
               return;
             }
             this.props
               .updateCustomerCard(
                 token
               )
               .then(async res => {
                 this.props.refreshData();
                 this.setState({ isLoading: false });
                 this.props.closeModal();
               });
           } else if (this.props.type === "name") {
             this.setState({ isLoading: true });
             this.props
               .updateUserInfo(this.props.auth.data.id, {
                 name: this.state.value
               })
               .then(res => {
                 this.setState({ isLoading: false });
                 this.props.closeModal();
               });
           }
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.closeModal} center>
        <div
          style={{ display: "flex", flexDirection: "column", padding: 10 }}
        >
          <h2 style={{ marginBottom: 10 }}>
            {this.props.type === "reactivate"
              ? "🙌 Reactivate Premium 🙌"
              : this.props.type === "card update"
              ? "Update Payment Info"
              : "Edit Account"}
          </h2>
          {this.props.type === "name" && (
            <div className="label-input-pair-center">
              <label>Name</label>
              <input
                className="large-input"
                style={{ marginBottom: 9 }}
                defaultValue={this.state.value}
                maxLength="55"
                onChange={e => this._onNameChange(e)}
              />
            </div>
          )}
          {this.props.type === "cancel plan" && (
            <div className="text-center">
              <h3 style={{ color: "black" }}>Cancel Subscription 🤔</h3>
              <p>
                Are you sure you want to cancel your subscription? Doing so
                will de-activate your linkstackr from public access at the
                end of this billing cycle. You will not be charged again.
              </p>
            </div>
          )}
          {this.props.type === "reactivate" && (
            <div className="text-center">
              {/* <h3 style={{ color: "black" }}>Welcome back!</h3> */}
              <div style={{ marginTop: 10 }}>
                <p>
                  For <span style={{ fontWeight: 900 }}>$9.99/month</span>,
                  regain premium benefits and access to your linkstackr. You
                  will be billed immediately and your benefits will start
                  now.
                </p>
              </div>
              <div className="main">
                <form className="auth-form" data-rewardful>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 10,
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      marginBottom: 20
                    }}
                  >
                    <CardElement
                      style={{
                        base: { fontSize: 24, padding: 20, lineHeight: 1.5 }
                      }}
                    />
                  </div>

                  <p style={{ fontSize: 12, color: "gray" }}>
                    All transactions are securely processed with Stripe.
                  </p>
                  <div className="form-group">
                    <Button
                      onClick={() => this._onSubmit()}
                      color="primary"
                      size={"lg"}
                      className="submit"
                      disabled={this.state.loading}
                      style={{ marginTop: 25 }}
                    >
                      Reactivate Premium
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {this.props.type === "card update" && (
            <div className="text-center">
              {/* <h3 style={{ color: "black" }}>Welcome back!</h3> */}
              <div style={{ marginTop: 10 }}>
                <p>
                  Enter your new card information here. Your old card will
                  be replaced with this one for all future payments.
                </p>
              </div>
              <div className="main">
                <form className="auth-form" data-rewardful>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 10,
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      marginBottom: 20
                    }}
                  >
                    <CardElement
                      style={{
                        base: { fontSize: 24, padding: 20, lineHeight: 1.5 }
                      }}
                    />
                  </div>

                  <p style={{ fontSize: 12, color: "gray" }}>
                    All transactions are securely processed with Stripe.
                  </p>
                  <div className="form-group">
                    <Button
                      onClick={() => this._onSubmit()}
                      color="primary"
                      size={"lg"}
                      className="submit"
                      disabled={this.state.loading}
                      style={{ marginTop: 25 }}
                    >
                      Update Card
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {this.props.type === "url" && (
            <div className="label-input-pair-center">
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  paddingBottom: 24
                }}
              >
                Please note that changing your URL requires updating your
                custom QR code. Existing QR codes will no longer send visitors to your
                linkstackr!
              </p>
              <label>Your Personal Link</label>
              <p style={{marginBottom: 15}}>
                {_forceShortURLprefix(
                  this.state.shortUrl,
                  this.state.shortUrl
                )}
              </p>
              <input
                className="reg-input"
                value={this.state.shortCode}
                onChange={e => this._onURLChange(e)}
              />
            </div>
          )}
          {this.props.type !== "reactivate" &&
            this.props.type !== "card update" && (
              <Button
                style={{

                  backgroundColor:
                    this.props.type === "cancel plan" ? "red" : "#2DBECF",
                    margin: "0 auto",
                    maxWidth: 300,
                    marginTop: 29,
                  }
                }
                color={
                  this.props.type === "cancel plan" ? "danger" : "primary"
                }
                onClick={() => this._onSubmit()}
              >
                {this.props.type === "cancel plan"
                  ? "Cancel My Account"
                  : "Submit"}
              </Button>
            )}

          <Button
            style={{
              margin: "0 auto",
              maxWidth: 300,
              marginTop: 29, }}
              onClick={() => this.props.closeModal()}
          >
            {this.props.type === "cancel plan" ||
            this.props.type === "reactivate" ||
            this.props.type === "card update"
              ? "Go Back"
              : "Discard Changes"}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateUserInfo,
      cancelPlan,
      enrollCustomer,
      updateCustomerCard,
      refreshData
    }
  )(injectStripe(AccountEditModal))
);
