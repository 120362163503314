import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class FeatureOverview extends React.Component {
  render() {
    return (
      <section className="theme-bg" id="features">
        <div className="container">
          <div className="col-md-12 text-center">
            <div className="section-title">
              <h1 className="text-white">FEATURES</h1>
              {/* <img
                src={require("../styles/images/white-line.png")}
                alt="white-line"
                className="img-fluid"
              /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"magic"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Live Editor</h5>

                <div className="review-message">
                  <p>
                    Preview and edit your page with our Live Editor. A quick,
                    simple way to customize your links and look to your own
                    liking.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"user"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Unique Branding</h5>
                <div className="review-message">
                  <p>
                    Keep your branding consistent with matching color schemes,
                    fonts, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"chart-line"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Analytics</h5>

                <div className="review-message">
                  <p>
                    View tracking information for your links and page.
                    Understand where your visitors are going.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"address-card"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Link in Bio</h5>

                <div className="review-message">
                  <p>
                    Primed and ready to be shared as your "link in bio" on
                    Instagram! Never worry about changing your link again!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"wifi"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Custom Short URL</h5>

                <div className="review-message">
                  <p>
                    Short, easy-to-read URLs that actually mean something.
                    Example: lnks.by/jamie
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"clock"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Schedule Links</h5>
                <div className="review-message">
                  <p>
                    Schedule when you want your links to appear and disappear!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"cubes"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Multiple Layout Options</h5>
                <div className="review-message">
                  <p>
                    Go with a clean list layout, or add images for a grid
                    experience! The choice is yours!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"qrcode"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Printable QR Code</h5>
                <div className="review-message">
                  <p>
                    Receive a QR code that will lead others to your page! Great
                    for printed material, advertisements, and more!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12" style={{padding:10}}>
              <div className="review-box homepage margin-bottom">
                <div className="rating-star">
                  <FontAwesomeIcon
                    icon={"money-bill-wave"}
                    color="#a2a2a2"
                    className="fa-2x"
                  />
                </div>
                <h5>Are you sponsored?</h5>
                <div className="review-message">
                  <p>
                    Share your affiliate links as you recommend products to
                    others, and watch your commissions blast off!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className="text-center">...and more!</h4>
        </div>
      </section>
    );
  }
}
