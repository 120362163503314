import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { apiBaseURL } from '../Utils/Constants';
import ReactChartkick, { LineChart, BarChart } from "react-chartkick";
import Chart from "chart.js";
import Highcharts from 'highcharts';
import { isMobile } from "react-device-detect";

ReactChartkick.addAdapter(Highcharts);
class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      analyticsPeriod: "7",
      analytics_data: this._getAnalyticsData("7"),
      analytics_total: 0,
      socialMediaPeriod: "7",
      social_analytics_data: this._getSocialAnalyticsData("7"),
      social_analytics_total: 0,
      visitsPeriod: "7",
      visits_data: this._getVisitData("7"),
      visits_total: 0,
      visitChart: "line",
      analyticsChart: "line",
      socialAnalyticsChart: "line"
    };
  }
  componentDidMount() {

  }

  _handleAnalyticsPeriodChange = (period) => {
    let chart = period === "1" ? "bar" : "line"
    this.setState({analyticsPeriod: period, analyticsChart: chart}, () => {
      this._getAnalyticsData(period);
    })
  }
  _handleVisitsPeriodChange = (period) => {
    let chart = period === "1" ? "bar" : "line"
    this.setState({visitsPeriod: period, visitChart: chart}, () => {
      this._getVisitData(period);
    })
  }
  _handleSocialMediaPeriodChange = (period) => {
    let chart = period === "1" ? "bar" : "line"
    this.setState(
      { socialMediaPeriod: period, socialAnalyticsChart: chart },
      () => {
        this._getSocialAnalyticsData(period);
      }
    );
  }

  _getAnalyticsData = (period) => {
    return axios({
      method: "get",
      url: `${apiBaseURL}/analytics/${period}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .catch(err => {
        this.setState({ analytics_data: [], loading: false })
      })
      .then(async res => {
        this.setState({ analytics_data: res.data.data, loading: false, analytics_total: res.data.total })
      });
  }

  _getSocialAnalyticsData = (period) => {
    return axios({
      method: "get",
      url: `${apiBaseURL}/social_analytics/${period}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .catch(err => {
        this.setState({ social_analytics_data: [], loading: false })
      })
      .then(async res => {
        this.setState({
          social_analytics_data: res.data.data,
          loading: false,
          social_analytics_total: res.data.total
        });
      });
  }

  _getVisitData = (period) => {
    return axios({
      method: "get",
      url: `${apiBaseURL}/visits/${period}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .catch(err => {
        this.setState({ visits_data: [], loading: false })
      })
      .then(async res => {
        this.setState({ visits_data: res.data.data, visits_total: res.data.total, loading: false })
      });
  }

  render() {
    document.body.classList.remove("landing-page");
    return (
      <div>
        <div>
          {/*breadcrumb start*/}
          <div className="breadcrumb-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 d-align-center">
                  <h2 className="title">
                    <Link to="/start/"><span style={{fontSize: 22, textTransform: "lowercase", color: "#c7c7c7", marginRight: 5}}> Dashboard </span></Link>
                    <span> Analytics </span>
                    {isMobile &&
                    <p>This page is best viewed on a desktop computer.</p>}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/*breadcrumb end*/}

          {/*Review Section Start*/}
          <section className="review-block" style={{padding: 0}}>
            {/* <div className="container" style={{marginBottom: 25}}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h3 className="analytics-header">Understand Your Audience</h3>
                  <p className="analytics-sub">Explore the graphs below to get a feel for which links are more popular than others. Using this data, you can tweak your links to ensure higher conversion rates!</p>
                </div>
              </div>
            </div> */}
            <div className="container">
              <div className="row" style={{marginTop: 45}}>
                <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom">
                  <div className="graph-box">
                    <h3 className="analytics-header">Total Traffic</h3>
                    <p className="analytics-sub">The number of visitors your linkstackr gets each day.</p>
                    <div className="review-message">

                      <div className="radio-filter">
                        <div
                          className={
                            this.state.visitsPeriod === "1"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleVisitsPeriodChange("1")}
                        >
                          Today
                        </div>
                        <div
                          className={
                            this.state.visitsPeriod === "7"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleVisitsPeriodChange("7")}
                        >
                          Week
                        </div>
                        <div
                          className={
                            this.state.visitsPeriod === "14"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleVisitsPeriodChange("14")}
                        >
                          2 Weeks
                        </div>
                        <div
                          className={
                            this.state.visitsPeriod === "31"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleVisitsPeriodChange("31")}
                        >
                          Month
                        </div>
                        <div
                          className={
                            this.state.visitsPeriod === "all"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleVisitsPeriodChange("all")}
                        >
                          All-time
                        </div>
                      </div>
                      <div className="number-summary">Total Traffic for this Period: <p className="number">{this.state.visits_total}</p></div>
                      {this.state.visitChart === "line" ? (
                        <LineChart
                          data={this.state.visits_data}
                          xtitle="Date"
                          ytitle="Visits"
                          messages={{ empty: "No data" }}
                        />
                      ) : (
                          <BarChart
                            data={this.state.visits_data}
                            label="Today"
                            ytitle="Visits"
                            messages={{ empty: "No data" }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
                            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom">
                  <div className="graph-box">
                    <div className="review-message">
                      <h3 className="analytics-header">Link Clicks</h3>
                      <p className="analytics-sub">The number of clicks your links get each day.</p>
                      <div className="radio-filter">
                        <div
                          className={
                            this.state.analyticsPeriod === "1"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleAnalyticsPeriodChange("1")}
                        >
                          Today
                        </div>
                        <div
                          className={
                            this.state.analyticsPeriod === "7"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleAnalyticsPeriodChange("7")}
                        >
                          Week
                        </div>
                        <div
                          className={
                            this.state.analyticsPeriod === "14"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleAnalyticsPeriodChange("14")}
                        >
                          2 Weeks
                        </div>
                        <div
                          className={
                            this.state.analyticsPeriod === "31"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleAnalyticsPeriodChange("31")}
                        >
                          Month
                        </div>
                        <div
                          className={
                            this.state.analyticsPeriod === "all"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleAnalyticsPeriodChange("all")}
                        >
                          All-time
                        </div>
                      </div>
                      <div className="number-summary">Total Clicks for this Period: <p className="number">{this.state.analytics_total}</p></div>
                      {this.state.analyticsChart === "line" ? (
                        <LineChart
                          data={this.state.analytics_data}
                          xtitle="Date"
                          ytitle="Link Clicks"
                          messages={{ empty: "No data" }}
                          legend="bottom"
                        />
                      ) : (
                        <BarChart
                          data={this.state.analytics_data}
                          label="Today"
                          ytitle="Link Clicks"
                          messages={{ empty: "No data" }}
                          legend="bottom"
                        />
                      )}
                    </div>
                  </div>
                </div>
            </div>
              <div className="row" style={{marginTop: 45}}>
                <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom">
                  <div className="graph-box">
                    <h3 className="analytics-header">Social Media Clicks</h3>
                    <p className="analytics-sub">The number of visitors who clicked on your social media links.</p>
                    <div className="review-message">

                      <div className="radio-filter">
                        <div
                          className={
                            this.state.socialMediaPeriod === "1"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleSocialMediaPeriodChange("1")}
                        >
                          Today
                        </div>
                        <div
                          className={
                            this.state.socialMediaPeriod === "7"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleSocialMediaPeriodChange("7")}
                        >
                          Week
                        </div>
                        <div
                          className={
                            this.state.socialMediaPeriod === "14"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleSocialMediaPeriodChange("14")}
                        >
                          2 Weeks
                        </div>
                        <div
                          className={
                            this.state.socialMediaPeriod === "31"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleSocialMediaPeriodChange("31")}
                        >
                          Month
                        </div>
                        <div
                          className={
                            this.state.socialMediaPeriod === "all"
                              ? "option selected"
                              : "option"
                          }
                          onClick={() => this._handleSocialMediaPeriodChange("all")}
                        >
                          All-time
                        </div>
                      </div>
                      <div className="number-summary">Total Social Media Link Clicks for this Period: <p className="number">{this.state.social_analytics_total}</p></div>
                      {this.state.socialAnalyticsChart === "line" ? (
                        <LineChart
                          data={this.state.social_analytics_data}
                          xtitle="Date"
                          ytitle="Link Clicks"
                          messages={{ empty: "No data" }}
                          legend="bottom"
                        />
                      ) : (
                        <BarChart
                          data={this.state.social_analytics_data}
                          label="Today"
                          ytitle="Link Clicks"
                          messages={{ empty: "No data" }}
                          legend="bottom"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="animation-circle absolute">
              <i />
              <i />
              <i />
            </div>
            <div className="animation-circle-inverse">
              <i />
              <i />
              <i />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Analytics)
);
