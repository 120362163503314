import { GETTING_COUPON, GETTING_COUPON_FAILURE, GETTING_COUPON_SUCCESS, CLEAR_COUPON } from './../Utils/ActionTypes';

const initialState = {
  isFetching: false,
  isValid: null,
  details: {},
  errorMessage: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETTING_COUPON:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });

    case GETTING_COUPON_SUCCESS:
      return Object.assign({}, state, {        
        isFetching: false,
        isValid: action.isValid,
        details: action.details,
        errorMessage: null
      });

    case GETTING_COUPON_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isValid: null,
        details: {},
        errorMessage: action.err
      });

    case CLEAR_COUPON:      
      return Object.assign({}, state, {
        isFetching: false,
        isValid: null,
        details: {},
        errorMessage: null
      });

    default:
      return state;
  }
}