import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom'
import { Button } from "reactstrap";

import "./../styles/home/index.scss";



class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <section className="auth-page">
        <div className="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="animation-circle">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="auth-card">
          <div className="text-center">
            <h2>Terms And Conditions</h2>
            {/* <img src={require("./../styles/images/title-line.png")} alt="title-line" className="img-fluid line" /> */}
            <p style={{marginTop: 25}}>View our terms and conditions by clicking the button below!</p>
          </div>
          <div className="main">
            <form className="auth-form">
              <div className="form-group">

                <Link to="https://app.termly.io/document/terms-of-use-for-saas/f1e624cb-cd71-4ed5-b1ff-b86e87e90b71">
                  <Button className="btn submit">Terms & Conditions</Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { })(TermsAndConditions))
