import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import FontPicker from "font-picker-react";
import React, { Component } from "react";
import { SwatchesPicker } from "react-color";
import { DebounceInput } from "react-debounce-input";
import { isMobile } from "react-device-detect";
import EdiText from "react-editext";
import LoadingOverlay from "react-loading-overlay";
import { Tab, TabList, TabPanel, Tabs } from "react-re-super-tabs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "react-responsive-modal";
import UrlReserve from "./UrlReserve";
import moment from 'moment';

// import {
//   arrayMove,
//   SortableContainer,
//   SortableElement
// } from "react-sortable-hoc";
import "react-tabs/style/react-tabs.scss";
import { Button } from "reactstrap";
import { refreshData } from "./../actions/Auth";
import {
  updateLayout,
  updateLinkable,
  updateUser,
  updateUserData
} from "./../actions/UpdateData";
import "./../styles/fonts.scss";
import "./../styles/home/index.scss";
import { apiBaseURL, googleFontsKey } from "./../Utils/Constants";
import CustomTab from "./CustomTab";
import CustomTabVip from "./CustomTabVip";
import GridLinkable from "./Linkable/GridLinkable";
import Linkable from "./Linkable/Linkable";
import LinkableModal from "./Linkable/LinkableModal";
import SocialLinkable from "./Linkable/SocialLinkable";
import SocialModal from "./SocialModal";
import Joyride from 'react-joyride';
import DragSortableList from 'react-drag-sortable'
import { colors } from "../Utils/BaseStyles";


const styles = () => ({
  helperClass: {
    zIndex: 20000
  }
});

const steps = [
  {
    target: '.allTabOptions',
    disableBeacon: true,
    placement: 'auto',
    // title: 'Changing layouts, styles & more',
    content: 'Over here, you can customize the look of your Linkstackr to match your style and brand! You can change your list of links to an image grid, change your background, fonts, and colors!',
  },
  {
    disableBeacon: true,
    target: '.addexternalmedialinks',
    placement: 'auto',
    // title: 'Add Links to Your Social Media',
    content: 'Add links to your Linkstackr here!',
  },
  {
    disableBeacon: true,
    target: '.addsocialmedialinks',
    placement: 'auto',
    // title: 'Add Links to Your Social Media',
    content: 'Help others connect with you on social media platforms by adding links here!',
  },
  {
    disableBeacon: true,
    target: '.titletextedit',
    placement: 'auto',
    // title: 'Add Links to Everywhere Else',
    content: 'Go ahead and click the pencil here to change your page title now!',
  },
  {
    disableBeacon: true,
    target: ".subtitletextedit",
    placement: 'auto',
    // title: 'Add Links to Everywhere Else',
    content: 'Do it again here. This can be a simple sentence or phrase explaining the links!',
  },
  {
    disableBeacon: true,
    target: "#avatarInput",
    placement: 'auto',
    // title: 'Add Links to Everywhere Else',
    content: 'Upload an image or avatar for your page here. You can do it now, or later!',
  },
  {
    disableBeacon: true,
    target: ".publish-btn",
    placement: 'auto',
    // title: 'Add Links to Everywhere Else',
    content: `When you're done, hit this button so you can start sharing your page with your audience! If you have questions, don't hesitate to chat with us!`,
  },
  {
    disableBeacon: true,
    target: ".walkthrough-btn",
    placement: 'auto',
    // title: 'Add Links to Everywhere Else',
    content: `To see this guide again, click here! Good luck!`,
  }
];

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editPanelOpen: true,
      pagePropsOpen: false,
      layoutPropsOpen: false,
      linkablePropsOpen: false,
      addLinkPropsOpen: false,

      editModalOpen: false,
      selectedLinkable: {},
      socialModalOpen: false,
      urlClaimModal: false,

      showUnsplashSearch: false,
      unsplashSearch: "",
      unsplashPhotos: [],
      pageTitle: props.auth.data.page_title,
      pageSubTitle: props.auth.data.page_subtitle,
      pageTextColor: props.auth.data.layout.text_color,
      otherTextColor: "",
      showBodyTextColorPicker: "",
      pageTitleFont: "",
      backgroundColor: "",
      backgroundType: "",
      imageBackgroundUrl: "",
      unsplashUser: "",
      unsplashUserLink: "",
      customImageBackgroundUrl: "",
      showBackgroundColorPicker: "",
      showBackgroundGradient1ColorPicker: "",
      showBackgroundGradient2ColorPicker: "",
      gradientOne: "",
      gradientTwo: "",
      showTitleColorPicker: "",
      showColorPicker: "",
      linkableBackgroundColor: "",
      showLinkableBackgroundColorPicker: "",
      linkables: [],
      linkableBorderColor: "",
      linkableTextColor: "",
      showLinkableTextColorPicker: "",
      linkableTextFont: "",
      otherTextFont: "",
      linkableRounded: "",
      header_center_aligned: true,

      newLinkableTitle: "",
      newLinkableDescription: "",
      newLinkableUrl: "",
      newLinkableFile: "",
      newLinkablePlatform: "",

      newBackgroundImage: "",
      newAvatarImage: "",

      avatarSource: null,

      layout: "list",

      highlightChecked: false,
      visible: true,
      runWelcomeTutorial: false,
      isVip: false,
      shiningAvatar: false,
      checkmarkAvatar: false,
      avatarPulseRing: false
    };
    this.props.refreshData();
  }

  componentDidMount = async () => {
    var seenWelcome = await localStorage.getItem("seenWalkthrough2");
    if (seenWelcome === null) {
      localStorage.setItem("seenWalkthrough2", "true");
      this.setState({ runWelcomeTutorial: true })
    }
    if (
      this.props.auth &&
      (this.props.auth.data.plan === "canceled")
    ) {
      this.props.history.replace({
        pathname: "/account",
        state: {
          plan_active: "no",
          page: "Editor"
        }
      });
    }
    // this.props.refreshData();
  }
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      // if (this.state.pageTitle !== nextProps.auth.data.page_title) {
      //   this.setState({pageTitle: nextProps.auth.data.page_title})
      // }
      if (!nextProps.auth) {
        return false;
      }
      this.setState({
        pageTitle: nextProps.auth.data.page_title,
        pageSubTitle: nextProps.auth.data.page_subtitle,
        pageTextColor: nextProps.auth.data.layout.text_color,
        otherTextColor: nextProps.auth.data.layout.other_text_color,
        pageTitleFont: nextProps.auth.data.layout.title_font,
        backgroundColor: nextProps.auth.data.layout.background_color,
        customImageBackgroundUrl: this.props.auth.data.background_image_url,
        imageBackgroundUrl: nextProps.auth.data.layout.background_image_url,
        unsplashUser: nextProps.auth.data.layout.unsplash_user,
        unsplashUserLink: nextProps.auth.data.layout.unsplash_user_link,
        backgroundType: nextProps.auth.data.layout.background_type,
        linkableBackgroundColor:
          nextProps.auth.data.layout.linkable_background_color,
        linkables: nextProps.auth.data.linkables,
        linkableBorderColor: nextProps.auth.data.layout.linkable_border_color,
        linkableTextColor: nextProps.auth.data.layout.linkable_text_color,
        linkableTextFont: nextProps.auth.data.layout.linkable_font,
        otherTextFont: nextProps.auth.data.layout.text_font,
        linkableRounded: nextProps.auth.data.layout.linkable_rounded,
        gradientOne: nextProps.auth.data.layout.gradient_one_color,
        gradientTwo: nextProps.auth.data.layout.gradient_two_color,
        header_center_aligned: nextProps.auth.data.layout.header_center_aligned,

        newLinkableTitle: "",
        newLinkableDescription: "",
        newLinkableUrl: "",
        newLinkableFile: "",

        avatarSource: nextProps.auth.data.avatar,
        layout: nextProps.auth.data.layout.design,

        shiningAvatar: nextProps.auth.data.layout.shining_avatar,
        checkmarkAvatar: nextProps.auth.data.layout.checkmark_avatar,
        avatarPulseRing: nextProps.auth.data.layout.avatar_pulse_ring,
        isVip: nextProps.auth.data.role === "vip"
      });
    }
  }

  onSortEnd = (sortedList) => {
    sortedList.sort(function (a, b) {
      return a.rank - b.rank;
    });
    var socialLinks = this.state.linkables.filter(function (el) {
      return el.link_type === "social";
    });
    var links = []

    sortedList.forEach((link) => {
      let data = link.content.props.children.props.linkableData;
      links.push(data);
    })
    links.push(socialLinks);
    var reorderedLinks = links.flat();

    this.setState(
      {
        linkables: reorderedLinks
      },
      () => {
        setTimeout(() => {
          this.updateUserData();
        }, 10);
      }
    );
  };

  _promptToSubscribe = () => {
    if (
      (this.props.location.state &&
        this.props.location.state.custom_slug !== null) ||
      this.props.custom_slug !== null
    ) {
      this.props.history.push("/publish");
    } else {
      this.setState({ urlClaimModal: true });
    }

  }

  updateUserData = async () => {
    this.props.updateUserData(this.state);
  };

  _setLinkableURL = url => {
    if (url.indexOf("http://") === -1 || url.indexOf("https://") === -1) {
      url = "https://" + url;
    }
    if (
      url.indexOf("https://http://") !== -1 ||
      url.indexOf("https://https://") !== -1
    ) {
      url = url.replace("https://", "");
    }
    this.setState({ newLinkableUrl: url });
  };

  _setPlatform = platform => {
    this.setState({ newLinkablePlatform: platform });
  };

  toggleCollapse = name => {
    switch (name) {
      case "pageProps":
        this.setState({
          pagePropsOpen: !this.state.pagePropsOpen,
          layoutPropsOpen: false,
          linkablePropsOpen: false,
          addLinkPropsOpen: false
        });
        break;
      case "layoutProps":
        this.setState({
          layoutPropsOpen: !this.state.layoutPropsOpen,
          pagePropsOpen: false,
          linkablePropsOpen: false,
          addLinkPropsOpen: false
        });
        break;
      case "linkableProps":
        this.setState({
          linkablePropsOpen: !this.state.linkablePropsOpen,
          pagePropsOpen: false,
          layoutPropsOpen: false,
          addLinkPropsOpen: false
        });
        break;
      case "addLinkProps":
        this.setState({
          addLinkPropsOpen: !this.state.addLinkProps,
          pagePropsOpen: false,
          layoutPropsOpen: false
        });
        break;

      default:
        break;
    }
  };
  handlePageBackgroundChangeComplete = color => {
    this.setState({ backgroundColor: color.hex }, () => {
      this.hitSave();
    });
  };
  handleGradientOneChangeComplete = color => {
    this.setState({ gradientOne: color.hex }, () => {
      this.hitSave();
    });
  };
  handleGradientTwoChangeComplete = color => {
    this.setState({ gradientTwo: color.hex }, () => {
      this.hitSave();
    });
  };
  handlePageTextColorChangeComplete = color => {
    this.setState({ pageTextColor: color.hex }, () => {
      this.hitSave();
    });
  };
  handleOtherTextColorChangeComplete = color => {
    this.setState({ otherTextColor: color.hex }, () => {
      this.hitSave();
    });
  };
  handleLinkableBackgroundChangeComplete = color => {
    this.setState({ linkableBackgroundColor: color.hex }, () => {
      this.hitSave();
    });
  };
  handleLinkableTextColorChangeComplete = color => {
    this.setState({ linkableTextColor: color.hex }, () => {
      this.hitSave();
    });
  };
  handlePageTitleChange = val => {
    this.setState({ pageTitle: val }, () => {
      this.hitSave();
    });
  };
  handlePageSubTitleChange = val => {
    this.setState({ pageSubTitle: val }, () => {
      this.hitSave();
    });
  };
  handleselectedFile = event => {
    const file = event.target.files[0];
    this.setState({
      newLinkableFile: file,
      layout: "image grid"
    });
  };

  handleselectedFileAvatar = event => {
    this.setState({ newAvatarImage: event.target.files[0] }, () => {
      this.updateUserInfo();
    });
  };

  handleselectedFileBackground = event => {
    this.setState({ newBackgroundImage: event.target.files[0] }, () => {
      this.hitSave();
    });
  };

  handleFontChange = (newFont, target) => {
    switch (target) {
      case "pageTitle":
        this.setState({ pageTitleFont: newFont }, () => {
          this.hitSave();
        });
        break;
      case "other":
        this.setState({ otherTextFont: newFont }, () => {
          this.hitSave();
        });
        break;
      case "linkableText":
        this.setState({ linkableTextFont: newFont }, () => {
          this.hitSave();
        });
        break;

      default:
        break;
    }
  };

  handleLinkLayoutChange = layout => {
    this.setState({ layout: layout }, () => {
      this.hitSave();
    });
  };
  handleHeaderAlignmentChange = aligned => {
    this.setState({ header_center_aligned: aligned }, () => {
      this.hitSave();
    });
  };

  handleBackgroundTypeChange = backgroundType => {
    this.setState({ backgroundType: backgroundType }, () => {
      this.hitSave();
    });
  };

  handlelinkableRoundedChange = shape => {
    this.setState({ linkableRounded: shape }, () => {
      this.hitSave();
    });
  };

  handleSelectedShiningAvatar = e => {
    var shiningAvatar = e.target.checked;
    this.setState({ shiningAvatar: shiningAvatar }, () => {
      this.hitSave();
    });
  };
  handleSelectedCheckmarkAvatar = e => {
    var checkmarkAvatar = e.target.checked;
    this.setState({ checkmarkAvatar: checkmarkAvatar }, () => {
      this.hitSave();
    });
  };
  handleSelectedAvatarPulseRing = e => {
    var avatarPulseRing = e.target.checked;
    this.setState({ avatarPulseRing: avatarPulseRing }, () => {
      this.hitSave();
    });
  };


  // renderLinkables = () => {
  //   let linkables = [];

  //   if (this.state.layout === "list") {
  //     this.state.linkables.map(linkable => {
  //       if (linkable.active) {
  //         linkables.push(
  //           <a
  //             key={linkable.id}
  //             // href={linkable.destination}
  //             style={{
  //               width: "100%",
  //               display: "flex",
  //               justifyContent: "center",
  //               marginTop: 30
  //             }}
  //             // onClick={() => {this._recordClick(linkable.id, linkable.destination)}}
  //           >
  //             <Linkable
  //             onEdit={(linkable) => this.editLinkable(linkable)}
  //             edit={true}
  //             onDelete={(linkable) => this.confirmLinkRemoval(linkable)}
  //               key={linkable.id}
  //               linkableData={linkable}
  //               background={this.state.linkableBackgroundColor}
  //               borderColor={this.state.linkableBorderColor}
  //               textColor={this.state.linkableTextColor}
  //               rounded={this.state.linkableRounded}
  //               font={this.state.linkableTextFont}
  //             />
  //           </a>
  //         );
  //       }
  //     });
  //   }
  //   else if(this.state.layout === "image grid") {
  //   this.state.linkables.map(linkable => {
  //     if (linkable.active) {
  //       linkables.push(
  //         <div
  //           className="grid-linkable"
  //           style={{ height: '15vw', backgroundImage: `url(${linkable.thumbnail})`}}
  //           key={linkable.id}
  //           // onClick={(linkable) => this.editLinkable(linkable)}
  //         >
  //           <GridLinkable
  //             edit={true}
  //             // onEdit={(linkable) => this.editLinkable(linkable)}
  //             key={linkable.id}
  //             onDelete={(linkable) => this.confirmLinkRemoval(linkable)}
  //             linkableData={linkable}
  //             background={this.state.linkableBackgroundColor}
  //             borderColor={this.state.linkableBorderColor}
  //             textColor={this.state.linkableTextColor}
  //             rounded={this.state.linkableRounded}
  //             font={this.state.linkableTextFont}
  //           />
  //         </div>
  //       );
  //     }
  //   });
  //   }

  //   return linkables;
  // };

  isMenuOpen = state => {
    this.setState({ editPanelOpen: state.isOpen });
  };

  unsplashSearch = query => {
    return axios({
      method: "get",
      url: `${apiBaseURL}/unsplash_search/${query}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .catch(() => { })
      .then(async res => {
        this.setState({ unsplashPhotos: res.data.results });
      });
  };

  unsplashSelect = (url, user) => {
    this.setState(
      {
        backgroundType: "image",
        imageBackgroundUrl: url,
        unsplashUser: user.first_name,
        unsplashUserLink: user.links.html
      },
      () => {
        this.updateUserData();
      }
    );
  };

  updateUserInfo = async () => {
    this.setState({ isLoading: true });
    this.props
      .updateUser(this.props.auth.data.id, this.state.newAvatarImage)
      .then(() => {
        this.setState({ isLoading: false });
      });
  };

  updateLayout = async () => {
    if (this.state.newBackgroundImage["size"] > 3000000) {
      alert(
        "That image is too large. Try something under 3MB! We use tinypng.com to compress our images."
      );
      return;
    }
    this.setState({ isLoading: true });
    this.props.updateLayout(this.state).then(() => {
      this.setState({ isLoading: false });
    });
  };
  renderPagePropsDropdown = () => {
    return (
      <div className="pagePropsContainer">
        <div className="edit-item">
          <p>Title & Subtitle Font</p>
          <FontPicker
            apiKey={googleFontsKey}
            options={{ sort: "popularity", limit: 15 }}
            activeFont={this.state.pageTitleFont}
            onChange={nextFont =>
              this.handleFontChange(nextFont.family, "pageTitle")
            }
          />
        </div>
        <div className="edit-item">
          {/* <OutsideClickHandler className="horizontal space-between" onClick={() => this.setState(
              {
                showTitleColorPicker: !this.state.showTitleColorPicker
              }
            )}> */}
          <p>Title & Subtitle Font Color</p>
          <div
            style={{
              padding: 5,
              background: "#fff",
              borderRadius: 1,
              width: 46,
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              position: "relative",
              cursor: "pointer"
            }}
          >
            <div
              style={{
                width: 36,
                height: 14,
                borderRadius: 2,
                background: this.state.pageTextColor
              }}
              onClick={() =>
                this.setState({
                  showTitleColorPicker: true
                })
              }
            />
            {this.state.showTitleColorPicker && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({
                    showTitleColorPicker: false
                  });
                }}
              >
                <SwatchesPicker

                  color={this.state.pageTextColor}
                  onChange={color =>
                    this.handlePageTextColorChangeComplete(color)
                  }
                />
              </OutsideClickHandler>
            )}
          </div>

          {/* <FontAwesomeIcon icon={this.state.showBackgroundColorPicker ? "caret-up" : "caret-down"} style={{ marginRight: 8 }} /> */}
        </div>

        <div className="edit-item">
          <p>Name Font</p>
          <FontPicker
            apiKey={googleFontsKey}
            options={{ sort: "popularity", limit: 15 }}
            activeFont={this.state.otherTextFont}
            onChange={nextFont =>
              this.handleFontChange(nextFont.family, "other")
            }
          />
        </div>
        <div className="edit-item">
          <p>Name Font Color</p>
          <div
            style={{
              padding: 5,
              background: "#fff",
              borderRadius: 1,
              width: 46,
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              position: "relative",
              cursor: "pointer"
            }}
          >
            <div
              style={{
                width: 36,
                height: 14,
                borderRadius: 2,
                background: this.state.otherTextColor
              }}
              onClick={() => {
                this.setState({
                  showBodyTextColorPicker: true
                });
              }}
            >
              {this.state.showBodyTextColorPicker && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({
                      showBodyTextColorPicker: false
                    });
                  }}
                >
                  <SwatchesPicker
                    disableAlpha={true}
                    color={this.state.otherTextColor}
                    onChange={color =>
                      this.handleOtherTextColorChangeComplete(color)
                    }
                  />
                </OutsideClickHandler>
              )}
            </div>
          </div>
        </div>

      </div>
    );
  };
  renderBackgroundPropsDropdown = () => {
    return (
      <div className="pagePropsContainer">
        <div className="edit-item">
          <p>Background Type</p>
          <div style={{ marginTop: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Color"
                checked={this.state.backgroundType === "solid"}
                onChange={() => this.handleBackgroundTypeChange("solid")}
              />
              Solid
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Color"
                checked={this.state.backgroundType === "gradient"}
                onChange={() => this.handleBackgroundTypeChange("gradient")}
              />
              Gradient
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Image"
                checked={this.state.backgroundType === "image"}
                onChange={() => this.handleBackgroundTypeChange("image")}
              />
              Image (Choose from thousands of Unsplash Professional Images)
            </label>
            {/* <label style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <input type="radio" style={{ marginRight: 10 }} value="Image" checked={this.state.backgroundType === "custom"} onChange={() => this.handleBackgroundTypeChange("custom")} />
            Upload Image (PRO)
              </label> */}
          </div>
        </div>
        {this.state.backgroundType === "solid" && (
          <div className="edit-item">
            <p>Page Background Color</p>
            <div
              style={{
                padding: 5,
                background: "#fff",
                borderRadius: 1,
                width: 46,
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                position: "relative",
                cursor: "pointer"
              }}
            >
              <div
                style={{
                  width: 36,
                  height: 14,
                  borderRadius: 2,
                  background: this.state.backgroundColor
                }}
                onClick={() =>
                  this.setState({
                    showBackgroundColorPicker: true
                  })
                }
              />
              {this.state.showBackgroundColorPicker && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({
                      showBackgroundColorPicker: false
                    });
                  }}
                >
                  <SwatchesPicker
                    disableAlpha={true}
                    color={this.state.backgroundColor}
                    onChange={color => {
                      this.handlePageBackgroundChangeComplete(color);
                    }}
                  />
                </OutsideClickHandler>
              )}
            </div>
          </div>
        )}

        {this.state.backgroundType === "gradient" && (
          <div>
            <div className="edit-item">
              <p>First Gradient Color</p>
              <div
                style={{
                  padding: 5,
                  background: "#fff",
                  borderRadius: 1,
                  width: 46,
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "inline-block",
                  position: "relative",
                  cursor: "pointer"
                }}
              >
                <div
                  onClick={() =>
                    this.setState({
                      showBackgroundGradient1ColorPicker: true
                    })
                  }
                  style={{
                    width: 36,
                    height: 14,
                    borderRadius: 2,
                    background: this.state.gradientOne
                  }}
                />
                {this.state.showBackgroundGradient1ColorPicker && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      this.setState({
                        showBackgroundGradient1ColorPicker: false
                      });
                    }}
                  >
                    <SwatchesPicker
                      disableAlpha={true}
                      color={this.state.gradientOne}
                      onChange={color =>
                        this.handleGradientOneChangeComplete(color)
                      }
                    />
                  </OutsideClickHandler>
                )}
              </div>
            </div>

            <div className="edit-item">
              <p>Second Gradient Color</p>
              <div
                style={{
                  padding: 5,
                  background: "#fff",
                  borderRadius: 1,
                  width: 46,
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "inline-block",
                  position: "relative",
                  cursor: "pointer"
                }}
                onClick={() =>
                  this.setState({
                    showBackgroundGradient2ColorPicker: true
                  })
                }
              >
                <div
                  style={{
                    width: 36,
                    height: 14,
                    borderRadius: 2,
                    background: this.state.gradientTwo
                  }}
                />
                {this.state.showBackgroundGradient2ColorPicker && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      this.setState({
                        showBackgroundGradient2ColorPicker: false
                      });
                    }}
                  >
                    <SwatchesPicker
                      disableAlpha={true}
                      color={this.state.gradientTwo}
                      onChange={color =>
                        this.handleGradientTwoChangeComplete(color)
                      }
                    />
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.backgroundType === "image" && (
          <div className="edit-item">
            <div
              className="horizontal space-between"
              onClick={() =>
                this.setState({
                  showUnsplashSearch: !this.state.showUnsplashSearch
                })
              }
            >
              <p>Background Image from Unsplash</p>
            </div>
            {/* <Collapse isOpened={this.state.showUnsplashSearch} springConfig={presets.wobbly}> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <DebounceInput
                minLength={4}
                debounceTimeout={600}
                style={{ marginBottom: 9 }}
                placeholder="Search anything (cats, dogs, whatever)"
                onChange={event => this.unsplashSearch(event.target.value)}
              />
              {/* <input style={{ marginBottom: 9 }} defaultValue={this.state.unsplashSearch} placeholder="Search anything (cats, dogs, whatever)" onChange={e => this.setState(
              { unsplashSearch: e.target.value }
            )} /> */}
              {/* <Button onClick={() => this.unsplashSearch()} color="primary">
              Search
                  </Button> */}
              <div className="unsplashContainer">
                {this.state.unsplashPhotos.length > 0 &&
                  this.renderUnsplashThumbnails()}
              </div>
            </div>
            {/* </Collapse> */}
          </div>
        )}
        <div className="edit-item">
          <p>Header Alignment</p>
          <div style={{ marginTop: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="List"
                checked={this.state.header_center_aligned === true}
                onChange={() => this.handleHeaderAlignmentChange(true)}
              />
              Centered
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Image Grid"
                checked={this.state.header_center_aligned === false}
                onChange={() => this.handleHeaderAlignmentChange(false)}
              />
              Left Aligned
            </label>
          </div>
        </div>
        {/* {this.state.backgroundType === "custom" && <div className="edit-item">
        <label>Upload Background Image</label>
        <p style={{ fontSize: 10 }}>Under 3MB recommended - otherwise your image will load slowly.</p>
        <input type="file" name="" id="" onChange={e => this.handleselectedFileBackground(e)} />

        <Button style={{ marginTop: 10, marginBottom: 10 }} onClick={() => this.updateLayout()}>
          Apply
              </Button>
      </div>} */}
      </div>
    );
  };

  renderUnsplashThumbnails = () => {
    let images = this.state.unsplashPhotos;
    let renderables = [];
    images.map((image, index) => {
      renderables.push(
        <div
          key={index}
          onClick={() =>
            this.unsplashSelect(
              image.attributes.table.urls.regular,
              image.attributes.table.user
            )
          }
        >
          <img
            className="unsplashPhoto"
            src={image.attributes.table.urls.thumb}
          />
        </div>
      );
    });
    if (renderables.length > 0) {
      return renderables;
    } else {
      return <div>No results. Try again.</div>;
    }
  };

  renderLayoutPropsDropdown = () => {
    return (
      <div className="pagePropsContainer">
        <div className="edit-item">
          <p>Link Layout</p>
          <div style={{ marginTop: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="List"
                checked={this.state.layout === "list"}
                onChange={() => this.handleLinkLayoutChange("list")}
              />
              List
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Image Grid"
                checked={this.state.layout === "image grid"}
                onChange={() => this.handleLinkLayoutChange("image grid")}
              />
              Image Grid
            </label>
          </div>
        </div>
        <div className="edit-item">
          <p>Link Background Color</p>
          <div
            style={{
              padding: 5,
              background: "#fff",
              borderRadius: 1,
              width: 46,
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              position: "relative",
              cursor: "pointer"
            }}
            onClick={() =>
              this.setState({
                showLinkableBackgroundColorPicker: true
              })
            }
          >
            <div
              style={{
                width: 36,
                height: 14,
                borderRadius: 2,
                background: this.state.linkableBackgroundColor
              }}
            />
            {this.state.showLinkableBackgroundColorPicker && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({
                    showLinkableBackgroundColorPicker: false
                  });
                }}
              >
                <SwatchesPicker
                  disableAlpha={true}
                  color={this.state.linkableBackgroundColor}
                  onChange={color =>
                    this.handleLinkableBackgroundChangeComplete(color)
                  }
                />
              </OutsideClickHandler>
            )}
          </div>
        </div>
        <div className="edit-item">
          <p>Link Text Color</p>
          <div
            style={{
              padding: 5,
              background: "#fff",
              borderRadius: 1,
              width: 46,
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              position: "relative",
              cursor: "pointer"
            }}
          >
            <div
              style={{
                width: 36,
                height: 14,
                borderRadius: 2,
                background: this.state.linkableTextColor
              }}
              onClick={() =>
                this.setState({
                  showLinkableTextColorPicker: true
                })
              }
            />
            {this.state.showLinkableTextColorPicker && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({
                    showLinkableTextColorPicker: false
                  });
                }}
              >
                <SwatchesPicker
                  disableAlpha={true}
                  color={this.state.linkableTextColor}
                  onChange={color =>
                    this.handleLinkableTextColorChangeComplete(color)
                  }
                />
              </OutsideClickHandler>
            )}
          </div>
        </div>
        <div className="edit-item">
          <p>Link Font</p>
          <FontPicker
            apiKey={googleFontsKey}
            options={{ sort: "popularity", limit: 15 }}
            activeFont={this.state.linkableTextFont}
            onChange={nextFont =>
              this.handleFontChange(nextFont.family, "linkableText")
            }
          />
        </div>
        <div className="edit-item">
          <p>Link Shape</p>
          <div style={{ marginTop: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Color"
                checked={
                  this.state.linkableRounded === false ||
                  this.state.linkableRounded === null
                }
                onChange={() => this.handlelinkableRoundedChange(false)}
              />
              Squared
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="radio"
                style={{ marginRight: 10 }}
                value="Color"
                checked={this.state.linkableRounded === true}
                onChange={() => this.handlelinkableRoundedChange(true)}
              />
              Rounded
            </label>
          </div>
        </div>
      </div>
    );
  };

  renderVipPropsDropdown = () => {
    return (
      <div className="pagePropsContainer">
        <div className="edit-item">
          <p>VIP Features</p>
          <div style={{ marginTop: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 10 }}
                checked={this.state.shiningAvatar}
                onChange={(e) => this.handleSelectedShiningAvatar(e)}
              />
              Shining Profile Picture
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 10 }}
                checked={this.state.checkmarkAvatar}
                onChange={(e) => this.handleSelectedCheckmarkAvatar(e)}
              />
              VIP Checkmark
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 10 }}
                checked={this.state.avatarPulseRing}
                onChange={(e) => this.handleSelectedAvatarPulseRing(e)}
              />
              Pulsating Ring Border for Profile Picture
            </label>
            <strong>Custom background image? Email it to <a href="mailto:linkstackr@gmail.com">linkstackr@gmail.com</a>, and we'll upload it for you!</strong>
          </div>
        </div>
      </div>
    );
  };

  updateLinkableTitle = (title, id) => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    if(linkable){
      linkable.title = title;
      this.setState({ linkables, newLinkableTitle: title });
    } else {
      this.setState({ newLinkableTitle: title });
    }
  };

  _setHighlighted = id => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    if (linkable) {
      linkable.highlight = !linkable.highlight;
      this.setState({ linkables, highlightChecked: linkable.highlight});
    } else {
      this.setState({ highlightChecked: !this.state.highlightChecked });
    }
  };

  _setVisibility = id => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    this.setState({ linkables, visible: linkable ? !linkable.visible : true });
    if (linkable) {
      linkable.visible = !linkable.visible;
    }
  };

  _setStartDate = (id, date) => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    var momentdate = moment(date).utc();
    if(linkable){
      linkable.start_date = date;
      this.setState({ linkables, newLinkableStartDate: momentdate });
    } else {
      this.setState({ newLinkableStartDate: momentdate });
    }
  };
  _setEndDate = (id, date) => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    var momentdate = moment(date).utc();
    if(linkable){
      linkable.end_date = date;
      this.setState({ linkables, end_date: momentdate });
    } else {
      this.setState({ end_date: momentdate });
    }
  };

  updateLinkableDestination = (text, id) => {
    let linkables = this.state.linkables;
    let linkable = linkables.find(e => e.id === id);
    if(linkable){
      linkable.destination = text;
      this.setState({ linkables, newLinkableUrl: text });
    } else {
      this.setState({ newLinkableUrl: text });
    }
  };

  createNewLink = async () => {
    const id = this.state.selectedLinkable["id"];
    this.setState({ isLoading: true });
    this.props
      .updateLinkable(
        this.state.newLinkableUrl,
        this.state.newLinkableTitle,
        this.state.newLinkableDescription,
        this.state.newLinkableFile,
        id,
        true,
        this.state.highlightChecked,
        this.state.newLinkablePlatform,
        this.state.visible,
        this.state.newLinkableStartDate,
        this.state.end_date
      )
      .then(() => {
        this.setState({
          isLoading: false,
          editModalOpen: false,
          socialModalOpen: false,
          selectedLinkable: {},
          newLinkablePlatform: ""
        });
      });
  };

  removeLink = async id => {
    var linkable = this.state.linkables.find(el => el.id === id);
    this.props.updateLinkable(
      linkable.destination,
      linkable.title,
      linkable.description,
      linkable.thumbnail,
      id,
      false,
      linkable.highlight,
      linkable.platform,
      this.state.visible
    );
  };

  confirmLinkRemoval = id => {
    if (window.confirm("Are you sure you want to remove this link?")) {
      this.removeLink(id);
      this.setState({ editModalOpen: false, socialModalOpen: false });
    }
  };

  editLinkable = linkable => {
    if (linkable.link_type === "link") {
      this.setState({
        selectedLinkable: linkable,
        start_date: linkable.start_date,
        end_date: linkable.end_date,
      }, () => {
        this.setState({ editModalOpen: true });
      });
    } else {
      this.setState({ selectedLinkable: linkable }, () => {
        this.setState({ socialModalOpen: true });
      });
    }
  };

  renderEditableLinkables = () => {
    let activeLinkables = [];
    if (this.state.linkables === [])
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column"
          }}
        >
          {/* <div
            className="addLink"
            style={{ borderRadius: this.state.linkableRounded ? 50 : 0 }}
            onClick={() =>
              this.setState({ editModalOpen: true, selectedLinkable: {} })
            }
          >
            + Add Link
          </div> */}
        </div>
      );
    this.state.linkables.map(linkable => {
      if (linkable.active && linkable.link_type === "link") {
        activeLinkables.push(linkable);
      }

    });
    if (this.state.layout === "image grid") {
      var activeLinks = [];
      activeLinkables.forEach((link) => {
        activeLinks.push({
          content: (
            <a
              style={{ backgroundImage: `url(${link.thumbnail})` }}
              className="grid-linkable in-edit"
              onClick={() => this.editLinkable(link)}
            >
              <GridLinkable
                key={link.id}
                linkableData={link}
                background={this.state.linkableBackgroundColor}
                borderColor={this.state.linkableBorderColor}
                textColor={this.state.linkableTextColor}
                rounded={this.state.linkableRounded}
                font={this.state.linkableTextFont}
                onDelete={() => this.removeLink(link.id)}
              />
            </a>)
        })
      })
    }
    if (this.state.layout === "list") {
      var activeLinks = [];
      activeLinkables.forEach((link) => {
        activeLinks.push({
          content: (<div className="linkView">
            <Linkable
              edit={true}
              key={link.id}
              linkableData={link}
              // handleTitleChange={handleTitleChange}
              background={this.state.linkableBackgroundColor}
              borderColor={this.state.linkableBorderColor}
              textColor={this.state.linkableTextColor}
              rounded={this.state.linkableRounded}
              font={this.state.linkableTextFont}
              onDelete={() => this.removeLink(link.id)}
              onEdit={() => this.editLinkable(link)}
            />
          </div>)
        })
      })
    }
    return (
      <div className={this.state.layout === "image grid" ? "flex-container listcontainerparent" : "listcontainervertical"} style={{ padding: 0, paddingBottom: 20 }}>
        <DragSortableList
          items={activeLinks}
          onSort={(list) => this.onSortEnd(list)}
          dropBackTransitionDuration={0.3}
          type={this.state.layout === "list" ? "vertical" : "grid"}
        />
        {/*
        <div
          className="addLink"
          style={{ borderRadius: this.state.linkableRounded ? 50 : 0 }}
          onClick={() =>
            this.setState({ editModalOpen: true, selectedLinkable: {} })
          }
        >
          + Add Link
        </div> */}
      </div>
    );
  };

  renderSocialMediaLinks = () => {
    let activeLinkables = [];

    this.state.linkables.map(linkable => {
      if (linkable.active && linkable.link_type === "social") {
        activeLinkables.push(linkable);
      }
    });
    return (
      <div
        className="flex-container"
        style={{
          padding: 0,
          paddingBottom: 20,
          width: 220,
          justifyContent: "space-evenly"
        }}
      >
        {activeLinkables.map(linkable => {
          return (
            <SocialLinkable
              key={linkable.id}
              edit={true}
              linkableData={linkable}
              background={this.state.linkableBackgroundColor}
              borderColor={this.state.linkableBorderColor}
              textColor={this.state.linkableTextColor}
              rounded={this.state.linkableRounded}
              font={this.state.linkableTextFont}
              // onDelete={this.confirmLinkRemoval()}
              onEdit={linkable => this.editLinkable(linkable)}
            />
          );
        })}
      </div>
    );
  };

  renderLinkablePropsDropdown = () => {
    return (
      <div className="linkablePropsContainer">
        {/* <hr className="hr-text" onClick={() => this.toggleCollapse("linkableProps")} data-content="My Links" />
        <Collapse isOpened={this.state.linkablePropsOpen} springConfig={presets.wobbly}> */}
        {this.renderEditableLinkables()}
        {/* </Collapse> */}
      </div>
    );
  };

  renderTabLists = () => {
    if (this.state.isVip) {
      return (
        <TabList>
          <Tab component={CustomTab} label="Page Styles" id="bg" />
          <Tab component={CustomTab} label="Link Styles" id="link" />
          <Tab component={CustomTab} label="Text Styles" id="page" />
          <Tab component={CustomTabVip} label="Exclusive VIP Styles & Settings" id="vip" />
        </TabList>
      )

    } else {
      return (
        <TabList>
        <Tab component={CustomTab} label="Page Styles" id="bg" />
        <Tab component={CustomTab} label="Link Styles" id="link" />
        <Tab component={CustomTab} label="Text Styles" id="page" />
      </TabList>
      )
    }
  }

  hitSave = async () => {
    // this.setState({isLoading: true})
    this.updateUserData();
    // setTimeout(() => {
    //   this.setState({isLoading: false})
    // }, 600);
  };

  render() {
    document.body.classList.remove("inner-page");
    const loadingText = [
      "Loading. Don't go anywhere :)",
      "Hamsters are churning the wheel...",
      "Preparing rocket launch...",
      "Updating. This could take a second.",
      "Hold your horses! Almost there.",
      "We'll be back in 3, 2, 1...",
      "Applying your changes. Can't wait to see them!",
      "This is gonna be awesome!"
    ];
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text={loadingText[Math.floor(Math.random() * loadingText.length)]}
          className="loading-overlay"
        >
          <div id="full-content">
            <div className="half-width-content">
              <div className="body-content" style={{ minHeight: "100vh" }}>
                {isMobile && (
                  <p
                    style={{
                      textAlign: "center",
                      paddingBottom: 15,
                      color: "red"
                    }}
                  >
                    This page is best viewed on a desktop computer.
                  </p>
                )}
                <h2 style={{ margin: 0, marginBottom: 10 }}>Customizations</h2>

                <p className="margin-bottom">
                  Changes are saved automatically.
                </p>
                <div className="editPanelAction">
                  <Button
                    className="btn-primary"
                    color="warning"
                    onClick={() => {
                      if (this.props.location.state && this.props.location.state.custom_slug !== null) {
                        this.props.history.push({
                          pathname: '/preview',
                          state: { custom_slug: this.props.location.state.custom_slug }
                        })
                      } else {
                        this.props.history.push({
                          pathname: '/preview',
                        })
                      }
                    }
                    }
                  >
                    Preview
                  </Button>
                </div>

                {/* <p>Please remember to save your changes by clicking the "Save" button below. Preview your changes by clicking the "Preview" button.</p>
                <div className="icon-actions">
                  <Button className="action-option horizontal show-cursor" color="warning" onClick={() => this.hitSave()}>
                    <FontAwesomeIcon icon="check" style={{ marginRight: 8 }} />
                    <p>Save</p>
                  </Button>
                  <Button className="action-option horizontal show-cursor" color="info" onClick={() => {
                    this.setState({ editPanelOpen: false }, () => this.isMenuOpen)
                  }}>
                    <FontAwesomeIcon icon="eye" style={{ marginRight: 8 }} />
                    <p>Preview</p>
                  </Button>

                </div> */}
                <div className="add-links-row">
                  <Button
                    style={{ marginBottom: 20 }}
                    className="btn-primary addexternalmedialinks"
                    color="primary"
                    onClick={() =>
                      this.setState({ editModalOpen: true, selectedLinkable: {} })
                    }
                  >
                    Add External Link
                </Button>
                  <Button
                    style={{ marginBottom: 20 }}
                    className="btn-primary addsocialmedialinks"
                    color="primary"
                    onClick={() => this.setState({ socialModalOpen: true })}
                  >
                    Add Social Media Link
                </Button>
                </div>

                <div className="allTabOptions">
                  <Tabs activeTab="bg">
                    {this.renderTabLists()}
                    <TabList>
                      <TabPanel
                        component={() => this.renderPagePropsDropdown()}
                        id="page"
                      />
                      <TabPanel
                        component={() => this.renderLayoutPropsDropdown()}
                        id="link"
                      />
                      <TabPanel
                        component={() => this.renderBackgroundPropsDropdown()}
                        id="bg"
                      />
                      <TabPanel
                        component={() => this.renderVipPropsDropdown()}
                        id="vip"
                      />
                      {/* <TabPanel component={() => this.renderEditableLinkables()} id='links' /> */}
                    </TabList>
                  </Tabs>
                </div>
                {this.props.auth.data.plan === "canceled" || this.props.auth.data.plan === "Free" &&
                  <Button className="show-cursor publish-btn" color="warning" onClick={() => this._promptToSubscribe()}>
                    <p>Publish</p>
                    <FontAwesomeIcon icon="check" style={{ marginLeft: 8, marginTop: 4 }} />

                  </Button>
                }
                <Button className="show-cursor btn-primary walkthrough-btn" style={{ color: "white" }} color="primary" onClick={() => this.setState({ runWelcomeTutorial: true })}>
                  <p style={{ color: "white" }}>View Walkthrough</p>
                  <FontAwesomeIcon icon="book-open" style={{ marginLeft: 8, marginTop: 4 }} />

                </Button>



                {/* <Tabs>
                  <TabList>
                    <Tab>Page Style</Tab>
                    <Tab>Link Style</Tab>
                  </TabList>
                  <TabPanel>
                    {this.renderPagePropsDropdown()}
                  </TabPanel>
                  <TabPanel>
                    {this.renderLayoutPropsDropdown()}
                  </TabPanel>
                </Tabs> */}
              </div>
            </div>
            <div className="half-width-content">
              <div
                id="background"
                style={{
                  zIndex: -10,
                  filter: `blur(0px)`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: "100%",
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  objectFit: "contain",
                  backgroundPosition: "center",
                  background:
                    this.state.backgroundType === "solid"
                      ? this.state.backgroundColor
                      : this.state.backgroundType === "image"
                        ? `url(${
                        this.state.imageBackgroundUrl
                        }) center center/cover`
                        : this.state.backgroundType === "custom"
                          ? `url(${
                          this.state.customImageBackgroundUrl
                          }) center center/cover`
                          : this.state.backgroundType === "gradient"
                            ? `linear-gradient(to bottom right, ${
                            this.state.gradientOne
                            }, ${this.state.gradientTwo})`
                            : ""
                }}
              />
              <div className="preview-content">
                {/* <div className="editPanelAction">
                  <Button className="editBtn btn-primary" onClick={() => this.setState({ editPanelOpen: !this.state.editPanelOpen })}>Customize</Button>
                </div> */}
                <div
                  className={
                    this.state.header_center_aligned
                      ? "centered-heading"
                      : "horizontal-heading"
                  }
                >
                  <div className="header-image-and-name">
                    <input
                      type="file"
                      name=""
                      id="avatarInput"
                      data-content="Update"
                      className="avatar-image"
                      onChange={e => this.handleselectedFileAvatar(e)}
                      style={{
                        marginTop: this.state.header_center_aligned ? 0 : 0
                      }}
                    />
                    <img
                      className="avatarRound"
                      src={this.state.avatarSource}
                    />
                    {this.state.avatarPulseRing && (
                      <div className="pulse-container" style={{ boxShadow: '0 0 15px #987ec6' }}>
                        <span></span>
                      </div>
                    )}
                    <p
                      style={{
                        fontFamily: this.state.otherTextFont,
                        color: this.state.otherTextColor,
                        marginTop: this.state.avatarPulseRing || this.state.shining_avatar ? 30 : "initial"
                      }}
                    >
                      {this.props.auth.data.name}
                      {this.state.checkmarkAvatar && (
                        <FontAwesomeIcon icon="check-circle" style={{ marginLeft: 2, marginTop: 4, color: this.state.otherTextColor }} />
                      )}
                    </p>
                  </div>

                  <div className="header-title-and-subtitle">
                    <h2
                      className="titletextedit"
                      style={{
                        width: "100%",
                        marginTop: 60,
                        color: this.state.pageTextColor,
                        fontFamily: this.state.pageTitleFont
                      }}
                    >
                      <EdiText
                        type="text"
                        editOnViewClick={true}
                        value={this.state.pageTitle}
                        onSave={this.handlePageTitleChange}
                        editButtonClassName="editable-button"
                        cancelButtonClassName="editable-button pad"
                        saveButtonClassName="editable-button pad"
                        saveButtonContent={
                          <FontAwesomeIcon
                            icon={"check"}
                            color="green"
                            style={{ height: 35 }}
                          />
                        }
                        cancelButtonContent={
                          <FontAwesomeIcon
                            icon={"times"}
                            color="red"
                            style={{ height: 35 }}
                          />
                        }
                        editButtonContent={
                          <FontAwesomeIcon
                            className="editTitleIcon"
                            icon={"pencil-alt"}
                            color={this.state.pageTextColor}
                            style={{ height: 25 }}
                          />
                        }
                      />
                    </h2>
                    <h5
                      className="subtitletextedit"
                      style={{
                        paddingTop: 10,
                        width: "100%",
                        color: this.state.pageTextColor,
                        fontFamily: this.state.pageTitleFont
                      }}
                    >
                      <EdiText
                        type="textarea"
                        editOnViewClick={true}
                        value={this.state.pageSubTitle}
                        onSave={this.handlePageSubTitleChange}
                        editButtonClassName="editable-button"
                        cancelButtonClassName="editable-button pad"
                        saveButtonClassName="editable-button pad"
                        saveButtonContent={
                          <FontAwesomeIcon
                            icon={"check"}
                            color="green"
                            style={{ height: 35 }}
                          />
                        }
                        cancelButtonContent={
                          <FontAwesomeIcon
                            icon={"times"}
                            color="red"
                            style={{ height: 35 }}
                          />
                        }
                        editButtonContent={
                          <FontAwesomeIcon
                            className="editSubTitleIcon"
                            icon={"pencil-alt"}
                            color={this.state.pageTextColor}
                            style={{ height: 25 }}
                          />
                        }
                      />
                    </h5>
                  </div>
                </div>

                {this.state.layout === null ||
                  this.state.layout === "" ||
                  (this.state.layout === "list" && (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%"
                      }}
                    >
                      {this.state.linkables && this.renderEditableLinkables()}
                      {this.state.linkables.length === 0 &&
                        <div>Add a link, and they will be displayed here</div>
                      }
                    </div>
                  ))}

                {this.state.layout !== null &&
                  this.state.layout === "image grid" &&
                  this.state.linkables &&
                  this.renderEditableLinkables()}

                {/* {this.state.backgroundType === "image" && this.state.unsplashUser !== '' &&
                  <div className="unsplash-attribution">
                    <p><FontAwesomeIcon
                      icon={"camera-retro"}
                      color="#a2a2a2"
                      style={{ marginRight: 8 }}
                    />{`${this.state.unsplashUser} @ `}<a href={this.state.unsplashUserLink}>Unsplash</a></p>
                  </div>
                } */}
                {this.renderSocialMediaLinks()}
              </div>
            </div>

            <LinkableModal
              newTitle={this.state.newLinkableTitle}
              updateTitle={(title, id) => this.updateLinkableTitle(title, id)}
              newDescription={this.state.newLinkableDescription}
              updateDescription={description =>
                this.setState({ newLinkableDescription: description })
              }
              newUrl={this.state.newLinkableUrl}
              newStartDate={this.state.newLinkableStartDate}
              updateUrl={url => this._setLinkableURL(url)}
              updateHighlight={id => this._setHighlighted(id)}
              setStartDate={(id, date) => this._setStartDate(id, date)}
              setEndDate={(id, date) => this._setEndDate(id, date)}
              updateVisible={id => this._setVisibility(id)}
              deleteLink={id => this.confirmLinkRemoval(id)}
              open={this.state.editModalOpen}
              linkable={this.state.selectedLinkable}
              newLinkable={() => this.createNewLink()}
              handleselectedFile={e => this.handleselectedFile(e)}
              closeModal={() =>
                this.setState({
                  editModalOpen: false,
                  selectedLinkable: {}
                })
              }
            />
            <SocialModal
              newUrl={this.state.newLinkableUrl}
              updateUrl={url => this._setLinkableURL(url)}
              updatePlatform={platform => this._setPlatform(platform)}
              updateHighlight={id => this._setHighlighted(id)}
              deleteLink={id => this.confirmLinkRemoval(id)}
              open={this.state.socialModalOpen}
              linkable={this.state.selectedLinkable}
              platform={this.state.newLinkablePlatform}
              newLinkable={() => this.createNewLink()}
              closeModal={() =>
                this.setState({
                  socialModalOpen: false,
                  selectedLinkable: {}
                })
              }
            />
            <Modal open={this.state.urlClaimModal} onClose={() => this.setState({ urlClaimModal: false })} center>
              <UrlReserve />
            </Modal>
            <Joyride
              disableOverlayClose={true}
              floaterProps={{ disableAnimation: true }}
              showProgress={true}
              spotlightClicks={true}
              showProgress={true}
              showSkipButton={true}
              run={this.state.runWelcomeTutorial}
              styles={{
                options: {
                  arrowColor: colors.white,
                  backgroundColor: "white",
                  primaryColor: '#000',
                  textColor: '#000000',
                  zIndex: 1000,
                }
              }}
              spotlightPadding={20}
              locale={{
                close: "Next"
              }}
              steps={steps}
            />
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  custom_slug: state.auth.data.slug
});

export default withRouter(
  connect(
    mapStateToProps,
    { updateUserData, updateLinkable, updateUser, updateLayout, refreshData }
  )(Edit)
);
