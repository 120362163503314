import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledCollapse, Card, CardBody, CardHeader, Button } from "reactstrap";
class FAQ extends React.Component {
    constructor(props) {
      super(props);
    }

  render() {
   const white = this.props.color === "white";
  	return (
        <section className={white ? "faq" : "theme-bg faq"} id="faq">
           <div className="container">
              <div className="row">
                 <div className="col-md-12 text-center">
                    <div className="section-title">
                       <h2 className={white ? "faq" : "white"}>FAQs</h2>
                       {/* <img src={require("./../styles/images/title-line.png")} alt="title-line" className="img-fluid" /> */}
                    </div>
                 </div>
              </div>
              <div className="row">
                  <div className="col-lg-12 text-center">
                     {/* <h4 className={white ? "faq-link primary" : "faq-link white"} id="toggler1">
                        Is there a limit to the number of links I can create in my linkstackr?
                     </h4>
                    <UncontrolledCollapse toggler="#toggler1">
                       <Card className="card mb-3">
                           <CardBody className="card-body">
                              Paid accounts have no limit, while free account can create up to three (3) links.
                           </CardBody>
                       </Card>
                     </UncontrolledCollapse> */}
                     <h4 className={white ? "faq-link primary" : "faq-link white"} id="toggler2">
                        Do you offer a free trial period?
                     </h4>
                    <UncontrolledCollapse toggler="#toggler2">
                       <Card className="card mb-3">
                           <CardBody className="card-body">
                              <p>All accounts have a 15-day free trial period. We also offer a <span style={{textDecoration: "underline"}}>30-day money back guarantee</span> if for some reason you aren't satisfied with linkstackr (though we're confident you'll love it!)</p>
                           </CardBody>
                       </Card>
                     </UncontrolledCollapse>
                     {/* <h4 className={white ? "faq-link primary" : "faq-link white"} id="toggler3">
                        Can I get paid if I share linkstackr with my followers?
                     </h4>
                    <UncontrolledCollapse toggler="#toggler3">
                       <Card className="card mb-3">
                           <CardBody className="card-body">
                              <p>We love to reward our users for spreading the word! :) Contact us if you're interested in being an affiliate! We pay RECURRING commissions, so each month a user pays, you get paid!</p>
                           </CardBody>
                       </Card>
                     </UncontrolledCollapse> */}
                 </div>
              </div>
           </div>
        </section>
  	);
  }
}


export default FAQ;