import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Elements, StripeProvider } from "react-stripe-elements";
import "react-toastify/dist/ReactToastify.min.css";
import Main from './components/Main';
import "./styles/base.scss";
import "./styles/css/animate.min.css";
import "./styles/custom/_style.scss";
import "./styles/fonts.scss";
import "./styles/home/index.scss";
import "./styles/style.scss";
import { inDev, stripePublishableKey } from './Utils/Constants';
import ReactPixel from 'react-facebook-pixel';
 

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('572352783339288', null, options);


Sentry.init({
  dsn: "https://a19af9c2d8344498a441647ae4b343ef@sentry.io/1462829",
  environment: process.env.NODE_ENV
});

class App extends Component {
  render() {
    return (
      <StripeProvider apiKey={stripePublishableKey}>
        <Elements>
          <Main />
        </Elements>
      </StripeProvider>
    );
  }
}
export default withRouter(connect()(App));