import { PropTypes } from "prop-types";
import React from "react";
import ReactGA from "react-ga";
import { GAKey, inDev } from "../Utils/Constants";
import ReactPixel from 'react-facebook-pixel';
ReactGA.initialize(GAKey);

export default class GoogleAnalytics extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    // if(!inDev){
      this.sendPageView(this.context.router.history.location);      	
      this.context.router.history.listen(this.sendPageView);
    // }
    
  }

  sendPageView(location) {
    // if(!inDev){
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      ReactPixel.pageView(location.pathname); 			
    // }
    
  }

  render() {
    return this.props.children;
  }
}
