import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom'
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { login, facebook_login } from './../actions/Auth';
import ForgotPasswordModal from './ForgotPasswordModal';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
import "./../styles/home/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let theToast = null;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      forgotPasswordModalVisible: false
    };
  }

  responseFacebook = (response) => {
    theToast = toast.success("Logging In... Please wait...", { autoClose: false, position:"bottom-center" });
    this.props.facebook_login(response["accessToken"]).then(async res => {
      if (this.props.auth.isLoggedIn === true) {
        const userLoggedIn = await localStorage.getItem(
          "userLoggedIn"
        );
        if (userLoggedIn) {
          toast.update(theToast, {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
            render: `Success! Let's get started!`,
            position: "bottom-center"
          });
          this.setState({redirectToReferrer: true})
        } else {
          toast.update(theToast, {
            autoClose: 3000,
            type: toast.TYPE.ERROR,
            render: "Sign in failed. Check your email and password!!",
            position: "bottom-center"
          });
        }
      } else {
        toast.update(theToast, {
          autoClose: 3000,
          type: toast.TYPE.ERROR,
          render: "Sign in failed. Check your email and password!!",
          position: "bottom-center"
        });
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    theToast = toast.success("Logging In...", { autoClose: false });
    if(this.state.email !== "" && this.state.name !== "" && this.state.password !== "") {
      this.props
        .login(this.state.email, this.state.password)
        .then(async res => {
          if (this.props.auth.isLoggedIn === true) {
            const userLoggedIn = await localStorage.getItem(
              "userLoggedIn"
            );
            if (userLoggedIn) {
              toast.update(theToast, {
                type: toast.TYPE.SUCCESS,
                autoClose: 4000,
                render: `Welcome back! Let's get started!`,
                position: "bottom-center"
              });
              this.setState({redirectToReferrer: true})
            } else {
              toast.update(theToast, {
                autoClose: 3000,
                type: toast.TYPE.ERROR,
                render: "Sign in failed. Check your email and password!!",
                position: "bottom-center"
              });
            }
          } else {
            toast.update(theToast, {
              autoClose: 3000,
              type: toast.TYPE.ERROR,
              render: "Sign in failed. Check your email and password!!",
              position: "bottom-center"
            });
          }
        });
    }
    else {
      toast.update(theToast, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
        render: "Sign in failed. Check your email and password!!",
        position: "bottom-center"
      });
    }
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  render() {
    const { from } = this.props.location.state || { from: {pathname: '/start'}};
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <section className="auth-page">
        <div className="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="animation-circle">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="auth-card">
          <div className="text-center">
            <h2>Sign In</h2>
            {/* <img src={require("./../styles/images/title-line.png")} alt="title-line" className="img-fluid line" /> */}
            <p style={{marginTop: 25}}>Welcome To linkstackr, login to get started!</p>
          </div>
          <div className="main">
            <form className="auth-form">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email</label>

                <FontAwesomeIcon
                  icon={"envelope-open"}
                  color="#a2a2a2"
                  className="fa"
                />
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <FontAwesomeIcon
                  icon={"lock"}
                  color="#a2a2a2"
                  className="fa"
                />
                <input
                  type="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  className="form-control"
                />
                <p onClick={() => this.setState({forgotPasswordModalVisible: true})} className="forgot-pass">Forgot?</p>
              </div>
              <div className="form-group">
                <Button
                  onClick={this.handleSubmit}
                  color="primary"
                  size={"lg"}
                  className="submit"
                  style={{ marginTop: 25 }}
                  type="submit"
                >
                  Let's go!
                </Button>
              </div>
              {/* <div className="or-saparator"><span>Or</span></div>
              <div style={{padding: 20}}>
                <FacebookLogin
                  appId="2246737035541687"
                  autoLoad={false}
                  fields="name,email,picture"

                  callback={(response) => this.responseFacebook(response)} />
              </div> */}

              <div className="or-saparator"><span>Don't have an account?</span></div>
              <div className="form-button text-center social-btns" style={{ marginTop: 25}}>
                <Link to="/trial">
                  <Button className="btn submit">Get Started</Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
        <ForgotPasswordModal
          open={this.state.forgotPasswordModalVisible}
          closeModal={() => this.setState({forgotPasswordModalVisible: !this.state.forgotPasswordModalVisible})}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { login, facebook_login })(Login))
