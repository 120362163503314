export const _formatURL = string => {
  return string
    .replace(/\s+/g, "-")
    .replace(/\./g, "")
    .toLowerCase();
};

export const _formatStringCapitalize = string => {
  return string
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const _forceURLprefix = (newString, oldString) => {
  if (newString.includes("https://www.linkstackr.com/v/")) {
    return newString;
  } else {
    return oldString;
  }
};

export const _forceShortURLprefix = (newString, oldString) => {

  if (newString.includes("https://www.linkstackr.com/v/")) {
    newString = newString.replace("https://www.linkstackr.com/v/", "https://lnks.by/")
    return newString;
  } else {
    return oldString;
  }
};
