import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom";



class Price extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
             // Dynamic Price Data Easy to Update
             let data = [
               // {
               //   title: "Limited Plan",
               //   label: "Only the basic features",
               //   price: "Free",
               //   paid: false,
               //   features: [
               //     "Up to 4 custom links",
               //     "Customize colors",
               //     "Customize fonts",
               //     "Solid backgrounds",
               //     "List Layout"
               //   ],
               //   link: "#"
               // },
               {
                 // title: "Premium Plan",
                 // label: "Take it to the next level",
                 price: "4",
                 paid: true,
                 features: [
                   "15-Day Free Trial",
                  //  "30-Day Money Back Guarantee after Trial",
                   "Unlimited custom links",
                   "Social Media links",
                   "Customize colors",
                   "Customize fonts",
                   "Use stock background images",
                   "Gradient & Solid Backgrounds",
                   "Scheduled Links",
                   "Grab Attention with Animation on Links",
                   // "Upload your own background images",
                   "Different Layouts (Grids or Lists)",
                   "Analytics (track visits, link clicks, etc.)",
                   // "Removes linkstackr Brand from page",
                   "Custom Branded URL",
                   "Get a personalized QR Code that links to your page for printing and sharing"
                 ],
                 link: "#"
               }
             ];

             let DataList = data.map((val, i) => {
               return (
                 <div
                   className="text-center"
                   style={{
                     display: "flex",
                     justifyContent: "space-around",
                     alignContent: "center",
                     marginBottom: 30
                   }}
                   key={i}
                 >
                   <div className="item">
                     <div className="package-box">
                       <h3>{val.title}</h3>
                       <p className="white">{val.label}</p>
                       <div className="price-box">
                         {val.paid && <span>$</span>}

                         <h2>{val.price}</h2>
                         {val.paid && (
                           <h5 className="plan-clr">
                             <span className="d-block">
                               /month
                             </span>
                           </h5>
                         )}
                       </div>
                       <div className="price-plan text-center">
                         <ul>
                           {val.features.map((feature, i) => {
                             return <li key={i}>{feature}</li>;
                           })}
                         </ul>
                         {val.paid && (
                           <Link
                             to={this.props.auth.isLoggedIn ? "/start" : "/trial"}
                             className="btn-theme-primary mt-2 mr-3"
                           >
                             Start 15-day Free Trial
                           </Link>
                         )}
                         {!val.paid && (
                           <div className="btn-inverse">
                             Try FREE
                           </div>
                         )}
                       </div>
                     </div>
                   </div>
                 </div>
               );
             });

             return (
               <section id="pricing">
                 <div className="container">
                   <div className="row">
                     <div className="col-md-12 text-center">
                       <div className="section-title">
                         <h2>Pricing</h2>
                         {/* <img
                           src={require("../styles/images/title-line.png")}
                           alt="title-line"
                           className="img-fluid"
                         /> */}
                         <h6>Let's keep it simple.</h6>
                       </div>
                       {DataList}
                     </div>
                   </div>
                 </div>
               </section>
             );
           }
}



const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, {})(Price))
