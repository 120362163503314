import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import "./../styles/home/index.scss";
import "./../styles/fonts.scss";
import './../styles/css/bootstrap.min.css';
import { apiBaseURL } from './../Utils/Constants';
import Linkable from './Linkable/Linkable';
import SocialLinkable from './Linkable/SocialLinkable';
import { getView } from './../actions/Public';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridLinkable from './Linkable/GridLinkable';
import FitText from "@kennethormandy/react-fittext";
import { Animated } from 'react-animated-css';
import moment from 'moment';
class PublicView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,

      userName: null,
      pageTitle: null,
      pageSubTitle: null,
      pageTextColor: null,
      otherTextColor: null,

      pageTitleFont: null,
      backgroundColor: null,
      backgroundType: null,
      imageBackgroundUrl: null,
      customImageBackgroundUrl: null,
      gradientOne: null,
      gradientTwo: null,
      linkableBackgroundColor: null,
      linkables: [],
      linkableBorderColor: null,
      linkableTextColor: null,
      linkableTextFont: null,
      otherTextFont: null,
      linkableRounded: null,
      avatarSource: null,
      unsplashUser: null,
      unsplashUserLink: null,
      header_center_aligned: null,
      avatarPulseRing: false,
      checkmarkAvatar: false,
      isVip: false,

      layout: ""
    };
  }

  componentDidMount = async () => {
    await this._getView(this.props.match.params.slug).then((userData) => {
      let data = userData.data;
      if (typeof (data.user) !== "undefined") {
        data = data.user;
      }

      if (data.status === 404 || data.status === 500) {
        this.props.history.push('/404')
      } else {

        if (!this.props.preview) {
          console.log("DATA: ", data);
          if (
            data.plan === null ||
            data.plan.toLowerCase() === "canceled" ||
            data.plan_status.toLowerCase() === "canceled" ||
            data.plan === "" ||
            data.plan === "Free" ||
            data.blocked === true
          ) {
            if (data.blocked === true) {
              this.props.history.replace({
                pathname: "/",
                state: { blocked: "yes" }
              })
              return;
            } else {
              this.props.history.replace("/");
              return;
            }
          }
        }

        document.title = `${data.name} - linkstackr`;
        this.setState({
          isLoading: false,
          userName: data.name,
          pageTitle: data.page_title,
          pageSubTitle: data.page_subtitle,
          pageTextColor: data.layout.text_color,
          otherTextColor: data.layout.other_text_color,
          pageTitleFont: data.layout.title_font,
          backgroundColor: data.layout.background_color,
          backgroundType: data.layout.background_type,
          customImageBackgroundUrl: data.background_image_url,
          imageBackgroundUrl: data.layout.background_image_url,
          gradientOne: data.layout.gradient_one_color,
          gradientTwo: data.layout.gradient_two_color,
          linkableBackgroundColor: data.layout.linkable_background_color,
          linkables: data.linkables,
          linkableBorderColor: data.layout.linkable_border_color,
          linkableTextColor: data.layout.linkable_text_color,
          linkableTextFont: data.layout.linkable_font,
          otherTextFont: data.layout.text_font,
          linkableRounded: data.layout.linkable_rounded,
          avatarSource: data.avatar,
          unsplashUser: data.layout.unsplash_user,
          unsplashUserLink: data.layout.unsplash_user_link,
          layout: data.layout.design,
          header_center_aligned: data.layout.header_center_aligned,
          isVip: data.role === 'vip',
          shining_avatar: data.layout.shining_avatar,
          avatarPulseRing: data.layout.avatar_pulse_ring,
          checkmarkAvatar: data.layout.checkmark_avatar
        })
      }

    })

  }

  _getView = async (slug) => {

    if (this.props.preview === true) {

      return axios({
        method: "get",
        url: `${apiBaseURL}/user/preview`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("userToken")
        }
      }).catch((err) => {
        return err;
      }).then(async res => {
        return res;
      })

    }
    else {
      return axios({
        method: "get",
        url: `${apiBaseURL}/visitor/${slug}/${this.props.preview.toString()}`
      }).catch((err) => {
        return err;
      }).then(async res => {
        return res;
      })
    }
  }

  _recordClick = (id, destination) => {
    if (!this.props.preview) {
      return axios({
        method: "get",
        url: `${apiBaseURL}/record/${id}`
      })
        .catch(err => {
        })
        .then(async res => {
          // window.open(destination, "_blank");
        });
    }
    else {
      // window.open(destination, "_blank");
    }
  }

  _recordReport = () => {
    return axios({
      method: "get",
      url: `${apiBaseURL}/report/${this.props.match.params.slug}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      }
    })
      .then(res => {
        window.alert("Thanks for letting us know. We'll look into this.")
      })
      .catch(err => {
      });

  }

  renderLinkables = () => {
    let scheduled_linkables = [];
    this.state.linkables.map(linkable => {
      if(linkable.start_date !== null){
        if(linkable.end_date !== null){
          if(moment.utc().isBetween(moment.utc(linkable.start_date), moment.utc(linkable.end_date))){
            scheduled_linkables.push(linkable);
          }
        } else {
          if(moment.utc().isAfter(moment.utc(linkable.start_date))){
            scheduled_linkables.push(linkable);
          }
        }
      } else if(linkable.end_date !== null){
        if(moment.utc().isBefore(moment.utc(linkable.end_date))){
          scheduled_linkables.push(linkable);
        }
      } else {
        scheduled_linkables.push(linkable);
      }
    });

    let linkables = [];

    if (this.state.layout === "list") {
      scheduled_linkables.map(linkable => {
        if (linkable.active && linkable.link_type === "link" && linkable.visible) {
          if (linkable.highlight) {
            linkables.push(
                <a
                  key={linkable.id}
                  href={linkable.destination}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wiggle"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30
                  }}
                  onClick={() => { this._recordClick(linkable.id, linkable.destination) }}
                >
                  <Linkable
                    key={linkable.id}
                    linkableData={linkable}
                    background={this.state.linkableBackgroundColor}
                    borderColor={this.state.linkableBorderColor}
                    textColor={this.state.linkableTextColor}
                    rounded={this.state.linkableRounded}
                    font={this.state.linkableTextFont}
                  />
                </a>

            )
          } else {
            linkables.push(
              <a
                key={linkable.id}
                href={linkable.destination}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30
                }}
                onClick={() => { this._recordClick(linkable.id, linkable.destination) }}
              >
                <Linkable
                  key={linkable.id}
                  linkableData={linkable}
                  background={this.state.linkableBackgroundColor}
                  borderColor={this.state.linkableBorderColor}
                  textColor={this.state.linkableTextColor}
                  rounded={this.state.linkableRounded}
                  font={this.state.linkableTextFont}
                />
              </a>
            );
          }
        }
      });
    }
    else if (this.state.layout === "image grid") {
      this.state.linkables.map(linkable => {
        if (linkable.active && linkable.link_type === "link" && linkable.visible) {
          linkables.push(
            <a
              rel="noopener noreferrer"
              href={linkable.destination}
              target="_blank"
              style={{ backgroundImage: `url(${linkable.thumbnail})` }}
              className="grid-linkable"
              key={linkable.id}
              onClick={() => { this._recordClick(linkable.id, linkable.destination) }}
            >
              <GridLinkable
                key={linkable.id}
                linkableData={linkable}
                background={this.state.linkableBackgroundColor}
                borderColor={this.state.linkableBorderColor}
                textColor={this.state.linkableTextColor}
                rounded={this.state.linkableRounded}
                font={this.state.linkableTextFont}
              />
            </a>
          );
        }
      });
    }

    return linkables;
  };

  renderSocialMediaLinks = () => {
    let activeLinkables = [];

    this.state.linkables.map((linkable) => {
      if (linkable.active && linkable.link_type === "social") {
        activeLinkables.push(linkable)
      }
    })
    return <div className="flex-container" style={{ padding: 0, paddingBottom: 20, width: 200, justifyContent: 'space-evenly' }}>
      {activeLinkables.map((linkable) => {
        return (
          <a key={linkable.id}
            rel="noopener noreferrer"
            href={linkable.destination}
            target="_blank"
            style={{ padding: 5 }}
            onClick={() => { this._recordClick(linkable.id, linkable.destination) }}
          >
            <SocialLinkable
              key={linkable.id}
              edit={false}
              linkableData={linkable}
              background={this.state.linkableBackgroundColor}
              borderColor={this.state.linkableBorderColor}
              textColor={this.state.linkableTextColor}
              rounded={this.state.linkableRounded}
              font={this.state.linkableTextFont}

            />
          </a>

        )
      })}
    </div>;
  };

  render() {
    return (
      <div>
        <div
          id="linkable-edit-content"
          style={{
            flex: 1,
            minHeight: "140vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            position: "relative",
            height: "100%"
          }}
        >
          {this.state.loading && <h3>Loading...</h3>}
          <div
            id="background"
            style={{
              zIndex: -10,
              filter: `blur(0px)`,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              minHeight: "100vh",
              display: !this.state.loading ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              objectFit: "contain",
              backgroundPosition: "center",
              background:
                this.state.backgroundType === "solid"
                  ? this.state.backgroundColor
                  : this.state.backgroundType === "image"
                    ? `url(${
                    this.state.imageBackgroundUrl
                    }) center center/cover`
                    : this.state.backgroundType === "custom"
                      ? `url(${
                      this.state.customImageBackgroundUrl
                      }) center center/cover`
                      : this.state.backgroundType === "gradient"
                        ? `linear-gradient(to bottom right, ${
                        this.state.gradientOne
                        }, ${this.state.gradientTwo})`
                        : ""
            }}
          />

          {this.props.preview && (
            <Link
              to="/edit"
              className="fixed-back-button"
              style={{ color: "black" }}
            >
              <FontAwesomeIcon
                icon={"arrow-circle-left"}
                color="#fff"
                size="2x"
                style={{ marginRight: 8 }}
              />
              Back to Edit
            </Link>
          )}
          {this.props.preview && this.props.auth.data.plan === "canceled" || this.props.auth.data.plan === "Free" && (
            <a
              onClick={() => {
                this.props.history.push("/publish");
              }}
              className="fixed-publish-button"
              style={{ color: "black" }}
            >
              <FontAwesomeIcon
                icon={"arrow-circle-right"}
                color="#fff"
                size="2x"
                style={{ marginRight: 8 }}
              />
              Publish Now
            </a>
          )}
          <div
            className={
              this.state.header_center_aligned
                ? "centered-heading"
                : "horizontal-heading"
            }
          >
            <div className="header-image-and-name">
              {this.state.isVip && this.state.shining_avatar && (
                <div className="shine-icon">
                  <img
                    className="avatarRound shining"
                    src={this.state.avatarSource}
                  />
                </div>

              )}

              {this.state.avatarPulseRing && (
                <div className="pulse-container" style={{ top: this.state.shining_avatar ? 36 : 62 }}>
                  <span></span>
                </div>
              )}

              {this.state.shining_avatar === false && (
                <img
                  className="avatarRound"
                  style={{ marginBottom: 30 }}
                  src={this.state.avatarSource}
                />
              )}

              <p
                style={{
                  fontFamily: this.state.otherTextFont,
                  color: this.state.otherTextColor,
                  textAlign: "center",
                  marginTop: this.state.shining_avatar || this.state.avatar_pulse_ring ? 30 : "initial"
                }}
              >{this.state.userName}
                {this.state.checkmarkAvatar && (
                  <FontAwesomeIcon icon="check-circle" style={{ marginLeft: 2, marginTop: 4, color: this.state.otherTextColor }} />
                )}
              </p>
            </div>
            <div className="header-title-and-subtitle" style={{ textAlign: 'center', width: 340, marginLeft: this.state.header_center_aligned ? 0 : 20 }}>
              <h1
                // compressor={0.5}
                // maxFontSize={40}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  lineHeight: 1.1,
                  marginTop: this.state.header_center_aligned ? 10 : 60,
                  color: this.state.pageTextColor,
                  fontFamily: this.state.pageTitleFont,
                  textAlign: "center"
                }}
              >
                {this.state.pageTitle}
              </h1>
            </div>
          </div>
          <h6
            style={{
              color: this.state.pageTextColor,
              fontFamily: this.state.pageTitleFont,
              textAlign: "center",
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            {this.state.pageSubTitle}
          </h6>

          {this.state.layout === null ||
            (this.state.layout === "list" && (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  textAlign: "center"
                }}
              >
                {this.state.linkables && this.renderLinkables()}
              </div>
            ))}

          {this.state.layout !== null &&
            this.state.layout === "image grid" && (
              <div
                className="flex-container"
                style={{
                  // paddingBottom: 300,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                {this.state.linkables && this.renderLinkables()}
              </div>
            )}
          <div
            className="flex-container"
            style={{
              paddingBottom: 300,
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: 30
            }}
          >{this.renderSocialMediaLinks()}</div>

          {this.state.backgroundType === "image" &&
            this.state.unsplashUser !== "" && (
              <div className="unsplash-attribution">
                <p>
                  <FontAwesomeIcon
                    icon={"camera-retro"}
                    color="#a2a2a2"
                    style={{ marginRight: 8 }}
                  />
                  {`${this.state.unsplashUser} @ `}
                  <a href={this.state.unsplashUserLink} rel="noopener noreferrer" target="_blank">
                    Unsplash
                  </a>
                </p>
              </div>
            )}
          <div className='report-btn' onClick={() => { if (window.confirm('Are you sure you wish report this page as inappropriate?')) this._recordReport() }} >
            <p style={{ color: this.state.pageTextColor, fontFamily: this.state.pageTitleFont }}>Report</p>
          </div>
          <a className='linkstackr-logo' href="https://www.linkstackr.com">
            <p style={{ color: this.state.pageTextColor, fontFamily: this.state.pageTitleFont }}>linkstackr</p>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { getView })(PublicView))
