import React from "react";
import Modal from "react-responsive-modal";
import WelcomeSlider from "./WelcomeSlider";
import { Button } from "reactstrap";

const WelcomeModal = props => {  
  return (
    <Modal open={props.open} onClose={props.closeModal} center>
      <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
        <WelcomeSlider />
      </div>
    </Modal>
  );
};

export default WelcomeModal;
