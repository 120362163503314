import { apiBaseURL } from "./../Utils/Constants";
import {
  LOGGING_IN,
  LOGIN,
  LOGIN_FAIL,
  LOGOUT,
  REGISTERING,
  REGISTER_FAIL,
  USER_DATA,
  LINKING_STRIPE_TOKEN_TO_CUSTOMER,
  LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS,
  LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE,
  CANCEL_CUSTOMER_PLAN,
  CANCEL_CUSTOMER_PLAN_SUCCESS,
  CANCEL_CUSTOMER_PLAN_FAILURE,
  GETTING_COUPON,
  GETTING_COUPON_SUCCESS,
  GETTING_COUPON_FAILURE,
  CLEAR_COUPON,
  SENDING_RESET_LINK,
  SENT_RESET_LINK,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_SUCCESS,
  RESETTING_PASSWORD_FAILURE
} from "./../Utils/ActionTypes";
import { toast } from "react-toastify";

import axios from "axios";

export const login = (email, password) => {
  return dispatch => {
    dispatch({
      type: LOGGING_IN
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/token`,
      data: JSON.stringify({
        auth: {
          email: email,
          password: password
        }
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8"
      }
    })
      .then(res => {
        dispatch({
          type: LOGIN,
          payload: res.data.token.token,
          email: email,
          data: res.data.user
        });
        localStorage.setItem("userToken", res.data.token.token);
        localStorage.setItem("userLoggedIn", "true");
        localStorage.setItem("userEmail", email);
      })
      .catch(err => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err
        });
      });
  };
};

export const refreshData = () => {
  return dispatch => {
    axios({
      method: "get",
      url: `${apiBaseURL}/users/current`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .catch(err => {
        console.log("err: ", err);
        return err;
      })
      .then(async res => {
        if (res.data.status && res.data.status === 403) {
          dispatch({
            type: LOGOUT
          });
        } else {
          dispatch({
            type: USER_DATA,
            payload: res.data
          });
        }
      });
  };
};


export const logout = () => {
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
  };
};

export const register = (email, password, name, slug, vip = false, referrer = null) => {
  return dispatch => {
    dispatch({
      type: REGISTERING
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/users/create`,
      data: JSON.stringify({
        user: {
          email: email,
          password: password,
          name: name,
          slug: slug,
          role: vip === true ? "vip" : "user",
          referred_by: referrer
        }
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8"
      }
    })
      .catch(function (error) {
        dispatch({ type: REGISTER_FAIL, payload: error });
      })
      .then(async res => {
        if (res !== undefined) {
          return axios({
            method: "post",
            url: `${apiBaseURL}/user/token`,
            data: JSON.stringify({
              auth: {
                email: email,
                password: password
              }
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=UTF-8"
            }
          })
            .catch(function (error) { })
            .then(async res => {
              dispatch({
                type: LOGIN,
                payload: res.data.jwt,
                email: email,
                data: res.data.user
              });
              try {
                await localStorage.setItem("userToken", res.data.token.token);
                await localStorage.setItem("userLoggedIn", "true");
                await localStorage.setItem("userEmail", email);
                await localStorage.setItem("userName", name);
                await localStorage.setItem("userSlug", res.data.user.slug);
              } catch (error) {
              }
            })
            .catch(function (error) {
              dispatch({ type: LOGIN_FAIL });
            });
        }
      });
  };
};

export const facebook_login = fb_token => {
  return dispatch => {
    dispatch({
      type: REGISTERING
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/facebook`,
      data: JSON.stringify({
        auth: {
          access_token: fb_token
        }
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8"
      }
    })
      .catch(function (error) {
        dispatch({ type: REGISTER_FAIL, payload: error });
      })
      .then(async res => {
        dispatch({
          type: LOGIN,
          payload: res.data.token.token,
          email: res.data.user.email,
          data: res.data.user
        });
        try {
          await localStorage.setItem("userToken", res.data.token.token);
          await localStorage.setItem("userLoggedIn", "true");
          await localStorage.setItem("userEmail", res.data.user.email);
          await localStorage.setItem("userName", res.data.user.name);
          await localStorage.setItem("userSlug", res.data.user.slug);
        } catch (error) { }
      });
  };
};

export const enrollCustomer = (token, plan, referral, code = null) => {
  return dispatch => {
    dispatch({
      type: LINKING_STRIPE_TOKEN_TO_CUSTOMER
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/enroll`,
      data: JSON.stringify({
        token: token.id,
        plan: plan,
        referral: referral,
        email: localStorage.getItem("userEmail"),
        code: code
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(res => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE,
          payload: err
        });
      });
  };
};

export const enrollCustomerWithoutCard = (plan, referral) => {
  return dispatch => {
    dispatch({
      type: LINKING_STRIPE_TOKEN_TO_CUSTOMER
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/enroll_wo_card`,
      data: JSON.stringify({
        plan: plan,
        referral: referral,
        email: localStorage.getItem("userEmail"),

      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(res => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE,
          payload: err
        });
      });
  };
};

export const updateCustomerCard = (token) => {
  return dispatch => {
    dispatch({
      type: LINKING_STRIPE_TOKEN_TO_CUSTOMER
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/card_update`,
      data: JSON.stringify({
        token: token.id,
        email: localStorage.getItem("userEmail")
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(res => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: LINKING_STRIPE_TOKEN_TO_CUSTOMER_FAILURE,
          payload: err
        });
      });
  };
};

export const cancelPlan = () => {
  return dispatch => {
    dispatch({
      type: CANCEL_CUSTOMER_PLAN
    });
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/cancel_plan`,
      data: JSON.stringify({
        email: localStorage.getItem("userEmail")
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }
    })
      .then(res => {
        dispatch({
          type: CANCEL_CUSTOMER_PLAN_SUCCESS
        });
        toast(
          "Your plan has been canceled! You will have access to your linkstackr until the end of the billing cycle.",
          {
            type: toast.TYPE.SUCCESS,
            autoClose: 6000,
            position: "bottom-center"
          }
        );
      })
      .catch(err => {
        dispatch({
          type: CANCEL_CUSTOMER_PLAN_FAILURE,
          payload: err
        });
        toast(
          "Oops! There was a problem cancelling your account. Try again. If problems persist, send us an email and we'll help you out.",
          {
            type: toast.TYPE.WARNING,
            autoClose: 3000,
            position: "bottom-center"
          }
        )
      })
  }
};

export const validateCoupon = (code) => {
  return dispatch => {
    dispatch({
      type: GETTING_COUPON
    });
    return axios({
      method: "get",
      url: `${apiBaseURL}/validate_coupon/${code}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      }
    })
      .then(async res => {
        dispatch({
          type: GETTING_COUPON_SUCCESS,
          details: res.data.data,
          isValid: res.data.status === 200
        });
        if (res.data.status === 200) {
          toast(
            "Coupon Applied!",
            {
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
              position: "bottom-center"
            }
          );
        }
      })
      .catch(err => {
        dispatch({
          type: GETTING_COUPON_FAILURE
        });
        console.log("err: ", err);
        return err;
      })
  }

};
export const clearCoupon = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_COUPON
    });
  }

};

export const sendResetPasswordEmail = (email) => {
  return (dispatch) => {
    dispatch({
      type: SENDING_RESET_LINK
    })
    return axios.get(`${apiBaseURL}/user/send_reset_password_email?email=${email}`)
      .then(async (res) => { }).catch(err => { }).then(async (res) => {
        dispatch({
          type: SENT_RESET_LINK
        })
      })
  }
}

export const updatePassword = (resetPasswordToken, password) => {
  return (dispatch) => {
    dispatch({
      type: RESETTING_PASSWORD
    })
    return axios({
      method: "post",
      url: `${apiBaseURL}/user/update_password`,
      data: {
        reset_password_token: resetPasswordToken,
        password: password
      }
    })
      .then(async (res) => {
        console.log("SUCCESS RES: ", res)
        dispatch({
          type: RESETTING_PASSWORD_SUCCESS
        })
      }).catch(err => {
        dispatch({
          type: RESETTING_PASSWORD_FAILURE,
          errorMessage: err
        })
        console.log("fAILURE RES: ", err)
      })
  }
}