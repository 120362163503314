import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom'
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { updatePassword } from '../actions/Auth';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
import "./../styles/home/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let theToast = null;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false
    };
  }


  handleSubmit = e => {
    e.preventDefault();
    theToast = toast.success("Resetting password...", { autoClose: false });
    if(this.state.password !== "" && this.state.confirmationPassword !== "") {
      if(this.state.password !== this.state.confirmationPassword) {
        toast.update(theToast, {
          autoClose: 3000,
          type: toast.TYPE.ERROR,
          render: "Your password doesn't match in both fields. Try again.",
          position: "bottom-center"
        });
        return;
      }

      this.props
        .updatePassword(this.props.match.params.user_token, this.state.password)
        .then(() => {
          if (this.props.auth.hasError === false) {
            toast.update(theToast, {
              autoClose: 3000,
              type: toast.TYPE.SUCCESS,
              render: "Your password has been reset. Please login.",
              position: "top-center"
            });
            setTimeout(() =>{
              this.props.history.push('/login')
            }, 450)
          } else {
            toast.update(theToast, {
              autoClose: 3000,
              type: toast.TYPE.ERROR,
              render: "Oops, there was an error on our end. Please try again!",
              position: "bottom-center"
            });
          }
        });
    }
    else {
      toast.update(theToast, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
        render: "You must enter in your password in both fields.",
        position: "bottom-center"
      });
    }
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  handlePasswordConfirmationChange = e => {
    this.setState({ confirmationPassword: e.target.value });
  };

  render() {

    return (
      <section className="auth-page">
        <div className="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="animation-circle">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="auth-card">
          <div className="text-center">
            <h2>Reset Your Password</h2>
            {/* <img src={require("./../styles/images/title-line.png")} alt="title-line" className="img-fluid line" />             */}
          </div>
          <div className="main">
            <form className="auth-form">

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <FontAwesomeIcon
                  icon={"lock"}
                  color="#a2a2a2"
                  className="fa"
                />
                <input
                  type="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  className="form-control"
                />

              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <FontAwesomeIcon
                  icon={"lock"}
                  color="#a2a2a2"
                  className="fa"
                />
                <input
                  type="password"
                  value={this.state.confirmationPassword}
                  onChange={this.handlePasswordConfirmationChange}
                  className="form-control"
                />

              </div>
              <div className="form-group">
                <Button
                  onClick={this.handleSubmit}
                  color="primary"
                  size={"lg"}
                  className="submit"
                  style={{ marginTop: 25 }}
                  type="submit"
                >
                  Reset Password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { updatePassword })(ResetPassword))
